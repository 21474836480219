import {Pipe, PipeTransform} from '@angular/core';
import {base64Encode} from '@softline/core';
import {Observable} from 'rxjs';
import {Media} from '../data/media';
import {SignatureService} from '../services/signature.service';
import {SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'getSignature',
  standalone: true,
  pure: true
})
export class GetSignaturePipe implements PipeTransform {

  constructor(private signatureService: SignatureService) {}

  transform(value: Media | never): Observable<Blob> {
    const data = JSON.stringify(value);
    const encoded = base64Encode(data);
    return this.signatureService.getSignatureImage(encoded);
  }
}
