import { Component, HostListener, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
    BackNavigationService,
    MengenEingabeComponent, HeaderComponent,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL } from "../../lagerkorrektur-artikel.shared";
import { LagerkorrekturArtikelStore } from "../../store";
import { combineLatest, map } from "rxjs";

@Component({
  selector: 'soft-mengen-auswahl',
  standalone: true,
    imports: [CommonModule, UiCoreModule, MengenEingabeComponent, StepHeaderComponent, HeaderComponent],
  templateUrl: './mengen-auswahl.page.html',
  styleUrls: ['./mengen-auswahl.page.scss']
})
export class MengenAuswahlPage implements OnInit {

  lagerplatz$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.lagerplatz);
  artikel$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.artikel);

  readonly steps: Step[] = [
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.LAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.LAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.DESCRIPTION'
    },
  ];
  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERKORREKTUR_ARTIKEL.TITLE')
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/lagerkorrektur-artikel/artikel-auswahl'])
  }

  async onMengeChange(menge: number | null | undefined): Promise<void> {
    const artikel = this.store.get(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.artikel);
    this.store.commit(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.mutations.setArtikel, {...artikel, menge});
  }

  @HostListener('window:keydown.enter', ['$event'])
  async onSubmit(): Promise<void> {
    await this.router.navigate(['/lagerkorrektur-artikel', 'umbuchungsartikel-auswahl'])
  }
}
