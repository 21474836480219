export interface Contact {
  salutation: string,
  title: string,
  firstName: string,
  lastName: string,
  nameAffix: string,
  position: string,
  nameAffix2: string,
  street: string,
  countryCode: string,
  zipCode: string,
  location: string,
  mail1: string,
  mailDomain: string,
  telephoneNumberOrganisation: string,
  directDialOrganisation: string,
  mobilePhoneNumberOrganisation: string,
  faxOrganisation: string,
  url: string,
  mail2: string,
  status: {
    value: string,
    description: string
  },
  crmStatus: {
    value: string,
    description: string
  },
  categories: {key: string}[]
}
