import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, Location} from '@angular/common';
import {RemoteObjectStore, Store} from '@softline/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';
import {
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_DETAIL,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICELIST,
  SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK,
  SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE
} from '../../artikel-info.shared';
import {ItemStore} from '../../store/item.store';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  defer,
  distinctUntilChanged,
  from,
  iif,
  Observable,
  of,
  skip,
  Subscription
} from 'rxjs';
import {Item} from '../../types/item';
import {ItemPriceStore} from '../../store/item-price.store';
import {ItemPrice} from '../../types/item-price';
import {
  BackNavigable,
  BackNavigationService,
  Command,
  CommandStore,
  showRequestErrors,
  ItemScanStore,
  ModuleStore,
  RemoteConfigStore,
  Scan,
  ScannerStore,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_ITEM_SCAN,
  SOFTLINE_FEATURE_MASTER_DATA,
  SOFTLINE_FEATURE_MODULE,
  SOFTLINE_FEATURE_REMOTE_CONFIG,
  SOFTLINE_FEATURE_SCANNER
} from '@softline/application';
import {ItemStockStore} from '../../store/item-stock.store';
import {ItemStockAmount, StockLocationInfo} from '../../types/item-stock-amount';
import {ItemDetailStore} from '../../store/item-detail.store';
import {ItemDetail} from '../../types/item-detail';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MediaPipe} from '../../pipes/media.pipe';
import {UmsatzabfrageState, UmsatzabfrageStore} from '../../store/umsatzabfrage.store';
import {ModalStore, SOFTLINE_FEATURE_MODAL, UiCoreModule, Validators} from '@softline/ui-core';
import {FavoriteService} from '../../services/favorite.service';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {GroupByStockPipe} from '../../pipes/group-by-stock.pipe';
import {UmsatzValueForIndexPipe} from '../../pipes/umsatz-value-for-index.pipe';
import {
  GesamtabfrageBewegungenDialogComponent
} from '../../dialogs/gesamtabfrage-bewegungen-dialog/gesamtabfrage-bewegungen-dialog.component';
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from '@softline/auth';
import {ItemDetailPricelistStore} from '../../store/item-pricelist.store';
import {ArtikelInfoModal} from '@softapps/wws/core';

@Component({
  selector: 'soft-artikel-detail',
  standalone: true,
  imports: [CommonModule, CdkAccordionModule, MediaPipe, UiCoreModule, ReactiveFormsModule, GroupByStockPipe, UmsatzValueForIndexPipe],
  templateUrl: './artikel-detail.component.html',
  styleUrls: ['./artikel-detail.component.scss'],
})
export class ArtikelDetailComponent implements OnInit, OnDestroy, BackNavigable, ArtikelInfoModal<{
  vkforgRestriction: boolean,
  showUmsatzabfrage: boolean
}> {

  private scanSubscription?: Subscription;
  private subscription?: Subscription;

  private readonly previouslyActiveModule$ = new BehaviorSubject(
    this.store.get(
      SOFTLINE_FEATURE_MODULE,
      ModuleStore.getters.active
    )
  );

  @Input() id!: number;
  @Input() params = {
    vkforgRestriction: false,
    showUmsatzabfrage: true
  };

  close!: (result: void) => void;

  readonly config$ = this.store.observe(
    SOFTLINE_FEATURE_REMOTE_CONFIG,
    RemoteConfigStore.getters.data,
  ).pipe(
    map(data => data?.modules?.find(o => o.module === 'artikel-info')?.properties),
    map(properties => {
      return {
        listungExpanded: properties?.find(o => o?.key === 'INFO_AUFGEKLAPPT')?.value?.includes('LISTUNG'),
        lagerstaendeExpanded: properties?.find(o => o?.key === 'INFO_AUFGEKLAPPT')?.value?.includes('LAGERSTAENDE'),
        lagerplaetzeExpanded: properties?.find(o => o?.key === 'INFO_AUFGEKLAPPT')?.value?.includes('LAGERPLAETZE'),
        einheitenExpanded: properties?.find(o => o?.key === 'INFO_AUFGEKLAPPT')?.value?.includes('EINHEITEN'),
        lieferantenExpanded: properties?.find(o => o?.key === 'INFO_AUFGEKLAPPT')?.value?.includes('LIEFERANTEN'),
        showNextOrderDate: properties?.find(o => o?.key === 'BESTELLUNG_LIEFERDATUM')?.value === 'true',
        hideListung: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('LISTUNG'),
        hideLagerstaende: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('LAGERSTAENDE'),
        hideLagerplaetze: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('LAGERPLAETZE'),
        hideEinheiten: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('EINHEITEN'),
        hideLieferanten: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('LIEFERANTEN'),
        hideUmsatzabfrage: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('UMSATZABFRAGE'),
        hideOffeneBestellungen: properties?.find(o => o?.key === 'INFO_AUSGEBLENDET')?.value?.includes('OFFENE_BESTELLUNGEN'),
      }
    }),
  );

  private readonly id$ = this.activatedRoute.paramMap.pipe(
    map(map => map.get('id')),
    map(id => id ? +id : (this.id ?? null)),
  );

  private readonly localItem$ = this.id$.pipe(
    filter((o): o is number => o !== null),
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM,
        ItemStore.getters.entity,
        id
      )
    ),
    distinctUntilChanged((a, b) => a?.id === b?.id)
  );

  readonly umsatzabfrageForm = new FormGroup({
    from: new FormControl<string | null>(null, Validators.required()),
    to: new FormControl<string | null>(null,  Validators.required()),
    filiale: new FormControl<string>('Alle', Validators.required())
  });

  readonly scanInProgress$ = new BehaviorSubject<boolean>(false);

  // Reads the item from the store or loads it from the API if not already in the store
  readonly item$: Observable<Item> = this.id$.pipe(
    filter((o): o is number => o !== null),
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM,
        ItemStore.getters.entity,
        id
      ).pipe(
        switchMap(item =>
          iif(
            () => !!item,
            of(item),
            defer(() => from(this.loadItem(id))).pipe(
              filter((item): item is Item => item !== null),
            )
          )
        ),
      )
    )
  );

  readonly pricelist$: Observable<Partial<ItemDetail>> = this.id$.pipe(
    filter((o): o is number => o !== null),
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICELIST,
        ItemDetailPricelistStore.getters.entity,
        id
      )
    )
  );

  readonly details$: Observable<ItemDetail | null> = this.id$.pipe(
    filter((o): o is number => o !== null),
    distinctUntilChanged((a, b) => a === b),
    switchMap(id =>
      defer(() => from(this.loadItemDetails(id)))
    ),
  );

  readonly price$: Observable<ItemPrice | null>= this.id$.pipe(
    filter((o): o is number => o !== null),
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICE,
        ItemPriceStore.getters.entity,
        id
      )
    )
  );

  readonly stock$: Observable<ItemStockAmount | null> = this.id$.pipe(
    filter((o): o is number => o !== null),
    switchMap(id =>
      this.store.observe(
        SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_STOCK,
        ItemStockStore.getters.entity,
        id
      )
    )
  );

  readonly umsaztabfrageFilialen$: Observable<UmsatzabfrageState['filialen']> = this.store.observe(
    SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
    UmsatzabfrageStore.getters.filialen,
  );

  readonly selectedUmsatzabfrageViewIndex$ = new BehaviorSubject<number>(0);

  readonly sum$ = this.store.observe(
    SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
    UmsatzabfrageStore.getters.data,
  ).pipe(
    map(o => {
      return { sum: o?.sum, ekSum: o?.ekSum };
    })
  );

  readonly umsaetze$ = this.store.observe(
    SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
    UmsatzabfrageStore.getters.data,
  ).pipe(
    map(result => result ?? {} as object),
    combineLatestWith(this.selectedUmsatzabfrageViewIndex$),
    map(([o, _]) => {
      return o?.values ?? []
    })
  );

  constructor(
    private store: Store,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private favoriteService: FavoriteService,
    private backNavigationService: BackNavigationService,
    private injector: Injector,
  ) {}

  regi

  async ngOnInit(): Promise<void> {
    const previousModule = this.store.get(
      SOFTLINE_FEATURE_MODULE,
      ModuleStore.getters.active
    );

    this.previouslyActiveModule$.next(previousModule);

    this.store.commit(
      SOFTLINE_FEATURE_MODULE,
      ModuleStore.mutations.setActive,
      'artikel-info'
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      { name: ArtikelDetailComponent, commands: this.createCommands() }
    );

    this.backNavigationService.set(this);

    this.scanSubscription = this.store
      .observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(skip(1))
      .subscribe(async scan => {
        await this.validateScan(scan);
      });

    this.subscription = combineLatest([
      this.localItem$,
      this.store.observe(SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT, AuthenticationContextStore.getters.data),
      this.store.observe(SOFTLINE_FEATURE_MASTER_DATA, RemoteObjectStore.getters.data) as Observable<any>
    ]).subscribe(async ([item, context, data]) => {
      if (!item || !context || !data)
        return;

      const { filialen, einkaufFilialen } = await this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
        UmsatzabfrageStore.actions.loadFilialen
      );

      const vkforg = filialen.find(o => o.id === (context as any)?.vkforgid);

      let from: string;
      let to: string;

      if (data && data?.fibperModule) {
        from = data?.fibperModule[0]?.vondat
        to = data?.fibperModule[0]?.bisdat
      } else {
        from = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString();
        to = new Date().toISOString();
      }

      this.umsatzabfrageForm.patchValue({
        from,
        to,
        filiale: !vkforg ? this.umsatzabfrageForm.value?.filiale : vkforg.vkforg
      }, { onlySelf: true, emitEvent: false });

      try {
        await this.updateUmsatzabfrage(item, from, to, vkforg?.vkforg ?? 'Alle');
      } catch (e) {
        showRequestErrors(this.store, e);
      }
    });
  }

  ngOnDestroy() {
    this.backNavigationService.set(undefined);

    this.store.commit(
      SOFTLINE_FEATURE_MODULE,
      ModuleStore.mutations.setActive,
      this.previouslyActiveModule$.value
    );

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.removeSet,
      ArtikelDetailComponent
    );

    this.store.commit(
      SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
      UmsatzabfrageStore.mutations.clearAbfrage,
    );

    if (this.scanSubscription && !this.scanSubscription.closed)
      this.scanSubscription.unsubscribe();

    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();

    this.scanSubscription = undefined;
    this.subscription = undefined;
  }

  async navigateBack(): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
      UmsatzabfrageStore.mutations.clearAbfrage,
    );

    const state = this.location.getState() as { previousRoute?: string };

    if (state?.previousRoute)
      await this.router.navigate([state?.previousRoute ?? '/artikel-info']);
    else
      await this.router.navigate(['/artikel-info']);
  }

  registerCloseHandler(handler: (result: void) => void) {
    this.close = handler;
  }

  async onTabIndexChanged(index: number, item: Item | null): Promise<void> {
    this.selectedUmsatzabfrageViewIndex$.next(index || 0);

    const ekOrg = this.getEkOrgBeanFromVkfOrg(this.umsatzabfrageForm.value.filiale);

    if (index === 3 && item) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
        UmsatzabfrageStore.actions.loadEinkaufsstatisitk,
        {
          number: item.number,
          from: this.umsatzabfrageForm.value.from ?? '',
          to: this.umsatzabfrageForm.value.to ?? '',
          filiale: ekOrg ? ekOrg : undefined
        }
      );
    }
  }

  async onSubmit(item: Item | null): Promise<void> {
    try {
      await this.updateUmsatzabfrage(
        item,
        this.umsatzabfrageForm.value?.from ?? null,
        this.umsatzabfrageForm.value?.to ?? null,
        this.umsatzabfrageForm.value?.filiale ?? 'Alle',
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }

  async updateUmsatzabfrage(item: Item | null, from: string | null, to: string | null, filiale: string): Promise<void> {
    if (!item)
      return;

    this.store.commit(
      SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
      UmsatzabfrageStore.mutations.clearAbfrage,
    );

    await this.store.dispatch(
      SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
      UmsatzabfrageStore.actions.load,
      { number: item.number, from, to, filiale }
    );

    if (this.selectedUmsatzabfrageViewIndex$.value === 3) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
        UmsatzabfrageStore.actions.loadEinkaufsstatisitk,
        {
          number: item.number,
          from: this.umsatzabfrageForm.value.from ?? '',
          to: this.umsatzabfrageForm.value.to ?? '',
          filiale: this.getEkOrgBeanFromVkfOrg(this.umsatzabfrageForm.value.filiale)
        }
      );
    }
  }

  async showBewegungen(stockLocation: StockLocationInfo, artikel: Item | null): Promise<void> {
    if (!artikel || this.umsatzabfrageForm.invalid)
      return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'ARTIKEL_INFO_BEWEGUNGEN',
        component: GesamtabfrageBewegungenDialogComponent,
        getInjector: (): Injector => this.injector,
        dismiss: true,
        data: {
          stockLocation,
          artikel,
        },
      }
    );
  }

  async openImageDialog(image: string | null): Promise<void> {
    if (!image)
      return;

    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.gallery,
      {
        index: 0,
        images: [image]
      }
    );
  }

  private getEkOrgBeanFromVkfOrg(vkforg: string | null | undefined): any | null {
    if (!vkforg)
      return null;

    const filialen = this.store.get(SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE, UmsatzabfrageStore.getters.filialen);
    const ekFilialen = this.store.get(SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE, UmsatzabfrageStore.getters.einkaufFilialen);

    const idEkorg = filialen.find(o => o.vkforg === this.umsatzabfrageForm.value.filiale)?.idekorg;

    return ekFilialen.find(o => o.id === idEkorg);
  }

  private async loadItem(id: number): Promise<Item | null> {
    return await this.tryLoad(() =>
      this.store.dispatch(
        SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM,
        ItemStore.actions.load,
        { id, vkforgRestriction: this.params?.vkforgRestriction ?? false }
      )
    );
  }

  private async loadItemDetails(id: number): Promise<ItemDetail | null> {
    try {
      const [details,] = await Promise.all([
        this.store.dispatch(
          SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_DETAIL,
          ItemDetailStore.actions.loadManySequential,
          {
            ids: [id],
            vkforgRestriction: this.params?.vkforgRestriction ?? false
          }
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM_PRICELIST,
          ItemDetailPricelistStore.actions.loadPricelist,
          {
            id: id,
            attributes: ['discountTieredPricelist', 'tieredPricelist'],
            vkforgRestriction: this.params?.vkforgRestriction
          }
        )
      ]);

      return details && details?.length > 0 ? details[0] : null;
    } catch (e) {
      showRequestErrors(this.store, e);
      return null
    }
  }

  private async tryLoad<T>(promise: () => Promise<T>): Promise<T | null> {
    try {
      return await promise();
    } catch (e) {
      showRequestErrors(this.store, e);
      return null
    }
  }

  private async validateScan(scan?: Scan): Promise<void> {
    if (!scan)
      return;

    try {
      this.scanInProgress$.next(true);

      const scanItem = await this.store.dispatch(
        SOFTLINE_FEATURE_ITEM_SCAN,
        ItemScanStore.actions.load,
        scan
      );

      if (scanItem && scanItem.artikel.id) {
        this.store.commit(
          SOFTLINE_FEATURE_ARTIKEL_INFO_UMSATZABFRAGE,
          UmsatzabfrageStore.mutations.clearAbfrage,
        );
        await this.router.navigate(['/artikel-info', scanItem.artikel.id]);
      }
    } catch (e) {
      showRequestErrors(this.store, e);
      await this.router.navigate(['/artikel-info']);
    } finally {
      this.scanInProgress$.next(false);
    }
  }

  private firstDateOfYear(): string {
    return new Date(new Date().getFullYear(), 0, 1).toISOString();
  }

  private lastDayOfYear(): string {
    return new Date(new Date().getFullYear(), 11, 31).toISOString();
  }

  private createCommands(): Command[] {
    return [
      {
        icon: 'fa-regular fa-heart',
        name: 'Zu Favoriten hinzufügen',
        class: 'menu action-menu action-menu-top',
        execute: async () => {
          const id = this.activatedRoute.snapshot.params['id'];

          if (!id)
            return;

          const item = this.store.get(SOFTLINE_FEATURE_ARTIKEL_INFO_ITEM, ItemStore.getters.entity, id)

          if (!item)
            return;

          await this.favoriteService.addItemToList(item);
        }
      },
      {
        icon: 'fa-regular fa-barcode-scan',
        name: 'Scannen',
        class: 'menu action-menu action-menu-top',
        execute: async () => {
          await this.store.dispatch(
            SOFTLINE_FEATURE_SCANNER,
            ScannerStore.actions.scan,
            { labelType: ['ean128', 'ean13', 'ean8', 'code39', 'code128'] }
          );
        }
      }
    ]
  }

  sortByDate(a: any, b: any): number {
    return a?.date > b?.date ? 1 : -1
  }
}
