import { lastValueFrom } from 'rxjs';
import { SubscriptionStore2 } from '../observable/subscription.store2';
import { computed, inject, Injectable, InjectionToken } from '@angular/core';
import { SOFTLINE_SERVICE_UUID } from '../../../core.shared';
import { CancelledError } from '../../../types/errors';
import { NestedStore2Feature } from '../../nested-store2-feature';
import { LoadingState, SOFTLINE_REPOSITORY } from '../../store2.shared';
import { LoadObjectParameters } from '../../../data2/abstraction';
import { ObjectResponseCommit, ResultObjectResponseCommit } from './strategies/object-response-commit';
import { ObjectStore2 } from './object.store2';

export interface ReadonlyRepositoryObjectState {
  loadingState: LoadingState;
  loadingError: Error | null;
}

export interface ObjectLoadActionParameters extends LoadObjectParameters {
  token?: string;
}

export const SOFTLINE_FACTORY_OBJECT_RESPONSE_COMMIT = new InjectionToken<() => ObjectResponseCommit<any>>('SOFTLINE_CONFIG_DEFAULT_COMMIT_LOAD_DATA_STRATEGY',
  { providedIn: 'root', factory: () => () => new ResultObjectResponseCommit()});

@Injectable()
export class ReadonlyRepositoryObjectStore2<T extends object, TState extends ReadonlyRepositoryObjectState = ReadonlyRepositoryObjectState> extends NestedStore2Feature<TState>{

  private readonly factory = inject(SOFTLINE_FACTORY_OBJECT_RESPONSE_COMMIT)
  protected readonly commitResponse =  this.factory();

  protected readonly store = inject(ObjectStore2<T>);
  protected readonly subscription = inject(SubscriptionStore2);
  protected readonly service = inject(SOFTLINE_REPOSITORY);
  protected readonly uuid = inject(SOFTLINE_SERVICE_UUID);

  loadingState = computed(() => this.state().loadingState);
  loadingError = computed(() => this.state().loadingError);

  constructor() {
    super();
  }

  async load(params: ObjectLoadActionParameters): Promise<T> {
    this.commitPatch({ loadingState: 'loading', loadingError: null } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.load(params)
      ));
      this.commitPatch({ loadingState: 'loaded' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ loadingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ loadingState: 'failed', loadingError: e } as Partial<TState>);
      throw e;
    }
  }

  async loadOnce(params: ObjectLoadActionParameters): Promise<T> {
    const existing = this.store.value();
    if(existing)
      return existing;
    return await this.load(params);
  }

  async cancel(token: string): Promise<void> {
    this.subscription.cancel(token);
  }

  override getDefaultState(): TState {
    return {
      loadingState: null,
      loadingError: null
    } as TState;
  }
}
