@if(log(); as log) {
  <div class="flex flex-col border rounded p-2 text-sm"
      [ngClass]="{
      'error': log.response.status >= 400,
      }">
    <div class="flex flex-row justify-between">
      <div class="text-xs">{{log.request.timestamp | format:'DATE_TIME.SECONDS'}}</div>
      <span class="text-xs">{{log.response.time | duration:'S'}}ms</span>
    </div>
    <div class="flex flex-row">
      <span class="font-bold">{{log.request.method}}</span>
      <span class="ml-2"> {{log.request.url}}</span>
    </div>
    <div class="flex flex-row mt-1">
      <span>
        <span class="font-bold">{{log.response.status}}</span>
        <span class="ml-2">({{log.response.statusText}})</span>
      </span>
    </div>
    <span>{{log.response.message}}</span>
    <span>{{log.response.code}}</span>
  </div>
}
