import {
  computed,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  OnInit,
  runInInjectionContext,
  signal,
  Signal
} from '@angular/core';
import { Command2, Command2Service, showRequestErrors, MenuItem, MenuItemStore2 } from '@softline/application';
import {
  CancelledError,
  CollectionStore2,
  Dictionary,
  NestedStore2Feature,
  RepositoryCollectionStore2,
  SOFTLINE_SERVICE_UUID,
  Store
} from '@softline/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';
import { ModalStore, SOFTLINE_FEATURE_MODAL } from '@softline/ui-core';

type Constructor<T extends {}> = new (...args: any[]) => T;

type EditPageMixinParams<
  T extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>
> = {
  store: InjectionToken<TStore>;
  repositoryFeature: (o: TStore) => RepositoryCollectionStore2<T>;
  collectionFeature: (o: TStore) => CollectionStore2<T>;
};

export const WithEditPage = <
  T extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>,
  TBase extends Constructor<{}> = Constructor<{}>
>(
  params: EditPageMixinParams<T, TStore>,
  Base: TBase = class {} as any
) => {
  @Injectable()
  abstract class EditPageMixin extends Base implements OnInit, OnDestroy {
    #EDIT_MENU_NAME = this.constructor.name + 'EditMenuItems';
    #EDIT_COMMAND_NAME = this.constructor.name + 'EditCommand';
    #DELETE_COMMAND_NAME = this.constructor.name + 'DeleteCommand';

    #token: string | null = null;
    #destroying = false;
    #registeredCommands: Command2[] = [];

    #injector = inject(Injector);
    #uuid = inject(SOFTLINE_SERVICE_UUID);
    #store = inject(Store);
    #commandService = inject(Command2Service);
    #menuItemStore = inject(MenuItemStore2);

    #saving = signal(false);

    #repositoryStore: RepositoryCollectionStore2<T>;
    #collectionStore: CollectionStore2<T>;

    abstract id: Signal<string | number | null>;
    value = computed(() => {
      const id = this.id();
      const dict = this.#collectionStore.dict();
      if (!id) return null;
      return dict[id] ?? null;
    });
    abstract formValue: Signal<Partial<T> | null>;

    dirty = computed(() => {
      return this.formValue() !== null;
    });

    canDelete = signal(true);

    pathParams = signal<Dictionary<unknown> | undefined>(undefined);
    editPageMenuItems: Signal<MenuItem[]> = computed(() => {
      const saving = this.#saving();

      const menuItems: MenuItem[] = [
        {
          type: 'command',
          name: this.#EDIT_COMMAND_NAME,
          outlet: 'responsive',
          class: 'soft-button accent',
          icon: saving
            ? 'fa-regular fa-spinner fa-spin'
            : 'fa-regular fa-floppy-disk',
          title: '#SOFTAPPS_CORE.MENU_ITEMS.SAVE',
        },
      ];

      if (this.canDelete()) {
        menuItems.push({
          type: 'command',
          name: this.#DELETE_COMMAND_NAME,
          outlet: 'responsive',
          class: 'soft-button text-danger',
          icon: 'fa-regular fa-trash',
          priority: 1,
        });
      }

      return menuItems;
    });

    editPageCommands: Signal<Command2[]> = computed(() => {
      const saving = this.#saving();
      return [
        {
          name: this.#EDIT_COMMAND_NAME,
          canExecute: !saving,
          execute: async () => {
            await this.submit();
          },
        },
        {
          name: this.#DELETE_COMMAND_NAME,
          canExecute: !saving,
          execute: async () => {
            await this.delete();
          },
        },
      ];
    });

    constructor(...args: any[]) {
      super(...args);
      const injectedStore = inject(params.store);
      this.#repositoryStore = params.repositoryFeature(injectedStore);
      this.#collectionStore = params.collectionFeature(injectedStore);
    }

    async ngOnInit(): Promise<void> {
      if (super['ngOnInit']) super['ngOnInit']();

      runInInjectionContext(this.#injector, () => {
        toObservable(this.editPageCommands)
          .pipe(distinct(), takeUntilDestroyed())
          .subscribe((o) => {
            const registeredCommands = this.#registeredCommands;
            this.#registeredCommands = o;
            for (const command of registeredCommands)
              this.#commandService.remove(command.name);
            for (const command of o) {
              this.#commandService.register(command);
            }
          });
        toObservable(this.editPageMenuItems)
          .pipe(distinct(), takeUntilDestroyed())
          .subscribe((o) => {
            this.#menuItemStore.setItem(this.#EDIT_MENU_NAME, o ?? []);
          });
        toObservable(this.id)
          .pipe(distinct(), takeUntilDestroyed())
          .subscribe((o) => {
            const pathParams = this.pathParams();
            if (o) this.#repositoryStore.load(o, { pathParams });
          });
      });
    }

    ngOnDestroy(): void {
      this.#menuItemStore.removeItem(this.#EDIT_MENU_NAME);
      for (const command of this.#registeredCommands)
        this.#commandService.remove(command.name);

      if (super['ngOnDestroy']) super['ngOnDestroy']();
    }

    async submit(): Promise<void> {
      await this.save(this.formValue());
    }

    async save(value: Partial<T> | null): Promise<T | null> {
      if (!value) return null;
      const newValue = { ...this.value(), ...value };
      this.#saving.set(true);
      try {
        if (this.#token) await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        return await this.#repositoryStore.update({
          pathParams: params,
          value: newValue,
          token: this.#token,
        });
      } catch (e) {
        if (!this.#destroying && !(e instanceof CancelledError))
          showRequestErrors(this.#store, e);
        return null;
      } finally {
        this.#token = null;
        this.#saving.set(false);
      }
    }

    async delete(): Promise<void> {
      const value = this.value();
      if (!value) return;
      const result = await this.#store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.delete,
        {}
      );
      if (result !== 'DELETE') return;

      this.#saving.set(true);
      try {
        if (this.#token) await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.pathParams();
        await this.#repositoryStore.delete({
          pathParams: params,
          value: value,
          token: this.#token,
        });
      } catch (e) {
        if (!this.#destroying && !(e instanceof CancelledError))
          showRequestErrors(this.#store, e);
      } finally {
        this.#token = null;
        this.#saving.set(false);
      }
    }
  }
  return EditPageMixin;
};
