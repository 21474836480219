import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { UiCoreModule } from "@softline/ui-core";
import {
  BlockingLoadingSpinnerComponent, showRequestErrors, Scan,
  ScannerModule,
  ScannerStore,
  SOFTLINE_FEATURE_SCANNER
} from "@softline/application";
import { Store } from "@softline/core";
import { asapScheduler, observeOn, skip, Subscription } from "rxjs";
import { Lagerplatz } from "../../types/lagerplatz";
import { SOFTLINE_FEATURE_LAGERPLATZ_SUCHE } from "../../lager-core.shared";
import { LagerplatzSucheStore } from "../../store/lagerplatz-suche.store";

@Component({
  selector: 'soft-lagerplatz-suche',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ScannerModule, BlockingLoadingSpinnerComponent],
  templateUrl: './lagerplatz-suche.component.html',
  styleUrls: ['./lagerplatz-suche.component.scss']
})
export class LagerplatzSucheComponent implements OnInit, OnDestroy {

  private scanSubscription?: Subscription;
  loading$ = this.store.observe(SOFTLINE_FEATURE_LAGERPLATZ_SUCHE, LagerplatzSucheStore.getters.loading);
  loading = false;

  @Output() scan = new EventEmitter<Scan>()
  @Output() lagerplatzChange = new EventEmitter<Lagerplatz>()

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.scanSubscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(
        skip(1),
        observeOn(asapScheduler)
      )
      .subscribe(async scan => {
        if(!scan || this.loading)
          return;

        this.scan.emit(scan);
        this.loading = true;
        try {
          const lagerplatz = await this.store.dispatch(SOFTLINE_FEATURE_LAGERPLATZ_SUCHE, LagerplatzSucheStore.actions.suche, scan.data);
          this.loading = false;
          if(lagerplatz)
            this.lagerplatzChange.emit(lagerplatz);
        }
        catch (e) {
          this.loading = false;
          showRequestErrors(this.store, e);
        }
      })
  }

  ngOnDestroy(): void {
    if (this.scanSubscription && !this.scanSubscription.closed)
      this.scanSubscription.unsubscribe();
    this.scanSubscription = undefined;
  }

}
