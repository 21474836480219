<div class="pb-24 sm:pb-0">
  <h1 class="text-xl font-bold">{{'#LAGERKORREKTUR_ARTIKEL.TITLE' | translate}}</h1>
  <soft-step-header [steps]="steps"
                    [currentIndex]="0">
  </soft-step-header>

  <div class="soft-overline mt-4">
    {{'#LAGERKORREKTUR_ARTIKEL.PAGES.ARTIKEL_AUSWAHL.MENGE' | translate}}
  </div>
  <soft-mengen-eingabe *ngIf="(artikel$ | async) as artikel"
                      class="mt-1"
                      [value]="artikel.menge"
                      [einheit]="artikel.einheit.arteh"
                      [float]="true"
                      (valueChange)="onMengeChange($event)"></soft-mengen-eingabe>
</div>
<button class="fixed sm:relative left-0 right-0 bottom-0 soft-button primary w-full h-20 mt-16 sm:mt-4" (click)="onSubmit($event)">
  {{'#LAGERKORREKTUR_ARTIKEL.PAGES.ARTIKEL_AUSWAHL.SUBMIT' | translate}}
</button>
