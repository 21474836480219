import {AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, DOCUMENT, ViewportScroller} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {base64Encode, SOFTLINE_SERVICE_UUID, Store} from '@softline/core';
import {BehaviorSubject, combineLatestWith, filter, from, map, Observable, switchMap} from 'rxjs';
import {
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
  SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE
} from '../../auftrag.shared';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store'
import {WerkstattArbeitsberichtStore} from '../../store/werkstatt-arbeitsbericht.store'
import {WerkstattAuftragArtikelStore} from '../../store/werkstatt-auftrag-artikel.store'
import {
  ApplicationModule,
  BackNavigable,
  BackNavigationService,
  CommandStore,
  showRequestErrors,
  Refreshable,
  RefreshService,
  SOFTLINE_FEATURE_COMMANDS,
  SOFTLINE_FEATURE_TITLE,
  TitleStore
} from '@softline/application';
import {
  MessageBarStore,
  ModalStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  SOFTLINE_FEATURE_MODAL,
  UiCoreModule
} from '@softline/ui-core';
import {Arbeitsbericht} from '../../data/stempel';
import {Kunde} from '../../data/kunde';
import {Auftrag} from '../../data/auftrag';
import {AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION} from '@softline/auth';
import {SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_ERSTELLEN} from '../../auftrag.permissions';
import {AuftragArtikel} from '../../data/auftrag-artikel';
import {
  StartArbeitsberichtDialogComponent
} from '../../dialogs/start-arbeitsbericht-dialog/start-arbeitsbericht-dialog.component';
import {AuftragCardComponent} from '../../components/auftrag-card/auftrag-card.component';
import {
  ArtikelData,
  AuftragArtikelCardComponent
} from '../../components/auftrag-artikel-card/auftrag-artikel-card.component';
import {ArbeitsberichtCardComponent} from '../../components/arbeitsbericht-card/arbeitsbericht-card.component';
import {GetSignaturePipe} from '../../pipes/get-signature.pipe';
import {createAuftragCommands} from './auftrag.commands';
import {CloseTaskCommand} from '../../commands/close-task.command';
import {ArtikelOptionDialogComponent} from '../../dialogs/artikel-option-dialog/artikel-option-dialog.component';

const BASE_GOOGLE_URL = 'https://www.google.com/maps/search/?api=1&query=';

@Component({
  selector: 'soft-auftrag',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ApplicationModule, AuftragCardComponent, AuftragArtikelCardComponent, ArbeitsberichtCardComponent, GetSignaturePipe],
  templateUrl: './auftrag.component.html',
  styleUrls: ['./auftrag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuftragComponent implements OnInit, OnDestroy, AfterViewInit, Refreshable, BackNavigable {

  private GOOGLE_LINK = BASE_GOOGLE_URL

  readonly isSigning$ = new BehaviorSubject(false)

  readonly id$ = this.activatedRoute.params.pipe(
    map(o => +o['id']),
    filter(o => !!o),
  );

  readonly processingArtikelIndex$ = new BehaviorSubject<number | null>(null);

  readonly auftrag$: Observable<Auftrag> = this.id$.pipe(
    switchMap(id =>
      from(this.load({ id, once: true })).pipe(
        switchMap(() => this.store.observe(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
          WerkstattAuftraegeStore.getters.entity,
          id
        ))
      )
    ),
  );

  private readonly alleArbeitsberichte$: Observable<Arbeitsbericht[]> = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
    WerkstattArbeitsberichtStore.getters.all
  ) as Observable<Arbeitsbericht[]>;

  readonly arbeitsberichte$: Observable<Arbeitsbericht[]> = this.alleArbeitsberichte$.pipe(
    map(o => o as Arbeitsbericht[]),
    combineLatestWith(this.id$),
    map(([reports, orderId]) => reports.filter(o => o.orderId === orderId))
  );

  readonly arbeitsberichteLoading$ = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
    WerkstattArbeitsberichtStore.getters.loading
  );

  readonly auftragArtikel$ = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
    WerkstattAuftragArtikelStore.getters.allGrouped
  );

  readonly auftragArtikelLoading$ = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
    WerkstattAuftragArtikelStore.getters.loading
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private backNavigation: BackNavigationService,
    private refreshService: RefreshService,
    private store: Store,
    private router: Router,
    private injector: Injector,
    private viewportScroller: ViewportScroller,
    @Inject(SOFTLINE_SERVICE_UUID) private uuid: () => string,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  async ngOnInit(): Promise<void> {
    this.backNavigation.set(this);
    this.refreshService.add(this);

    this.store.commit(
      SOFTLINE_FEATURE_TITLE,
      TitleStore.mutations.setTitle,
      '#WORKSHOP.COMPONENTS.DETAIL.TITLE'
    );

    const id = this.activatedRoute.snapshot.params['id'];

    this.store.commit(
      SOFTLINE_FEATURE_COMMANDS,
      CommandStore.mutations.addSet,
      {
        name: AuftragComponent,
        commands: createAuftragCommands({
          store: this.store,
          router: this.router,
          injector: this.injector,
          getTask: () => this.store.get(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
            WerkstattAuftraegeStore.getters.entity,
            id
          ),
          permission: this.store.get(SOFTLINE_FEATURE_AUTHORIZATION, AuthorizationStore.getters.permissions),
          timeReport: this.store.get(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
            WerkstattArbeitsberichtStore.getters.active
          ),
        })
      }
    )
  }

  ngAfterViewInit(): void {
    const element = this.document.getElementsByClassName('content')[0];

    if (!element)
      return;

    element.scrollTo({ top: 0, left: 0 });
  }

  ngOnDestroy() {
    this.backNavigation.set(undefined);
    this.refreshService.remove(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setSubTitle, '');
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '');
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, AuftragComponent);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['werkstaettenauftrag']);
  }

  async refresh(): Promise<void> {
    const id = +this.activatedRoute.snapshot.params['id'];
    await this.load({ id, once: false });
  }

  getGoogleLink(customer: Kunde | undefined): string {
    if (!customer)
      return ''

    this.GOOGLE_LINK = BASE_GOOGLE_URL;
    this.GOOGLE_LINK += (customer.strasse ? customer.strasse + (customer.strassenr ? customer.strassenr : '') + ',' : '');
    this.GOOGLE_LINK += (customer.plz ? customer.plz + ',' : '');
    this.GOOGLE_LINK += (customer.ort ? customer.ort + ',' : '');
    return encodeURI(this.GOOGLE_LINK.slice(0, -1));
  }

  async navigateToAddTaskItems(auftragId: number): Promise<void> {
    await this.router.navigate(['/werkstaettenauftrag', auftragId, 'artikel']);
  }

  async navigateToManualWorkReport(auftrag: Auftrag): Promise<void> {
    const isAuthorized = this.store.get(
      SOFTLINE_FEATURE_AUTHORIZATION,
      AuthorizationStore.getters.authorized,
      SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_ERSTELLEN
    );

    if (isAuthorized) {
      await this.router.navigate(['/werkstaettenauftrag', auftrag.id, 'arbeitsbericht']);
    } else {
      await this.presentArbeitsberichtDialog(auftrag);
    }
  }

  async closeTask(auftrag: Auftrag): Promise<void> {
    await new CloseTaskCommand(this.store, () => auftrag, this.router).execute();
  }

  async signTask(auftrag: Auftrag, file: Blob | null | undefined): Promise<void> {
    this.isSigning$.next(true);

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
        WerkstattAuftraegeStore.actions.sign,
        {
          id: auftrag.id,
          signature: { files: [file] },
        }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: '#WORKSHOP.MESSAGES.SUCCESS.TITLE',
          message: '#WORKSHOP.MESSAGES.SUCCESS.SIGN'
        }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        {
          duration: 10000,
          title: '#WORKSHOP.DIALOGS.ERROR_SUBJECT',
          message: '#WORKSHOP.DIALOGS.SIGNATURE.ERROR',
        }
      );
    } finally {
      this.isSigning$.next(false);
    }
  }

  async moreTapped(item: ArtikelData, auftrag: Auftrag, index: number): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open<'delete' | 'addAgain' | null, { item: ArtikelData }>(),
      {
        component: ArtikelOptionDialogComponent,
        dismiss: true,
        data: { item }
      }
    );

    if (!result || result === 'DISMISSED')
      return;

    this.processingArtikelIndex$.next(index);

    if (result === 'delete') {
      try {
        await this.deleteTaskItems(item?.entities || []);
      } catch (e) {
        this.processingArtikelIndex$.next(null);
        showRequestErrors(this.store, e);
      }
    } else if (result === 'addAgain') {
      await this.router.navigate(['/werkstaettenauftrag', auftrag.id, 'artikel'], {
        queryParams: { prefill: base64Encode(JSON.stringify(item?.artikel))  }
      });
    }

    this.processingArtikelIndex$.next(null);
  }

  async deleteTaskItems(artikel: AuftragArtikel[]): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
        WerkstattAuftragArtikelStore.actions.removeArtikel,
        artikel
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        { title: '#WORKSHOP.TASK_DETAILS.DELETE.ITEM.SUCCESS' }
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        { title: '#WORKSHOP.TASK_DETAILS.DELETE.ITEM.ERROR' }
      );
    } finally {
      this.processingArtikelIndex$.next(null);
    }
  }

  async deleteArbeitsbericht(arbeitsbericht: Arbeitsbericht): Promise<void> {
    try {
      const changes = this.store.get(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
        WerkstattArbeitsberichtStore.getters.changes
      );

      if (changes?.length > 0) {
        this.store.commit(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
          WerkstattArbeitsberichtStore.mutations.resetChanges
        );
      }

      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
        WerkstattArbeitsberichtStore.actions.prepareDelete,
        arbeitsbericht
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
        WerkstattArbeitsberichtStore.actions.saveAll,
        { pathParams: {}, token: this.uuid() }
      );

    } catch (e) {
      console.log(e);
      showRequestErrors(this.store, e);
    }
  }

  private async presentArbeitsberichtDialog(auftrag: Auftrag): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'START_ACTIVITY',
        component: StartArbeitsberichtDialogComponent,
        data: { auftrag },
        dismiss: { button: true, backdrop: true }
      }
    );
  }

  private async load({ id, once }: { id: number, once: boolean }): Promise<void> {
    try {
      await Promise.all([
        this.store.dispatch(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
          WerkstattAuftraegeStore.actions[once ? 'loadOnce' : 'load'],
          { id }
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
          WerkstattArbeitsberichtStore.actions.loadMany,
          { clear: true, queryParams: { orderId: id } }
        ),
        this.store.dispatch(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL,
          WerkstattAuftragArtikelStore.actions.loadMany,
          { clear: true, queryParams: { orderId: id } }
        )
      ]);
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
