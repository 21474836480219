import {DefaultSearchResultViewComponent, SearchStrategy} from './../search.strategy';
import {FieldOkParameters, FieldOkService, FieldOkServiceOptions} from '@softline/dynamic';
import {inject, InjectionToken, signal, Type} from '@angular/core';
import {catchError, distinctUntilChanged, filter, firstValueFrom, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {toSignal} from '@angular/core/rxjs-interop';
import {ConnectionHttpService} from '@softline/core';
import {SearchResultComponent} from '../abstraction/search-result.component';
import { FavoriteStore2 } from '../../../user/favorites/favorite.store2';
import { LastUsedStore2 } from '../../../user/last-used/last-used.store2';
import { Favorite } from '../../../user/favorites/favorite';

export interface FieldOkSearchStrategyConfig<T extends object> {
  queryFormatter?: (query: string | null) => FieldOkParameters<T>
}

export interface FieldOkSearchConfig<T extends object> {
  name: string;
  view: Type<SearchResultComponent<any>>;
  options?: {
    autoLoad?: boolean;
    serviceOptions?: FieldOkServiceOptions<T>;
  }
}

export const SOFTLINE_CONFIG_FIELD_OK_SEARCH_PAGE = new InjectionToken<FieldOkSearchConfig<any>[]>('FIELD_OK_SEARCH_VIEW');

export class FieldOkSearchStrategy<T extends object> extends SearchStrategy<T> {

  private favoriteStore = inject(FavoriteStore2);
  private lastUsedStore = inject(LastUsedStore2);

  protected readonly searchStream$ = new Subject<string | null>();

  protected fokService: FieldOkService<T>;
  protected fokSearchConfig?: FieldOkSearchConfig<any>;

  override get resultViewType() {
    return this.fokSearchConfig?.view ?? DefaultSearchResultViewComponent;
  }

  override results= toSignal(
    this.searchStream$.pipe(
      filter(o =>
        this.fokSearchConfig?.options?.autoLoad === true
          ? true
          : (!!o && o?.length > 2)
      ),
      distinctUntilChanged(),
      switchMap(filter =>
        this.query(filter).pipe(
          tap({
            subscribe: () => this.loadingState.set('loading'),
            unsubscribe: () => this.loadingState.set('idle'),
            complete: () => this.loadingState.set('loaded'),
            error: () => this.loadingState.set('error'),
          }),
          catchError(() => of([]))
        )
      ),
    ), { initialValue: [] }
  );

  override favorites = this.favoriteStore.favorites(this.fieldOkName);
  override lastUsed = this.lastUsedStore.lastUsed(this.fieldOkName);

  override loadingState = signal<'loaded' | 'loading' | 'idle' | 'error'>('idle');

  constructor(protected fieldOkName: string, protected config?: FieldOkSearchStrategyConfig<T>) {
    const connectionHttpService = inject(ConnectionHttpService);
    const searchConfigs = inject(SOFTLINE_CONFIG_FIELD_OK_SEARCH_PAGE)

    super();

    this.fokSearchConfig = searchConfigs.find(o => o.name === this.fieldOkName);

    if (!this.fokSearchConfig) {
      console.warn(`[FieldOkSearchStrategy] - No configuration with name "${fieldOkName}" for token FIELD_OK_SEARCH_VIEW!`)
    }

    this.fokService = new FieldOkService<T>(connectionHttpService, fieldOkName, this.fokSearchConfig?.options?.serviceOptions);
  }

  searchInputChange(value: string | null) {
    if ((!value || value?.length <= 2) && this.loadingState() !== 'idle' && this.fokSearchConfig?.options?.autoLoad !== true) {
      this.loadingState.set('idle');
    }

    this.searchStream$.next(value);
  }

  async loadFavorites(): Promise<Favorite<T>[]> {
    return await this.favoriteStore.load(this.fieldOkName);
  }

  async loadLastUsed(): Promise<Favorite<T>[]> {
    return await this.lastUsedStore.load(this.fieldOkName);
  }

  async search(value: string | null): Promise<T[]> {
    return await firstValueFrom(this.query(value));
  }

  favoriteChange(value: T) {
    const favorites = this.favorites();
    if(favorites.find(o => o.id === value['id']))
      this.favoriteStore.remove(this.fieldOkName, value['id']);
    else
      this.favoriteStore.add(this.fieldOkName, value['id'], value);
  }

  addToLastUsed(value: T) {
    this.lastUsedStore.add(this.fieldOkName, value['id'], value);
  }

  private query(filter: string | null): Observable<T[]> {
    return this.fokService.query(
      this.config?.queryFormatter
        ? this.config.queryFormatter(filter)
        : {
          filter: filter ?? '',
          multiValued: false,
          maxAbfrageResults: 1000,
          parameters: {}
        }
    ) as Observable<T[]>;
  }
}
