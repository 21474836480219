import { IndexedDBConfig } from '@softline/core';
import {
  SOFTLINE_NAME_PAGE_SETTINGS_OBJECT_STORE
} from './page-settings/page-settings.shared';
import { SOFTLINE_NAME_USER_INDEXED_DB } from '../softapps-core.shared';
import { SOFTLINE_NAME_FAVORITES_OBJECT_STORE } from './favorites/favorite.shared';
import { SOFTLINE_NAME_LAST_USED_OBJECT_STORE } from './last-used/last-used.shared';

export const SOFTLINE_INDEXED_DB_USER: IndexedDBConfig = {
  version: 1,
  name: SOFTLINE_NAME_USER_INDEXED_DB,
  objectStoreConfigs: [
    {
      name: SOFTLINE_NAME_PAGE_SETTINGS_OBJECT_STORE,
      parameters: { keyPath: 'name', autoIncrement: false },
      schema: [],
    },
    {
      name: SOFTLINE_NAME_FAVORITES_OBJECT_STORE,
      parameters: { keyPath: 'key', autoIncrement: false },
      schema: [],
    },
    {
      name: SOFTLINE_NAME_LAST_USED_OBJECT_STORE,
      parameters: { keyPath: 'key', autoIncrement: false },
      schema: [],
    }
  ],
};
