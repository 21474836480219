import { inject, StaticProvider } from '@angular/core';
import { IndexedDbService, SOFTLINE_STORE_FEATURES } from '@softline/core';
import { SOFTLINE_NAME_LAST_USED_OBJECT_STORE, SOFTLINE_REPOSITORY_LAST_USED } from './last-used.shared';
import { LastUsedStore2 } from './last-used.store2';
import { UserIndexedDbDictionaryRepository } from '../repositories/user-indexed-db-dictionary.repository';


export const lastUsedProviders: StaticProvider[] = [
  { provide: SOFTLINE_REPOSITORY_LAST_USED,
    useFactory: () => new UserIndexedDbDictionaryRepository(
      inject(IndexedDbService),
      SOFTLINE_NAME_LAST_USED_OBJECT_STORE
    )},
  { provide: SOFTLINE_STORE_FEATURES, useExisting: LastUsedStore2, multi: true },
]
