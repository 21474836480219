import {
  ENVIRONMENT_INITIALIZER,
  ImportedNgModuleProviders,
  importProvidersFrom,
  inject,
  Provider
} from "@angular/core";
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  Store,
  StoreModule
} from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { AddOn, ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application";
import { default as de } from "../i18n/de.json";
import {
  SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, SOFTLINE_FEATURE_UMBUCHUNGSARTIKEL,
  SOFTLINE_PERMISSION_LAGERKORREKTUR_ARTIKEL
} from "./lagerkorrektur-artikel.shared";
import { KommissionierungAddOn } from "./add-ons/kommissionierung.add-on";
import { LagerkorrekturArtikelService } from "./services/lagerkorrektur-artikel.service";
import { LagerkorrekturArtikelStore, UmbuchungsartikelStore } from "./store";
import { SOFTLINE_API_UMBUCHUNGSARTIKEL } from "./lagerkorrektur-artikel.api";

export const lagerkorrekturArtikelProviders: (Provider | ImportedNgModuleProviders)[] = [
  LagerkorrekturArtikelService,
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL,
      feature: LagerkorrekturArtikelStore.feature,
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_UMBUCHUNGSARTIKEL,
      feature: UmbuchungsartikelStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service) => new SoftlineEntityService(service, SOFTLINE_API_UMBUCHUNGSARTIKEL),
          deps: [SOFTLINE_SERVICE_HTTP]
        },
      ]
    }),
  ),
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'lagerkorrekturArtikel',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#LAGERKORREKTUR_ARTIKEL.APP',
        description: '#LAGERKORREKTUR_ARTIKEL.APP_DESC',
        route: '/lagerkorrektur-artikel',
        icon: 'fa-regular fa-circle-minus',
        permission: SOFTLINE_PERMISSION_LAGERKORREKTUR_ARTIKEL
      });
    },
    multi: true
  },
  { provide: AddOn, useClass: KommissionierungAddOn, multi: true },
];
