import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductSupplier } from '../../types/product-supplier';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-product-supplier-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './product-supplier-card.component.html',
  styleUrl: './product-supplier-card.component.scss',
})
export class ProductSupplierCardComponent {
  productSupplier = input.required<ProductSupplier>();
}
