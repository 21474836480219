import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { asapScheduler, BehaviorSubject, observeOn, skip, Subscription } from "rxjs";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
    BackNavigable,
    BackNavigationService,
    BlockingLoadingSpinnerComponent, showRequestErrors,
    ItemScanStore, HeaderComponent,
    ScanButtonComponent,
    ScannerStore,
    SOFTLINE_FEATURE_ITEM_SCAN,
    SOFTLINE_FEATURE_SCANNER,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { I18nModule } from "@softline/ui-core";
import { Artikel, LagerplatzSucheComponent } from "@softapps/lager/core";
import { DynamicModule, EntityListDataView } from "@softline/dynamic";
import { ReactiveFormsModule } from "@angular/forms";
import { SOFTLINE_FEATURE_LAGERSTAND } from "../../lagerstand.shared";
import { LagerstandStore } from "../../store";


@Component({
  selector: 'soft-artikel-eingabe',
  standalone: true,
    imports: [CommonModule, BlockingLoadingSpinnerComponent, I18nModule, LagerplatzSucheComponent, StepHeaderComponent, ScanButtonComponent, DynamicModule, ReactiveFormsModule, HeaderComponent],
  templateUrl: './artikel-eingabe.page.html',
  styleUrls: ['./artikel-eingabe.page.scss']
})
export class ArtikelEingabePage implements OnInit, BackNavigable {

  private subscription?: Subscription;

  steps: Step[] = [
    {
      header: '#LAGERSTAND.PAGES.ARTIKEL_EINGABE.TITLE',
      subHeader: '#LAGERSTAND.PAGES.ARTIKEL_EINGABE.DESCRIPTION',
      routerLink: ['lagerstand']
    },
    {
      header: '#LAGERSTAND.PAGES.ERGEBNIS.TITLE',
      subHeader: '#LAGERSTAND.PAGES.ERGEBNIS.DESCRIPTION'
    }
  ]

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERSTAND.TITLE');
    this.subscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(
        skip(1),
        observeOn(asapScheduler)
      )
      .subscribe(async scan => {
        if(!scan || this.loading$.value)
          return;
        this.loading$.next(true);
        try {
          const palette = await this.store.dispatch(SOFTLINE_FEATURE_LAGERSTAND, LagerstandStore.actions.loadPalette, scan.data);
          if(palette && palette.inhalt.length > 0)
            await this.onItemChange(palette.inhalt[0].artikel as Artikel);
        }
        catch (e) {
          showRequestErrors(this.store, e)
        }
        this.loading$.next(false);
      })
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/'])
  }

  async onItemChange(artikel: Artikel): Promise<void> {
    this.router.navigate(['lagerstand', artikel.id]);
  }
}
