import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {CommonModule} from '@angular/common';
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {
  Artikel,
  ArtikelStore,
  ArtikelSuchverlaufStore,
  SearchEntry,
  SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
  SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
  SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF
} from "@softapps/wws/core";
import {map} from "rxjs/operators";
import {DateService, Store} from "@softline/core";
import {UiCoreModule} from "@softline/ui-core";
import {showRequestErrors} from "@softline/application";
import {ArtikelSuchergebnisComponent} from "./artikel-suchergebnis/artikel-suchergebnis.component";
import {ArtikelSuchverlaufComponent} from "./artikel-suchverlauf/artikel-suchverlauf.component";
import {ArtikelFavoritenStore} from '../../../store/artikel-favoriten.store';

@Component({
  selector: 'soft-artikel-textsuche',
  standalone: true,
  imports: [CommonModule, UiCoreModule, ArtikelSuchergebnisComponent, ArtikelSuchverlaufComponent],
  templateUrl: './artikel-textsuche.component.html',
  styleUrls: ['./artikel-textsuche.component.scss']
})
export class ArtikelTextsucheComponent implements OnInit {

  readonly loading$ = new BehaviorSubject<boolean>(false);
  readonly searchText$ = new BehaviorSubject<string | null | undefined>(undefined);

  @Output()
  selectArtikel = new EventEmitter<Artikel>();

  readonly searchHistory$: Observable<SearchEntry[]> = this.store.observe(
    SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF,
    ArtikelSuchverlaufStore.getters.all
  ).pipe(map(o => o.reverse()));

  readonly favoriteIds$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
    ArtikelFavoritenStore.getters.ids
  );

  readonly itemsEntities$ = this.store.observe(
    SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
    ArtikelStore.getters.entities
  );

  readonly items$: Observable<Artikel[]> = this.store.observe(
    SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
    ArtikelStore.getters.all
  );


  readonly itemsWithFavorite$: Observable<{ item: Artikel; favorite: boolean }[]> = combineLatest([
    this.favoriteIds$,
    this.items$
  ]).pipe(map(([favoriteIds, entites]) => {
    return entites.map(item => {
      return {
        item,
        favorite: this.store.get(SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN, ArtikelFavoritenStore.getters.isFavorite, item.id),
      }
    })
  }))

  constructor(private store: Store, private dateService: DateService) { }

  ngOnInit(): void {
  }

  async search(search: string | null | undefined): Promise<void> {
    this.searchText$.next(search);
    if (!search)
      return;

    await this.saveSearch(search);
    if (!search)
      this.store.commit(SOFTLINE_FEATURE_WWS_ARTIKELSUCHE, ArtikelStore.mutations.clear);
    else
      await this.loadItems(search);
  }

  async saveSearch(searchTerm: string): Promise<void> {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_ARTIKELSUCHVERLAUF,
      ArtikelSuchverlaufStore.mutations.addOrUpdate,
      { text: searchTerm, date: this.dateService.now() }
    );
  }

  private async loadItems(query?: string | null) {
    try {
      if (query)
        this.loading$.next(true);

      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
        ArtikelStore.actions.cancelAll,
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
        ArtikelStore.actions.loadMany,
        {
          clear: true,
          offset: 0,
          limit: 80,
          query: !query || query?.length < 2 ? {} : { query },
        }
      );
    } catch (e) {
      console.log(e);
      showRequestErrors(this.store, e);
    } finally {
      this.loading$.next(false);
    }
  }

  onEmptyTextfield(): void {
    this.store.commit(
      SOFTLINE_FEATURE_WWS_ARTIKELSUCHE,
      ArtikelStore.mutations.clear
    );
  }

  async onAddToFavorites(artikel: Artikel): Promise<void> {
    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN,
        ArtikelFavoritenStore.actions.create,
        { entity: { item: {id: artikel.id} } }
      );
    } catch (e: unknown){
      console.error(e);
    }
  }

  async onRemoveFromFavorites(artikel: Artikel): Promise<void> {
    try {
      const favorite = this.store.get(SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN, ArtikelFavoritenStore.getters.getFavorite, artikel.id);

      if (!favorite)
        return;

      await this.store.dispatch(SOFTLINE_FEATURE_WWS_ARTIKELFAVORITEN, ArtikelFavoritenStore.actions.delete, { entity: favorite })
    } catch (e: unknown){
      console.error(e);
    }
  }
}
