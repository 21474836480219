import { Inject, Injectable } from "@angular/core";
import { ResourceService, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable } from "rxjs";
import {
  SOFTLINE_API_SCAN_PALETTE,
  SOFTLINE_API_SCAN_QUELLLAGERPLATZ,
  SOFTLINE_API_UMLAGERUNG
} from "../lagerplatz-umlagerung.api";
import { ArtikelMenge, Lagerplatz, LagerplatzInhalt } from "@softapps/lager/core";

@Injectable()
export class LagerplatzUmlagerungService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ResourceService<any>) {
  }

  getQuelllagerplatz(code: string): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_SCAN_QUELLLAGERPLATZ,
      pathParams: {code}
    }
    return this.service.get(location);
  }

  getPalette(code: string): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_SCAN_PALETTE,
      pathParams: {code}
    }
    return this.service.get(location);
  }

  umlagerung(artikelMenge: ArtikelMenge[], quellLagerplatz: Lagerplatz, zielLagerplatz: Lagerplatz) {
    const location = { path: SOFTLINE_API_UMLAGERUNG }
    return this.service.get(location, {
      artikelMenge,
      quellLagerplatz,
      zielLagerplatz
    });
  }
}
