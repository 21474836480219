import { Component, forwardRef, input, TemplateRef, viewChild, viewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelViewBase } from '../panel-view.base';

@Component({
  selector: 'soft-panel-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './panel-view.component.html',
  styleUrl: './panel-view.component.scss',
  providers: [
    { provide: PanelViewBase, useExisting: forwardRef(() => PanelViewComponent) }
  ],
})
export class PanelViewComponent extends PanelViewBase {
  override name = input.required<string>();
  override label = input<string>();
  override icon = input<string>('fa fa-display');
  override template = viewChild<TemplateRef<any>>('template');
}
