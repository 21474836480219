import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Modal} from '@softline/ui-core';
import {ArtikelData} from '../../components/auftrag-artikel-card/auftrag-artikel-card.component';

@Component({
  selector: 'soft-artikel-option-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './artikel-option-dialog.component.html',
  styleUrl: './artikel-option-dialog.component.scss',
})
export class ArtikelOptionDialogComponent implements Modal<'delete' | 'addAgain' | null> {

  item!: ArtikelData;
  close!: (result: ("delete" | "addAgain" | null)) => void;

  registerCloseHandler(handler: (result: ("delete" | "addAgain" | null)) => void) {
    this.close = handler;
  }
}
