import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-sale-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sale-card.component.html',
  styleUrl: './sale-card.component.scss',
})
export class SaleCardComponent {}
