import { Type } from '@angular/core';
import { PriceCardComponent } from './components/price-card/price-card.component';
import { SaleCardComponent } from './components/sale-card/sale-card.component';
import { SalesUnitCardComponent } from './components/sales-unit-card/sales-unit-card.component';
import { PromotionCardComponent } from './components/promotion-card/promotion-card.component';
import { SalesPriceCardComponent } from './components/sales-price-card/sales-price-card.component';
import { SupplierPriceCardComponent } from './components/supplier-price-card/supplier-price-card.component';
import { SupplierPromotionCardComponent } from './components/supplier-promotion-card/supplier-promotion-card.component';
import { ProductSupplierCardComponent } from './components/product-supplier-card/product-supplier-card.component';
import {ContactCardComponent} from "./components/contact-card/contact-card.component";

export const wwsCoreLib: Type<any>[] = [
  PriceCardComponent,
  SaleCardComponent,
  SalesUnitCardComponent,
  PromotionCardComponent,
  SalesPriceCardComponent,
  SupplierPriceCardComponent,
  SupplierPromotionCardComponent,
  ProductSupplierCardComponent,
  ContactCardComponent
]
