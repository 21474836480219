import {Component, input, output, Signal, viewChild} from '@angular/core';
import { FieldOkComponent } from '@softline/dynamic';
import {ControlValueAccessor} from '@angular/forms';
import {DefaultFieldOkConfiguration} from './field-ok-configuration';

export abstract class FieldOkWrapper<T extends DefaultFieldOkConfiguration> implements ControlValueAccessor {
  value = input<object | null>(null);
  configuration = input<T | null>(null);
  parameters = input<object>({});
  readonly = input<boolean>(false);

  valueChange = output<object | null>();

  abstract fieldOkComponent: Signal<FieldOkComponent<any>>;

  registerOnChange(fn: any): void {
    this.fieldOkComponent().registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.fieldOkComponent().registerOnTouched(fn);
  }

  writeValue(obj: any): void {
    this.fieldOkComponent().writeValue(obj);
  }
}
