import { DateService, Dictionary, InjectableStore2Feature, Store2Feature } from '@softline/core';
import {computed, inject, Injectable} from '@angular/core';
import {LastUsed} from './last-used';
import { SOFTLINE_REPOSITORY_LAST_USED } from './last-used.shared';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LastUsedStore2<T> extends Store2Feature<Readonly<Dictionary<LastUsed<T>[]>>> {

  name = 'last-used';

  dateService = inject(DateService);
  repository = inject(SOFTLINE_REPOSITORY_LAST_USED);

  lastUsed = (key: string) => computed(() => {
    const value = this.state()[key] as LastUsed<T>[];
    return value ?? [];
  })

  constructor() {
    super();
  }

  async load(key: string): Promise<LastUsed<T>[]> {
    const lastUsed = await lastValueFrom(this.repository.loadItem(key));
    this.commit({ [key]: lastUsed });
    return lastUsed;
  }

  async add(key: string, id: string | number, object: T): Promise<void> {
    const timestamp = this.dateService.now();
    let lastUsed = this.state()[key] as LastUsed<T>[] ?? [];
    const index = lastUsed.findIndex(o => o.id === id);
    if(index !== -1) {
      lastUsed = [...lastUsed];
      lastUsed.splice(index, 1, { id, object, timestamp });
    }
    else
      lastUsed = [{ id, object, timestamp }, ...lastUsed];
    if(lastUsed.length > 10)
      lastUsed.pop();
    await lastValueFrom(this.repository.saveItem(key, lastUsed));
    this.commitPatch({ [key]: lastUsed } as any);
  }

  async clear(): Promise<void> {
    this.commit({ });
  }

  override getDefaultState(): Readonly<Dictionary<LastUsed<T>[]>> {
    return { } as Dictionary<LastUsed<T>[]>;
  }
}
