import { ResourceService } from '../../data/abstraction';
import { CRUDParameters, DictionaryRepository, LoadObjectParameters, Repository, SaveDictionaryParameters } from '../abstraction';
import { Observable } from 'rxjs';
import { IndexedDbResourceLocation } from '../../data/specialized/indexed-db/indexed-db.location';
import { ReadonlyIndexedDbDictionaryRepository } from './readonly-indexed-db-dictionary.repository';
import { Dictionary } from '../../types/dictionary';

export class IndexedDbDictionaryRepository<T, TResult> extends ReadonlyIndexedDbDictionaryRepository<TResult> implements DictionaryRepository<T, TResult> {
  constructor(service: ResourceService<IndexedDbResourceLocation>,
              databaseName: string,
              objectStoreName: string) {
    super(service, databaseName, objectStoreName);
  }

    save(dictionary: Dictionary<T>, params?: SaveDictionaryParameters | undefined): Observable<TResult> {
      const location: IndexedDbResourceLocation = {
        databaseName: this.databaseName,
        objectStoreName: this.objectStoreName,
      };
      return this.service.update(location, dictionary);
    }
    saveItem(key: string, value: T, params?: SaveDictionaryParameters | undefined): Observable<TResult> {
      const location: IndexedDbResourceLocation = {
        databaseName: this.databaseName,
        objectStoreName: this.objectStoreName,
        key
      };
      return this.service.update(location, value);
    }
    deleteItem(key: string, params?: SaveDictionaryParameters | undefined): Observable<TResult> {
      const location: IndexedDbResourceLocation = {
        databaseName: this.databaseName,
        objectStoreName: this.objectStoreName,
        key
      };
      return this.service.delete(location);
    }
    delete(params?: SaveDictionaryParameters | undefined): Observable<TResult> {
      const location: IndexedDbResourceLocation = {
        databaseName: this.databaseName,
        objectStoreName: this.objectStoreName,
      };
      return this.service.delete(location);
    }
}
