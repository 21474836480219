import { Price } from './price';
import { Unit } from './unit';

export interface SupplierPrice {
  distributionChannel: {
    key: string;
    name: string;
  };
  validFrom: string;
  validTo: string;
  currency: string;
  supplierConditions: {
    key: string;
    name: string;
    terms: string;
  }
  unit: Unit;
  prices: Price[];
}
