import { Injector, Type } from '@angular/core';
import { Modal } from './modal';

export interface DismissConfig {
  backdrop?: boolean;
  escape?: boolean;
  button?: boolean;
}

export interface ModalConfig<T extends Modal<U> & V, U, V extends object = {}> {
  id: string;
  component: Type<T>;
  class?: string;
  data?: V;
  priority?: number;
  dismiss?: boolean | DismissConfig;
  injector?: Injector;
  callback?(result: U): void;
  onDismiss?(): void;

  /**
   * @deprecated Use injector instead in combination with the ModalService instead
   * TODO: Remove after deleting the ModalStore
   */
  getInjector?(): Injector; // required because property: Injector Breaks Chromes DevTools Extension
}

export interface CommonModalConfig<
  T extends Modal<U> & V,
  U,
  V extends object = {}
> {
  id?: string;
  component: Type<T>;
  class?: string;
  data?: V;
  priority?: number;
  dismiss?: boolean | DismissConfig;
  injector?: Injector;
  /**
   * @deprecated Use injector instead in combination with the ModalService instead
   * TODO: Remove after deleting the ModalStore
   */
  getInjector?(): Injector; // required because property: Injector Breaks Chromes DevTools Extension
}


export function isCommonModalConfig(config: unknown): config is CommonModalConfig<any, any, any> {
  return !!(config as CommonModalConfig<any, any, any>).component;
}
