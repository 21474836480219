@if(config(); as config) {
  <div [ngClass]="config.class ?? ''" class="soft-message-bar" @fadeInOut>
    <div class="start">
      @if(config.icon) {
        <soft-icon [name]="config.icon" class="soft-message-bar-icon"></soft-icon>
      }
      <div class="flex flex-col w-full">
        <div class="flex flex-col">
          @if (config.title; as title) {
            <strong *ngIf="config.title" class="mr-1">{{config.title | translate | interpolate: config.params }}</strong>
          }
          <span class="mr-1">{{config.message | translate | interpolate: config.params }}</span>
        </div>

        @if (hasActions()) {
          <div class="flex items-center gap-3 mt-1">
            @for (button of buttons(); track button) {
              <button (click)="button?.onExecute()"
                      class="soft-button bg-[rgba(0,0,0,0.175)] rounded soft-primary-button-small mr-2">
                {{ button.title | translate }}
              </button>
            }
            @for (link of links(); track link) {
              <a (click)="link?.onExecute()" class="soft-link mr-1">{{ link.title | translate }}</a>
            }
          </div>
        }
      </div>
    </div>

    <div class="end">
      @if (config.dismiss?.canDismiss !== false) {
        <soft-icon name="fa-regular fa-xmark" class="dismiss mr-2"
                   (click)="onDismiss(config.id, config.onDismiss)"></soft-icon>
      }
    </div>
  </div>
}
