export enum PdfEditorToolGroupName {
  Drawing = 'DRAWING',
  Stamp = 'STAMP',
  Signature = 'SIGNATURE',
}

export enum PdfEditorToolType {
  Pen = 'PEN',
  Marker = 'MARKER',
  TextBox = 'TEXT_BOX',
  Image = 'IMAGE',
  Note = 'NOTE',
}

export interface PdfEditorTool {
  id: string | number;
  type: PdfEditorToolType;
  title?: string;
  icon?: string;
  url?: string;
  options?: any;
  customizations?: boolean | { [key: string]: any };
  isCustom?: boolean;
}

export interface PdfEditorToolGroup {
  name: PdfEditorToolGroupName;
  title: string;
  allowCustomTool: boolean;
  tools: PdfEditorTool[];
}
