import {
  ActionStore,
  CollectionStore,
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  QueryStore,
  RemoteCollectionStore,
  select,
  SOFTLINE_SERVICE_UUID,
  StoreBuilder,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { KommissionsauftragKopf, KommissionsauftragKopfFilter } from "../types/kommissionsauftrag";
import { lastValueFrom } from "rxjs";
import { KommissionierungService } from "../services/kommissionierung.service";
import { ArtikelMenge, LagerplatzInhalt } from "@softapps/lager/core";


export interface State extends RemoteCollectionStore.State<KommissionsauftragKopf>, QueryStore.State<KommissionsauftragKopfFilter> {
  mengen: {idkopfkomm: number, idbewekomm: number, menge: number}[]
  lagerplatzvorschlaege: {idkopfkomm: number, idbewekomm: number, lagerplatzvorschlag: LagerplatzInhalt}[];
}

export class KommissionierungStoreExtension {
  static mutations = {
    setLagerplatzvorschlag: createMutation<State, {idkopfkomm: number, idbewekomm: number, lagerplatzvorschlag: LagerplatzInhalt}>('setLagerplatzvorschlag'),
    setMenge: createMutation<State, {idkopfkomm: number; idbewekomm: number, menge: number}>('setMenge'),
    removeLagerplatzvorschlag: createMutation<State, {idkopfkomm: number, idbewekomm: number}>('removeLagerplatzvorschlag'),
    removeMenge: createMutation<State, {idkopfkomm: number; idbewekomm: number}>('removeMenge')
  }

  static getters = {
    lagerplatzvorschlag: createGetter<State, LagerplatzInhalt, {idkopfkomm: number, idbewekomm: number}>('lagerplatzvorschlag'),
    menge: createGetter<State, number, {idkopfkomm: number; idbewekomm: number}>('menge'),
    filtered: createGetter<State, KommissionsauftragKopf[]>('filtered')
  }

  static actions = {
    loadLagerplatzVorschlag: createAction<State, {idkopfkomm: number, idbewekomm: number, artikelMenge: ArtikelMenge}, LagerplatzInhalt>('loadLagerplatzVorschlag'),
    loadLagerplatz: createAction<State, {idkopfkomm: number, idbewekomm: number, code: string}, LagerplatzInhalt>('loadLagerplatz'),
    artikelKommissionierung: createAction<State, {idkopfkomm: number, idbewekomm: number}, KommissionsauftragKopf>('artikelKommissionierung'),
    abschluss: createAction<State, {idkopfkomm: number}, KommissionsauftragKopf>('abschluss'),
    begleitscheindruck: createAction<State, {idkopfkomm: number, begleitscheine: number}>('begleitscheindruck'),
    sperre: createAction<State, {idkopfkomm: number, force?: boolean}, KommissionsauftragKopf>('sperre'),
  }

  static feature: Partial<StoreFeature<any>> = {
    initialState: {
      mengen: [],
      lagerplatzvorschlaege: [],
      query: { }
    },
    mutations: [
      mutate(KommissionierungStoreExtension.mutations.setLagerplatzvorschlag,
        ({state, params}) => {
        const lagerplatzvorschlaege = [...state.lagerplatzvorschlaege];
        const index = lagerplatzvorschlaege.findIndex(o => o.idkopfkomm === params.idkopfkomm && o.idbewekomm === params.idbewekomm);
        if (index === -1)
          lagerplatzvorschlaege.push(params)
        else
          lagerplatzvorschlaege.splice(index, 1, params)
        return {...state, lagerplatzvorschlaege };
        }),
      mutate(KommissionierungStoreExtension.mutations.setMenge,
        ({state, params}) => {
          const mengen = [...state.mengen];
          const index = mengen.findIndex(o => o.idkopfkomm === params.idkopfkomm && o.idbewekomm === params.idbewekomm);
          if (index === -1)
            mengen.push(params)
          else
            mengen.splice(index, 1, params)
          return {...state, mengen };
        }),
      mutate(KommissionierungStoreExtension.mutations.removeLagerplatzvorschlag,
        ({state, params}) => {
          const lagerplatzvorschlaege = state.lagerplatzvorschlaege
            .filter(o => o.idkopfkomm !== params.idkopfkomm || o.idbewekomm !== params.idbewekomm);
          return {...state, lagerplatzvorschlaege };
        }),
      mutate(KommissionierungStoreExtension.mutations.removeMenge,
        ({state, params}) => {
          const mengen = state.mengen
            .filter(o => o.idkopfkomm !== params.idkopfkomm || o.idbewekomm !== params.idbewekomm);
          return {...state, mengen };
        }),
    ],
    getters: [
      select(KommissionierungStoreExtension.getters.lagerplatzvorschlag,
        ({state, params}) =>
          state.lagerplatzvorschlaege.find(o => o.idkopfkomm === params.idkopfkomm && o.idbewekomm === params.idbewekomm)?.lagerplatzvorschlag),
      select(KommissionierungStoreExtension.getters.menge,
        ({state, params}) =>
          state.mengen.find(o => o.idkopfkomm === params.idkopfkomm && o.idbewekomm === params.idbewekomm)?.menge),
      select(KommissionierungStoreExtension.getters.filtered,
        ({state, params}) =>
          state.ids
            .map(id => state.entities[id])
            .filter(entity => (state.query?.lieferart === undefined || state.query.lieferart === entity?.lieferart)
                           && (state.query?.lagerplatzAuswahl === undefined || state.query.lagerplatzAuswahl === entity?.lagerplatzAuswahl)
                           && (state.query?.retoure === undefined || state.query.retoure === entity?.retoure)))
    ],
    actions: [
      on(KommissionierungStoreExtension.actions.loadLagerplatzVorschlag,
        async ({state, commit, get, featureName, params, injector}) => {

        const service = injector.get(KommissionierungService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getLagerplatzVorschlag(params.artikelMenge),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            KommissionierungStoreExtension.actions.loadLagerplatzVorschlag.name,
            token
          )
        );

        commit(featureName, KommissionierungStoreExtension.mutations.setLagerplatzvorschlag,
          { idkopfkomm: params.idkopfkomm, idbewekomm: params.idbewekomm, lagerplatzvorschlag: result });
        return result;
      }),
      on(KommissionierungStoreExtension.actions.loadLagerplatz,
        async ({state, commit, featureName, params, injector, dispatch}) => {
          const service = injector.get(KommissionierungService);
          const token = injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
            service.getLagerplatz(params.code),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              KommissionierungStoreExtension.actions.loadLagerplatz.name,
              token
            )
          );
          commit(featureName, KommissionierungStoreExtension.mutations.setLagerplatzvorschlag,
            { idkopfkomm: params.idkopfkomm, idbewekomm: params.idbewekomm, lagerplatzvorschlag: result });
          return result;
        }),
      on(KommissionierungStoreExtension.actions.artikelKommissionierung,
        async ({state, commit, featureName, params, injector, dispatch, get}) => {
          const service = injector.get(KommissionierungService);
          const token = injector.get(SOFTLINE_SERVICE_UUID)();

          const bewe = state.entities[params.idkopfkomm]?.bewegungen?.find(o => o.id === params.idbewekomm);
          if(!bewe)
            throw new Error('[KommissionierungStoreExtension]: No Bewe found')
          const lagerplatz = get(featureName, KommissionierungStoreExtension.getters.lagerplatzvorschlag, params);
          const menge = get(featureName, KommissionierungStoreExtension.getters.menge, params);

          const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
            service.artikelKommissionierung(
              {
                idbewekomm: params.idbewekomm,
                lagerplatz: lagerplatz.lagerplatz,
                artikelMenge: {...bewe.artikelMengeOffen, menge}
              }
            ),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              KommissionierungStoreExtension.actions.loadLagerplatz.name,
              token
            )
          );
          commit(featureName, RemoteCollectionStore.mutations.update, result);
          return result;
        }),
      on(KommissionierungStoreExtension.actions.sperre,
        async ({state, commit, featureName, params, injector}) => {
          const service = injector.get(KommissionierungService);
          const token = injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
            service.sperren(params.idkopfkomm, params.force ?? false),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              KommissionierungStoreExtension.actions.sperre.name,
              token
            )
          );
          commit(featureName, CollectionStore.mutations.addOrUpdate, result);
          return result;
        }),
      on(KommissionierungStoreExtension.actions.abschluss,
        async ({state, commit, featureName, params, injector}) => {
          const service = injector.get(KommissionierungService);
          const token = injector.get(SOFTLINE_SERVICE_UUID)();
          const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
            service.abschluss(params.idkopfkomm),
            featureName,
            commit,
            token
          );
          const result = await lastValueFrom(
            ActionStore.handleObservableActionState(
              subscriptionState$,
              featureName,
              commit,
              KommissionierungStoreExtension.actions.abschluss.name,
              token
            )
          );

          return result;
        }),
      on(KommissionierungStoreExtension.actions.begleitscheindruck,
        async ({state, commit, featureName, params, injector}) => {
          const service = injector.get(KommissionierungService);
          const token = injector.get(SOFTLINE_SERVICE_UUID)();

          const begleitscheinState$ = SubscriptionStore.handleSubscriptionState(
            service.begleitscheinDruck(params.idkopfkomm, params.begleitscheine),
            featureName,
            commit,
            token
          );
          await lastValueFrom(
            ActionStore.handleObservableActionState(
              begleitscheinState$,
              featureName,
              commit,
              KommissionierungStoreExtension.actions.abschluss.name,
              token
            )
          );
        })
    ]
  }
}

export const KommissionierungStore = new StoreBuilder(RemoteCollectionStore.create<KommissionsauftragKopf>())
  .add(QueryStore.create<KommissionsauftragKopfFilter>())
  .add(KommissionierungStoreExtension)
  .value
