import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {DeveloperStore2} from '../developer/developer.store2';
import {RequestLogStore} from './request-log.store';
import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {DateService, Dictionary} from '@softline/core';
import moment from 'moment';
import {Router} from '@angular/router';

@Injectable()
export class RequestLogInterceptor implements HttpInterceptor {
  store = inject(DeveloperStore2, { optional: true });
  requestLog = inject(RequestLogStore)
  dateService = inject(DateService);
  router = inject(Router);

  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const requestTimestamp = this.dateService.now();
    const requestHeaders: Dictionary<string> = {};
    for(const key of request.headers.keys())
      requestHeaders[key] = request.headers.get(key) ?? '';

    return next.handle(request)
      .pipe(
        tap(response => {
          if(!this.store || !this.store.isDeveloper())
            return;

          const responseTimestamp = this.dateService.now();
          const time = moment.duration(moment(responseTimestamp)
            .diff(requestTimestamp, 'milliseconds', true))
            .toISOString();

          if(response.type === HttpEventType.Response) {
            const responseHeaders: Dictionary<string> = {};
            for(const key of response.headers.keys())
              responseHeaders[key] = response.headers.get(key) ?? '';
            this.requestLog.add({
              request: {
                method: request.method,
                url: request.urlWithParams,
                headers: requestHeaders,
                timestamp: requestTimestamp
              },
              response: {
                status: response.status,
                statusText: this.toStatusText(response.status),
                headers: responseHeaders,
                timestamp: responseTimestamp,
                ok: response.ok,
                time: time
              }
            })
          }
        }),
        catchError((e: HttpErrorResponse) => {
          if(!this.store || !this.store.isDeveloper())
            return throwError(() => e);

          const responseHeaders: Dictionary<string> = {};
          const responseTimestamp = this.dateService.now();
          const time = moment.duration(moment(responseTimestamp)
            .diff(requestTimestamp, 'milliseconds', true))
            .toISOString();
          let message = e.message;
          let code: string | undefined = undefined;
          if(e.error?.errors && e.error?.errors[0]?.message) {
            message = e.error?.errors[0]?.message;
            code = e.error?.errors[0]?.code;
          }

          for(const key of e.headers.keys())
            responseHeaders[key] = e.headers.get(key) ?? '';
          this.requestLog.add({
            request: {
              method: request.method,
              url: request.urlWithParams,
              headers: requestHeaders,
              timestamp: requestTimestamp
            },
            response: {
              status: e.status,
              statusText: this.toStatusText(e.status),
              message: message,
              code: code,
              headers: responseHeaders,
              timestamp: responseTimestamp,
              time: time,
              ok: false,
            }
          })
          return throwError(() => e);
        })
      )
  }

  toStatusText(code: number) {
    switch (code) {
      case 200: return 'OK';
      case 201: return 'Created';
      case 202: return 'Accepted';
      case 203: return 'Non-Authoritative Information';
      case 204: return 'No Content';
      case 304: return 'Not Modified';
      case 400: return 'Bad Request';
      case 401: return 'Unauthorized';
      case 403: return 'Forbidden';
      case 404: return 'Not Found';
      case 405: return 'Method Not Allowed';
      case 406: return 'Not Acceptable';
      case 408: return 'Request Timeout';
      case 409: return 'Conflict';
      case 410: return 'Gone';
      case 500: return 'Internal Server Error';
      case 501: return 'Not Implemented';
      case 502: return 'Bad Gateway';
      case 503: return 'Service Unavailable';
      case 504: return 'Gateway Timeout';
      default: return 'Unknown Error';
    }
  }
}
