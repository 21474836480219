import { Store2Feature } from '@softline/core';
import { RequestLog } from './request-log';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class RequestLogStore extends Store2Feature<RequestLog[]>{
  override name = 'requestLog';

  add(item: RequestLog): void {
    this.commit([...this.state(), item]);
  }

  override getDefaultState(): RequestLog[] {
    return [];
  }
}
