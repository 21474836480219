import { Component, inject, OnInit } from '@angular/core';
import { DateService, Store } from '@softline/core';
import {
  MessageBarService,
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule
} from '@softline/ui-core';
import moment from 'moment';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppInfoStore, SOFTLINE_FEATURE_APP_INFO } from '@softline/application';

@Component({
  standalone: true,
  selector: 'soft-public-shell',
  templateUrl: './public-shell.component.html',
  styleUrls: ['./public-shell.component.scss'],
  imports: [CommonModule, UiCoreModule, RouterModule],
})
export class PublicShellComponent implements OnInit {
  year?: string;
  messageBarService = inject(MessageBarService);

  version = this.store.signal(
    SOFTLINE_FEATURE_APP_INFO,
    AppInfoStore.getters.version,
    {initialValue: '0'}
  );

  constructor(private store: Store, private dateService: DateService) {}

  ngOnInit(): void {
    this.year = moment(this.dateService.today()).format('YYYY');
  }
}
