import {EnvironmentProviders, importProvidersFrom, Provider} from "@angular/core";

import {
  RemoteStoreObjectService,
  RemoteObjectStore,
  ResourceService,
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
  SoftlineObjectService,
  Store,
  StoreModule
} from "@softline/core";
import {SOFTLINE_CONFIG_SETTINGS} from "../application.shared";
import {SOFTLINE_FEATURE_DEVELOPER} from "../developer/developer.shared";
import {DeveloperStore} from "../developer/developer.store";
import {SOFTLINE_CONFIG_OFFLINE_FEATURE} from "../offline/offline.shared";
import {SOFTLINE_API_REMOTE_CONFIG} from "./remote-config.api";
import {SOFTLINE_FEATURE_REMOTE_CONFIG, SOFTLINE_SETTINGS_REMOTE_CONFIG} from "./remote-config.shared";
import {RemoteConfigSettingsComponent} from "./settings/remote-config-settings.component";
import {of} from 'rxjs';

function serviceFactory(
  store: Store,
  service: ResourceService<any>
): RemoteStoreObjectService<any> {
  return new SoftlineObjectService(service, SOFTLINE_API_REMOTE_CONFIG);
}

export const remoteConfigProviders: (Provider | EnvironmentProviders)[] = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_REMOTE_CONFIG,
      feature: RemoteObjectStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
          useFactory: serviceFactory,
          deps: [Store, SOFTLINE_SERVICE_HTTP],
        },
      ],
    }),
  ),
  {
    provide: SOFTLINE_CONFIG_SETTINGS,
    multi: true,
    useFactory: (store: Store) => ({
      key: SOFTLINE_SETTINGS_REMOTE_CONFIG,
      component: RemoteConfigSettingsComponent,
      title: '#APPLICATION.CONFIG.TITLE',
      default: undefined,
      icon: 'fa-regular fa-diagram-project',
      visible: store.isRegistered(SOFTLINE_FEATURE_DEVELOPER)
        ? store.observe(SOFTLINE_FEATURE_DEVELOPER, DeveloperStore.getters.isDeveloper)
        : of(false)
    }),
    deps: [Store]
  },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    multi: true,
    useValue: {
      featureName: SOFTLINE_FEATURE_REMOTE_CONFIG,
      fields: ['data'],
    },
  }];
