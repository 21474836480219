import { Component, Inject, Input, OnInit, signal, Type } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FieldOkItemComponent, FieldOkViewComponent } from "../../../../data/field-ok";
import { UiCoreModule } from "@softline/ui-core";
import { FieldOkItemOutletDirective } from "../../../../directives/field-ok-item-outlet.directive";
import { FilterAndSortComponent, FilterConfig, showRequestErrors, SortConfig } from "@softline/application";
import { Entity, SOFTLINE_DATA_VALUE, Store } from "@softline/core";
import { FieldOkInputParams, FieldOkStrategy } from "../../../field-ok/strategies/field-ok.strategy";
import { WithFilterAndSort } from "../../../field-ok/views/field-ok-view-filter-and-sort.mixin";

export interface FieldOkMultiSelectListViewConfig {
  component: Type<FieldOkItemComponent<any>>;
  filter?: FilterConfig;
  sort?: SortConfig;
}
@Component({
  selector: 'soft-field-ok-list-view',
  standalone: true,
  imports: [CommonModule, UiCoreModule, FieldOkItemOutletDirective, FilterAndSortComponent],
  templateUrl: './field-ok-multiselect-list-view.component.html',
  styleUrls: ['./field-ok-multiselect-list-view.component.scss'],
})
export class FieldOkMultiselectListViewComponent extends WithFilterAndSort() implements OnInit, FieldOkViewComponent<FieldOkMultiSelectListViewConfig>{

  selected = signal<Entity[]>([]);

  @Input() config!: FieldOkMultiSelectListViewConfig;
  constructor(@Inject(SOFTLINE_DATA_VALUE)private options: FieldOkInputParams<Entity, object>) {
    super();
    this.selected.set(Array.isArray(options.current) ? options.current : []);
  }

  async ngOnInit(): Promise<void> { }

  async resolve(selected: Entity[]): Promise<void> {
    try {
      await this.strategy.resolve(selected);
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
  }
  select(item: Entity) {
    const selected = [...this.selected()];
    const index = selected.findIndex(o => o.id === item.id);
    if(index === -1)
      this.selected.set([...selected, item])
    else {
      selected.splice(index, 1);
      this.selected.set(selected);
    }
  }
}
