<div class="login-app">
  <div class="login-container">
    <div class="login-company-branding mb-4">
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
<div class="c row center soft-app-info">
  <img
    [attr.src]="'./assets/images/softapps-logo.svg'"
    class="login-softapps-logo"
  />
  <div>
    <h6 class="font-semibold">SoftApps</h6>
    &copy; Softline IT GmbH 1980-{{ year }} <br />
    {{version()}}
  </div>
</div>
<soft-modal-container></soft-modal-container>
<div class="shell-message-bar-wrapper">
  <soft-message-bar-container class="shell-message-bar-container"
  ></soft-message-bar-container>
</div>
