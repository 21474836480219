import {Injectable} from '@angular/core';
import {ConnectionHttpService, ConnectionResourceLocation, Dictionary, ResponseResult} from '@softline/core';
import {Scan} from '@softline/application';
import {Artikel} from '../data/artikel';
import { map, Observable, switchMap, tap } from "rxjs";
import {
  SOFTLINE_API_WERKSTAETTENAUFTRAG_SCAN_QUERY_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_SCAN_RESULT_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_VALIDATE_SCAN_RESOURCE_PATH
} from '../auftrag.api';

@Injectable()
export class ArtikelScanService {

  constructor(private connectionHttpService: ConnectionHttpService) {}

  validateScan(scanResult: Scan): Observable<Artikel> {
    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_WERKSTAETTENAUFTRAG_VALIDATE_SCAN_RESOURCE_PATH,
      pathParams: scanResult as unknown as Dictionary<unknown>
    };

    return this.connectionHttpService.get<ResponseResult<{ artikel: { id: number} }>>(location)
      .pipe(
        map(o => o?.result?.artikel?.id),
        switchMap(o => this.connectionHttpService.create<any, any>(
          { path: SOFTLINE_API_WERKSTAETTENAUFTRAG_SCAN_QUERY_RESOURCE_PATH },
          { filter: '' + o, multiValued: false, parameters: { kzgelistetall: false, sortierung: 2 } }
        ).pipe(map(p => o))),
        switchMap(o => this.connectionHttpService.create<any, any>(
          { path: SOFTLINE_API_WERKSTAETTENAUFTRAG_SCAN_RESULT_RESOURCE_PATH, pathParams: { id: o } },
          { filter: '' + o, multiValued: false, parameters: { kzgelistetall: false, sortierung: 2 } }
        )),
        map(o => this.mapResult(o.data, { id: 'id', artbez1: 'name', cartikelnummer: 'number' }))
      );
  }

  private mapResult(result: any, resultMap: Dictionary<string>): any {
    if (!result)
      return result;

    const mappedResult: Dictionary<unknown> = {};

    for (const [key, value] of Object.entries(result))
      mappedResult[resultMap[key] ?? key] = value;

    return mappedResult;
  }
}
