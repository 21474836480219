<div [ngClass]="borderBottomMobileVisible() ? 'border-b ' : 'md:border-b'"
  class="flex flex-row flex-wrap justify-between items-center md:items-start h-full md:flex-col md:gap-1 gap-4 md:border-l pb-1 pl-1 min-h-10">
  @if (label(); as label) {
    <span class="text-sm font-semibold">{{label | translate}}</span>
  }
  <div class="flex flex-row">
    @if (overline(); as overline) {
      <span class="text-primary font-bold text-end md:text-start mr-2">{{overline | translate}}</span>
    }
    <div class="text-end md:text-start" #ref>
      <ng-content></ng-content>
    </div>
    @if(!ref.children.length && !(ref.innerText | isDefinedNotEmpty)) {
      <soft-icon class="text-lighter" [name]="'fa-regular fa-empty-set'"></soft-icon>
    }
  </div>
</div>
