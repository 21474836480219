import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { LagerLagerplatzListView } from "./list-view/lager-lagerplatz.list-view";

export const lagerLagerplatzFieldOk: FieldOkConfig = {
  name: 'lagerplatz',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{lagerplatz}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: LagerLagerplatzListView
    }
  }
  ]
};
export const lagerLagerplatzMultiselectFieldOk: FieldOkConfig = {
  name: 'lagerplatz',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{lagerplatz}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: LagerLagerplatzListView
    }
  }
  ]
};

export const lagerLagerplatzQueryFieldOk: FieldOkConfig = {
  name: 'lagerplatz',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{lagerplatz}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: LagerLagerplatzListView
    }
  }
  ]
};
