import {
  Dictionary,
  IndexedDbResourceLocation,
  isDefined,
  Logger,
  Mutation,
  ResourceService,
  Store,
} from '@softline/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { OfflineFeatureConfig } from '../offline.shared';

export class IndexedDbStoreAdapter {
  loaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected store: Store,
    protected indexedDbService: ResourceService<IndexedDbResourceLocation>,
    protected configs: (string | OfflineFeatureConfig)[],
    protected logger: Logger
  ) {
    (this.store as any).commits$.subscribe(
      async (commit: {
        feature: string;
        mutation: Mutation<any, any>;
        params: unknown;
        stateBefore: Dictionary<object>;
        stateAfter: Dictionary<object>;
      }) => {
        const config = this.configs.find(
          (o) =>
            (typeof o === 'string' && o === commit.feature) ||
            (typeof o === 'object' && o.featureName === commit.feature)
        );
        if (!isDefined(config)) return;

        const result = await this.saveFeatureState(commit.stateAfter, config);
        if (result)
          console.debug(
            `[IndexedDbAdapter] Saved feature state ${commit.feature} to IndexedDB`,
            result
          );
      }
    );

    this.loadState().then((_) => this.loaded$.next(true));
  }

  async loadState(): Promise<void> {
    const indexedDbState: Dictionary<any> = {};
    for (const config of this.configs) {
      const featureName =
        typeof config === 'string' ? config : config.featureName;
      try {
        const featureState = await lastValueFrom(
          this.indexedDbService.get<object>({
            databaseName: 'store',
            objectStoreName: 'features',
            key: featureName,
          })
        );
        if (!isDefined(featureState)) continue;

        console.debug(
          '[IndexedDbAdapter] Found feature state for ' + featureName,
          featureState
        );
        indexedDbState[featureName] = featureState;
      } catch (e) {
        console.debug(
          '[IndexedDbAdapter] Cannot get indexed db state for feature' +
            featureName,
          e
        );
      }
    }
    const state = { ...(this.store as any).state.value };
    console.debug(
      '[IndexedDbAdapter] Actual state',
      (this.store as any).state.value
    );
    console.debug('[IndexedDbAdapter] IndexedDb state', indexedDbState);
    for (const [key, value] of Object.entries(indexedDbState))
      state[key] = { ...state[key], ...value };
    (this.store as any).state.next(state);

    for (const config of this.configs) {
      const featureName =
        typeof config === 'string' ? config : config.featureName;
      if (isDefined(state[featureName]))
        await this.saveFeatureState(state[featureName], config);
    }

    console.debug('[IndexedDbAdapter] Loaded state from IndexedDB', state);
  }

  private async saveFeatureState(
    featureState: Dictionary<object>,
    config: string | OfflineFeatureConfig
  ): Promise<object | null> {
    const featureName =
      typeof config === 'string' ? config : config.featureName;
    if (!isDefined(featureState)) return null;
    const savableState = this.pruneFeatureState(featureState, config);
    if (Object.keys(savableState).length === 0) return null;
    await lastValueFrom(
      this.indexedDbService.update(
        {
          databaseName: 'store',
          objectStoreName: 'features',
          key: featureName,
        },
        { ...savableState, _featureName: featureName }
      )
    );
    return savableState;
  }

  private pruneFeatureState(
    featureState: Dictionary<object>,
    config: string | OfflineFeatureConfig
  ): Dictionary<object> {
    if (typeof config === 'string') return featureState as Dictionary<object>;

    const savableState: Dictionary<object> = {};
    for (const field of config.fields)
      savableState[field] = featureState[field];
    return savableState;
  }
}
