import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ArtuntergrpListView } from "./list-view/artuntergrp.list-view";

export const artuntergrpFieldOk: FieldOkConfig = {
  name: 'artuntergrp',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{artuntergrp}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtuntergrpListView,
      sort: {
        properties: [
          {property: 'id', title: 'Erfassungszeitpunkt'},
          {property: 'arthauptgrpsprbezeichnung', title: 'Bezeichnung'},
        ]
      }
    }
  }
  ]
};

export const artuntergrpMultiselectFieldOk: FieldOkConfig = {
  name: 'artuntergrp',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{artuntergrp}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtuntergrpListView,
      sort: {
        properties: [
          {property: 'id', title: 'Erfassungszeitpunkt'},
          {property: 'arthauptgrpsprbezeichnung', title: 'Bezeichnung'},
        ]
      }
    }
  }
  ]
};

export const artuntergrpQueryFieldOk: FieldOkConfig = {
  name: 'artuntergrp',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{artuntergrp}} - {{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtuntergrpListView,
      sort: {
        properties: [
          {property: 'id', title: 'Erfassungszeitpunkt'},
          {property: 'arthauptgrpsprbezeichnung', title: 'Bezeichnung'},
        ]
      }
    }
  }
  ]
};
