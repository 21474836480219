import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@softline/ui-core";
import {
    BackNavigable,
    BackNavigationService,
    BlockingLoadingSpinnerComponent,
    showRequestErrors, HeaderComponent,
    ScannerModule,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import { LagerkorrekturStore } from "../../store";
import { SOFTLINE_FEATURE_LAGERKORREKTUR } from "../../lagerkorrektur.shared";
import { Lagerplatz, LagerplatzSucheComponent } from "@softapps/lager/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'soft-quelllagerplatz-scannen',
  standalone: true,
    imports: [CommonModule, UiCoreModule, ScannerModule, StepHeaderComponent, BlockingLoadingSpinnerComponent, LagerplatzSucheComponent, HeaderComponent],
  templateUrl: './lagerplatz-scannen.page.html',
  styleUrls: ['./lagerplatz-scannen.page.scss']
})
export class LagerplatzScannenPage implements OnInit, OnDestroy, BackNavigable {

  readonly steps: Step[] = [
    {
      header: '#LAGERKORREKTUR.PAGES.LAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.LAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR.PAGES.LAGERSYMBOL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR.PAGES.LAGERSYMBOL_AUSWAHL.DESCRIPTION'
    },
  ];

  loading$ = new BehaviorSubject<boolean>(false)

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService,
              private zone: NgZone) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERKORREKTUR.TITLE')
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/'])
  }

  async onLagerplatzSelected(lagerplatz: Lagerplatz): Promise<void> {
    if(!lagerplatz)
      return;

    this.loading$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERKORREKTUR, LagerkorrekturStore.actions.loadLagerplatz, lagerplatz.barcode);
      this.loading$.next(false);
      await this.router.navigate(['/lagerkorrektur/artikel-auswahl'])
    }
    catch (e) {
      this.loading$.next(false);
      showRequestErrors(this.store, e);
    }
  }
}
