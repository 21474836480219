import { SupplierItem } from './supplier-item';
import { Unit } from './unit';

export interface ProductSupplier {
  purchasingOrganistion: {
    key: string,
    name: string
  };
  supplier: SupplierItem
  unit: Unit;
  priceUnit: Unit;
  status: {
    value: string;
    description: string;
  }
  supplierProductNumber: string;
  ean: string;
  minQuantity: number;
  deliveryDays: number;
  info1: string;
  info2: string;
}
