import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Promotion } from '../../types/promotion';
import { UiCoreModule } from '@softline/ui-core';
import { PriceCardComponent } from '../price-card/price-card.component';
import { Price } from '../../types/price';

@Component({
  selector: 'soft-promotion-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule, PriceCardComponent],
  templateUrl: './promotion-card.component.html',
  styleUrl: './promotion-card.component.scss',
})
export class PromotionCardComponent {
  promotion = input.required<Promotion>();
}
