import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { RemoteObjectStore, Store } from "@softline/core";
import { SOFTLINE_FEATURE_REMOTE_CONFIG } from "./remote-config.shared";
import {showRequestErrors} from '../errors/show-request-errors.function';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigResolver  {
  loaded = false;
  constructor(private store: Store) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<object> {
    if(this.loaded)
      return this.store.get(
      SOFTLINE_FEATURE_REMOTE_CONFIG,
      RemoteObjectStore.getters.data
    );

    try {
      const value = await this.store.dispatch(
        SOFTLINE_FEATURE_REMOTE_CONFIG,
        RemoteObjectStore.actions.load
      );
      this.loaded = true;
      return value;
    }
    catch (e) {
      showRequestErrors(this.store, e);
    }
    return {}
  }
}
