<div class="flex flex-col row fill gap-2 full-width bg-surface border-b border-b-solid px-4 pb-2 pt-4 z-10 -mt-2">
  <div class="search-wrapper">
    <soft-input #input (valueChange)="searchText$.next(input?.value ?? '')" [placeholder]="'#WORKSHOP.TASK_OUTLINE.SEARCH.PLACEHOLDER' | translate"></soft-input>
    <i class="fa-regular fa-magnifying-glass color-secondary"></i>
  </div>
  <div class="flex justify-between items-center text-base font-semibold">
    Nur eigene Anzeigen
    <soft-toggle-switch [value]="vertreterFilterActive$ | async" (valueChange)="vertreterFilterService.vertreterFilterActive$.next($event)"></soft-toggle-switch>
  </div>
</div>

<section class="mt-4 pb-3 mb-3" *ngIf="filteredAuftraege$ | async as auftraege">
  <div class="card-stack mt-4 mb-3" *ngIf="(auftraege?.length || 0 > 0) || (loading$ | async) === false">
    @for (auftrag of auftraege; track auftrag.id) {
      <soft-auftrag-card [auftrag]="auftrag" (click)="navigateTo(auftrag)"></soft-auftrag-card>
    }
  </div>
  <div class="loading h-screen flex items-center justify-center text-right" *ngIf="loading$ | async">
    <soft-loading-spinner class="spin"></soft-loading-spinner>
  </div>
</section>

<div *ngIf="(loaded$ | async) && (filteredAuftraege$ | async)?.length === 0"
     class="c row center center-v center-h height-100 color-light">
  {{ '#WORKSHOP.COMPONENTS.OVERVIEW.NO_DATA' | translate }}
</div>
