import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import { StoreModule } from "@softline/core";
import {
  SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG,
  SOFTLINE_PERMISSION_LAGERPLATZ_UMLAGERUNG
} from "./lagerplatz-umlagerung.shared";
import { LagerplatzUmlagerungStore } from "./store";
import { LagerplatzUmlagerungService } from "./services/lagerplatz-umlagerung.service";

export const lagerplatzUmlagerungRoutes: Routes = [
  {
    path: 'lagerplatz-umlagerung',
    children: [
      { path: 'artikel-auswahl', loadComponent: () => import('./pages/artikel-auswahl/artikel-auswahl.page').then(o => o.ArtikelAuswahlPage)},
      { path: 'mengen-auswahl', loadComponent: () => import('./pages/mengen-auswahl/mengen-auswahl.page').then(o => o.MengenAuswahlPage)},
      { path: 'ziellagerplatz', loadComponent: () => import('./pages/ziellagerplatz-scannen/ziellagerplatz-scannen.page').then(o => o.ZiellagerplatzScannenPage)},
      { path: '', loadComponent: () => import('./pages/quelllagerplatz-scannen/quelllagerplatz-scannen.page').then(o => o.QuelllagerplatzScannenPage) },
      { path: '**', redirectTo: '/lagerplatz-umlagerung' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_LAGERPLATZ_UMLAGERUNG},
    providers: [
      LagerplatzUmlagerungService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG,
          feature: LagerplatzUmlagerungStore.feature,
        }),
      ),
    ]
  },
];
