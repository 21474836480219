import {
  computed,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  OnInit,
  runInInjectionContext,
  signal,
  Signal
} from '@angular/core';
import { showRequestErrors } from '@softline/application';
import {
  CancelledError,
  CollectionStore2,
  Dictionary,
  LoadCollectionParameters,
  NestedStore2Feature,
  ReadonlyRepositoryCollectionStore2,
  SOFTLINE_SERVICE_UUID,
  Store
} from '@softline/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';
import { WithCollection } from './collection.mixin';

type Constructor<T extends {}> = new(...args: any[]) => T;

type ReadonlyRepositoryCollectionMixinParams<T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>>
  = {store: InjectionToken<TStore>, collectionFeature: (o: TStore) => CollectionStore2<T>, repositoryFeature: (o: TStore) => ReadonlyRepositoryCollectionStore2<T>};

export const WithLoadRepositoryCollection = <T extends object, TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>, TBase extends Constructor<{}> = Constructor<{}>>(params: ReadonlyRepositoryCollectionMixinParams<T, TStore>, Base: TBase = (class {} as any)) => {

  @Injectable()
  abstract class LoadRepositoryCollectionMixin
    extends WithCollection<T, TStore, TBase>({store: params.store, feature: params.collectionFeature}, Base)
    implements OnInit, OnDestroy {

    #token: string | null = null;
    #destroying = false;

    #injector = inject(Injector);
    #uuid = inject(SOFTLINE_SERVICE_UUID);
    #store = inject(Store);

    #repositoryStore: ReadonlyRepositoryCollectionStore2<T>;

    loadingState = computed(() => this.#repositoryStore.loadingState());

    pathParams = signal<Dictionary<unknown> | undefined>(undefined);
    abstract loadParams: Signal<LoadCollectionParameters | undefined>;

    constructor(...args: any[]) {
      super(...args);

      const injectedStore = inject(params.store);
      this.#repositoryStore = params.repositoryFeature(injectedStore);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      runInInjectionContext(this.#injector, () => {
        toObservable(this.loadParams)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {this.load();})
      });
    }

    override ngOnDestroy(): void {
      this.#destroying = true;
      this.cancelLoad();
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async load(): Promise<void> {
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.loadParams() ?? {};
        await this.#repositoryStore.loadMany({...params, token: this.#token, clear: true });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          showRequestErrors(this.#store, e);
      }
      finally {
        this.#token = null;
      }
    }

    cancelLoad(): void {
      if(!this.#token)
        return;
      this.#repositoryStore.cancel(this.#token);
    }
  }
  return LoadRepositoryCollectionMixin;
}
