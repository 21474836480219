import {
  CollectionStore2,
  DefaultCollectionResponseCommit,
  IndexedDbCollectionRepository,
  IndexedDbService,
  RepositoryCollectionStore2,
  SOFTLINE_COLLECTION_REPOSITORY,
  SOFTLINE_FACTORY_COLLECTION_RESPONSE_COMMIT,
  SOFTLINE_FEATURE_ID_FUNC,
  Store2Builder,
  SubscriptionStore2
} from '@softline/core';
import { inject, InjectionToken } from '@angular/core';
import {
  SOFTLINE_NAME_PAGE_SETTINGS_OBJECT_STORE
} from './page-settings.shared';
import { PageSettings } from './page-settings';
import { UserIndexedDbCollectionRepository } from '../repositories/user-indexed-db-collection.repository';

const pageSettingsStore = new Store2Builder('softline/page-settings')
  .withProvider({provide: SOFTLINE_FEATURE_ID_FUNC, useValue: (item: PageSettings) => item.name})
  .withSubFeature('settings', CollectionStore2<PageSettings>)
  .withSubFeature('subscription', SubscriptionStore2)
  .withSubFeature('repository', RepositoryCollectionStore2<PageSettings>, [
    {
      provide: SOFTLINE_FACTORY_COLLECTION_RESPONSE_COMMIT,
      useFactory: () => () => new DefaultCollectionResponseCommit()
    },
    {
      provide: SOFTLINE_COLLECTION_REPOSITORY,
      useFactory: () => new UserIndexedDbCollectionRepository(
        inject(IndexedDbService),
        SOFTLINE_NAME_PAGE_SETTINGS_OBJECT_STORE
      )
    }
  ]);

export const PageSettingsStore = new InjectionToken('PageSettingsStore', {
  providedIn: 'root',
  factory: () => pageSettingsStore.build()
});

