import { KommissionsauftragKopf } from "../types/kommissionsauftrag";
import { KommmissionierungStrategy } from "./kommmissionierung.strategy";
import { KommscheinStrategy } from "./kommschein.strategy";
import { RetourscheinStrategy } from "./retourschein.strategy";

export function kommissionierungStrategyFactory(auftrag: KommissionsauftragKopf): KommmissionierungStrategy {
  if(auftrag?.retoure)
    return new RetourscheinStrategy(auftrag.lagerplatzAuswahl);
  return new KommscheinStrategy(auftrag.lagerplatzAuswahl);
}
