import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Arbeitsbericht} from '../../data/stempel';
import {UiCoreModule} from '@softline/ui-core';
import {Store} from '@softline/core';
import {AuthorizationStore, SOFTLINE_FEATURE_AUTHORIZATION} from '@softline/auth';
import {SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_LOESCHEN} from '../../auftrag.permissions';

@Component({
  selector: 'soft-arbeitsbericht-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './arbeitsbericht-card.component.html',
  styleUrls: ['./arbeitsbericht-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArbeitsberichtCardComponent {
  @Input() item?: Arbeitsbericht;
  @Input() index?: number;
  @Output() delete = new EventEmitter<Arbeitsbericht>();

  readonly hasWorkReportDeletePermission$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHORIZATION,
    AuthorizationStore.getters.authorized,
    SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_LOESCHEN
  );

  constructor(private store: Store) {}

}
