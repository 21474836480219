<div class="flex flex-row mt-2 gap-2 flex-wrap">
  <div class="flex flex-col border-b border-l p-1 pr-8">
    <div class="soft-label top">Error</div>
    <soft-toggle-switch [(value)]="error"></soft-toggle-switch>
  </div>
  <div class="flex flex-col border-b border-l p-1 pr-8">
    <div class="soft-label top">Warning</div>
    <soft-toggle-switch [(value)]="warning"></soft-toggle-switch>
  </div>
  <div class="flex flex-col border-b border-l p-1 pr-8">
    <div class="soft-label top">Info</div>
    <soft-toggle-switch [(value)]="info"></soft-toggle-switch>
  </div>
  <div class="flex flex-col border-b border-l p-1 pr-8">
    <div class="soft-label top">Log</div>
    <soft-toggle-switch [(value)]="log"></soft-toggle-switch>
  </div>
  <div class="flex flex-col border-b border-l p-1 pr-8">
    <div class="soft-label top">Debug</div>
    <soft-toggle-switch [(value)]="debug"></soft-toggle-switch>
  </div>
</div>

<!--<soft-paginator  #paginator></soft-paginator>-->
<div class="flex flex-col overflow-auto max-h-[75vh]">
  @for(entry of logs(); track entry; let i = $index) {
    <div
      class="border border-slate-200 mt-2 p-4"
      [ngClass]="{
    consoleError: entry.type === 'error',
    consoleWarn: entry.type === 'warn',
    consoleInfo: entry.type === 'info',
    consoleDebug: entry.type === 'debug'
  }"
    >
      <div class="flex justify-between text-[0.5rem] font-semibold mb-2">
        <span>#{{ i + 1 }} - {{ entry.type | uppercase }}</span>
        <span>{{ entry.timestamp | format: 'DATE_TIME.SECONDS' }}</span>
      </div>
      <ng-container
        *ngTemplateOutlet="contentTemplate; context: { data: entry.data }"
      ></ng-container>
    </div>
  } @empty {
    <div class="text-center text-xs text-slate-400 mt-4">No logs available</div>
  }
</div>



<ng-template #contentTemplate let-data="data">
  <ng-container
    *ngTemplateOutlet="
      (data | isArray) ? arrayTemplate : typeTemplate;
      context: { data: data }
    "
  ></ng-container>
</ng-template>

<ng-template #arrayTemplate let-data="data">
  <div *ngFor="let o of data">
    <ng-container
      *ngTemplateOutlet="contentTemplate; context: { data: o }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #typeTemplate let-data="data">
  <div *ngIf="isError(data)">
    <p class="text-xs font-semibold">{{ data.message }}</p>
    <div>
      <p class="text-xs mt-2">{{ data.stack }}</p>
    </div>
  </div>
  <soft-json-object-tree class="text-xs mt-2" [value]="data"></soft-json-object-tree>
</ng-template>
