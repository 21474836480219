<div class="c row space-between center-v no-wrap px-4 sm:px-0">
  <h4 class="font-semibold text-xl mt-2 sm:mt-0">{{ '#WORKSHOP.DIALOGS.EDIT_HEADER.TITLE' | translate }}</h4>
  <div class="c row end center-v auto-width fill-height mt-3" *ngIf="(loading$ | async); else spacer">
    <soft-loading-spinner class="spin small mr-2"></soft-loading-spinner>
    <span class="soft-text color-light small-font ml-1">{{ '#WORKSHOP.SHARED.LOADING_INFO' | translate }}</span>
  </div>
  <ng-template #spacer><div class="spacer"></div></ng-template>
</div>

<form [formGroup]="form" class="mt-3" (ngSubmit)="onSubmit()">
  <div class="flex flex-col px-4 sm:px-0">
    <soft-multiline-input formControlName="headerText" [lines]="4"></soft-multiline-input>

    <soft-message-bar-container [name]="'EDIT_HEADER_MSG_BAR'">
    </soft-message-bar-container>
  </div>

  <div class="c row space-between actions sm:gap-2 w-full no-wrap mt-3">
    <button class="soft-button h-14 w-full text-center primary fill-force" (click)="close(null)">{{ '#WORKSHOP.DIALOGS.ABORT' | translate }}</button>
    <button class="soft-button h-14 w-full text-center accent fill-force">{{ '#WORKSHOP.DIALOGS.SUBMIT_SAVE' | translate }}</button>
  </div>
</form>

