import { BehaviorSubject, Observable } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

export abstract class Store2Feature<T> {
  protected commit!: (state: T) => void;

  readonly abstract name: string;
  readonly initialState: T = this.getDefaultState();

  private _state$ = new BehaviorSubject<T>(this.initialState);
  get state$(): Observable<T> {
    return this._state$.asObservable();
  }
  state = toSignal(this.state$, {initialValue: this.initialState});

  constructor() { }

  onRegister(): void { }

  protected commitPatch(patch: Partial<T>): void {
    this.commit({ ...this.state(), ...patch });
  }

  registerCommit(commit: (state: T) => void): void {
    this.commit = commit;
  }

  registerState(state$: Observable<T>) {
    state$.subscribe((value) => this._state$.next(value));
  }

  resetState(): void {
    this.commit(this.initialState);
  }

  abstract getDefaultState(): T;
}
