import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import { StoreModule } from "@softline/core";
import {
  SOFTLINE_FEATURE_PALETTEN_UMLAGERUNG,
  SOFTLINE_PERMISSION_PALETTEN_UMLAGERUNG
} from "./paletten-umlagerung.shared";
import { PalettenUmlagerungStore } from "./store";
import { PalettenUmlagerungService } from "./services/paletten-umlagerung.service";

export const palettenUmlagerungRoutes: Routes = [
  {
    path: 'paletten-umlagerung',
    children: [
      { path: 'ziellagerplatz', loadComponent: () => import('./pages/lagerplatz-scannen/lagerplatz-scannen.page').then(o => o.LagerplatzScannenPage)},
      { path: '', loadComponent: () => import('./pages/palette-scannen/palette-scannen.page').then(o => o.PaletteScannenPage) },
      { path: '**', redirectTo: '/paletten-umlagerung' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_PALETTEN_UMLAGERUNG},
    providers: [
      PalettenUmlagerungService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_PALETTEN_UMLAGERUNG,
          feature: PalettenUmlagerungStore.feature,
        }),
      ),
    ]
  },
];
