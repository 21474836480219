
import { Observable } from 'rxjs';
import {
  CollectionRepository, CRUDCollectionParameters, Id,
  IndexedDbResourceLocation,
  LoadCollectionItemParameters, LoadCollectionParameters,
  ResourceService, toIdString
} from '@softline/core';
import { AuthenticationContextStore2 } from '@softline/auth';
import { computed, inject } from '@angular/core';
import { SOFTLINE_NAME_USER_INDEXED_DB } from '../../softapps-core.shared';

export class UserIndexedDbCollectionRepository<TItem, TResult, TManyResult> implements CollectionRepository<TItem, TResult, TManyResult> {

  authStore = inject(AuthenticationContextStore2);

  constructor(protected service: ResourceService<IndexedDbResourceLocation>,
              protected objectStoreName: string) { }

  loadItem(id: Id, params: LoadCollectionItemParameters): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
      params: {id: this.authStore.userId()}
    };
    return this.service.get(location);
  }

  loadMany(params?: LoadCollectionParameters): Observable<TManyResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      params: {id: this.authStore.userId()}
    };
    return this.service.get(location);
  }

  create(id: Id | null | undefined, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
      params: {id: this.authStore.userId()}
    };
    return this.service.create(location, value);
  }
  update(id: Id, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
      params: {id: this.authStore.userId()}
    };
    return this.service.update(location, value);
  }
  patch(id: Id, changes: Partial<TItem>, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
      params: {id: this.authStore.userId()}
    };
    return this.service.patch(location, changes);
  }
  delete(id: Id, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
      params: {id: this.authStore.userId()}
    };
    return this.service.delete(location);
  }
}
