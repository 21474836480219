<ng-container *ngIf="lagerplatzInhalt">
  <div *ngFor="let artikelMenge of lagerplatzInhalt.inhalt; let i = index"
       class="soft-card border mt-1 p-6 rounded"
       (click)="onSelect(artikelMenge)">
    <div class="flex flex-row justify-between flex-nowrap">
      <div class="flex flex-col">
        <span class="text-sm color-light">{{artikelMenge.artikel.nummer}}</span>
        <span class="text-lg font-semibold">{{artikelMenge.artikel.artbez1}}</span>
        <span class="color-light">{{artikelMenge.artikel.artbez2}}</span>
        <span class="color-light">{{artikelMenge.artikel.artbez3}}</span>
        <span class="color-light">{{artikelMenge.artikel.artbez4}}</span>
        <span class="color-light">{{artikelMenge.artikel.artbez5}}</span>
      </div>
      <div class="flex flex-row flex-nowrap color-light items-center text-xl">
        <span>{{artikelMenge.menge}}</span>
        <span class="ml-1">{{artikelMenge.einheit.arteh}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="lagerplatzInhalt.inhalt.length === 0" class="color-light">
    {{'#LAGERPLATZ_CORE.COMPONENTS.ARTIKEL_MENGE_AUSWAHL.NO_ITEMS' | translate}}
  </div>
</ng-container>
