@if (configs(); as configs) {
  @if (configs.length > 0) {
    <div id="modal"
         class="modal-overlay"
         (keydown)="$event.stopPropagation();"
         (keyup)="$event.stopPropagation();">
      @for (config of configs | sort: 'priority':'ASC'; track config.id; let last = $last) {
        <soft-modal [config]="config"
                    [active]="last"
                    [style.display]="last ? 'block' : 'none'"
        ></soft-modal>
      }
    </div>
  }
}
