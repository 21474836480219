<header class="signaturepad-header mb-5">
  <h4 class="text-xl font-semibold">Unterschreiben</h4>
</header>

<div class="signaturepad-body my-5">
  <div class="signaturepad-input border-2 border-dashed mb-3" #input>
    <soft-signature-pad
      #signaturepad
      [options]="{
        minWidth: 2,
        canvasWidth: input.offsetWidth - 2,
        canvasHeight: input.offsetHeight
      }"
    ></soft-signature-pad>
  </div>

  <soft-checkbox #remember>
    <span class="soft-label right">Meine Unterschrift für zukünftige Verwendung speichern</span>
  </soft-checkbox>
</div>

<footer class="signaturepad-footer mt-5 flex no-wrap">
  <button
    class="soft-button secondary h-14 w-full"
    (click)="clear()"
    [disabled]="$any(signaturepad)?.signaturePad?.signaturePad?.isEmpty()"
  >
    Leeren
  </button>
  <button
    class="soft-button primary sm:ml-3 h-14 w-full"
    (click)="save(signaturepad.toDataURL('jpeg', 90))"
    [disabled]="$any(signaturepad)?.signaturePad?.signaturePad?.isEmpty()"
  >
    Speichern
  </button>
</footer>
