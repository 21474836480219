import { Component, computed, contentChild, input, output, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../page/page.component';
import { PageHeaderComponent } from '../page/page-header/page-header.component';
import { QueryPageShortcutsComponent } from '../query-page/query-page-shortcuts/query-page-shortcuts.component';
import { MasterDetailComponent, UiCoreModule, WithBreakpoints } from '@softline/ui-core';
import { RemoteDataContainerComponent } from '../../components/remote-data-container/remote-data-container.component';
import { LoadingState } from '@softline/core';
import { CdkPortalOutlet } from '@angular/cdk/portal';

@Component({
  selector: 'soft-master-detail-page',
  standalone: true,
  imports: [CommonModule, PageComponent, PageHeaderComponent, QueryPageShortcutsComponent, UiCoreModule, RemoteDataContainerComponent, CdkPortalOutlet],
  templateUrl: './master-detail.page.html',
  styleUrl: './master-detail.page.scss',
})
export class MasterDetailPage<T> extends WithBreakpoints() {
  private masterDetail = viewChild<MasterDetailComponent<T>>('masterDetail');

  data = input<T[]>([]);
  masterTemplate = input<TemplateRef<any> | null>(null);
  detailTemplate = input<TemplateRef<any> | null>(null);
  detailPlaceholder = input<TemplateRef<any> | null>(null);
  loadingState = input<LoadingState>(null);
  loadingError = input<Error | null>(null);

  selectedChange = output<T | null>();

  header = contentChild(PageHeaderComponent, { descendants: true });

  selected = computed(() => {
    const masterDetail = this.masterDetail();
    if(masterDetail)
      return masterDetail.selected();
    return null;
  });

  select(index: number): void {
    const masterDetail = this.masterDetail();
    if(masterDetail)
      masterDetail.select(index);
  }

  constructor() {
    super();
  }
}
