<ng-container *ngIf="form.valueChanges | async"></ng-container>
<form [formGroup]="form" class="mt-3">
  <div class="c column mt-3">
    <label class="soft-label top required">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.CUSTOMER' | translate }}</label>
    <soft-value-converter formControlName="customer" [converter]="kundeConverter">
      <soft-field-ok
        name="ktostamm.kunde"
        [parameters]="parameters"
        [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.CUSTOMER' | translate"
        #convertValue>
      </soft-field-ok>
    </soft-value-converter>
    <soft-validation-container class="soft-property-validation"
                               *ngIf="form.controls['customer'].touched"
                               [validation]="form|validationMessage:'customer'"></soft-validation-container>
  </div>

  <div class="c column mt-3">
    <label class="soft-label top required">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.TASK_TITLE' | translate }}</label>
    <soft-input [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.TASK_TITLE' | translate"
                formControlName="title"></soft-input>
    <soft-validation-container class="soft-property-validation"
                               *ngIf="form.controls['title'].touched"
                               [validation]="form|validationMessage:'title'"></soft-validation-container>
  </div>

  <div class="c column mt-3">
    <label class="soft-label top required">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.HEADER_TEXT' | translate }}</label>
    <soft-multiline-input [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.HEADER_TEXT' | translate"
                          formControlName="headerText"
                          [lines]="4"></soft-multiline-input>
    <soft-validation-container class="soft-property-validation"
                               *ngIf="form.controls['headerText'].touched"
                               [validation]="form|validationMessage:'headerText'"></soft-validation-container>
  </div>

  <div class="c column mt-3">
    <label class="soft-label top required">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.SELLING_METHOD' | translate }}</label>
    <soft-value-converter formControlName="sellingType" [converter]="verkaufsartwerkstattConverter">
      <soft-field-ok
        name="verkaufsartwerkstatt"
        [parameters]="form.value?.customer ? { kzart: 'H', idkunde: form.value?.customer?.id } : { kzart: 'H' }"
        [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.SELLING_METHOD' | translate"
        #convertValue>
      </soft-field-ok>
    </soft-value-converter>
    <soft-validation-container class="soft-property-validation"
                               *ngIf="form.controls['sellingType'].touched"
                               [validation]="form|validationMessage:'sellingType'"></soft-validation-container>
  </div>

  <div class="c column mt-3">
    <label class="soft-label top">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.VEHICLE' | translate }}</label>
    <soft-value-converter formControlName="vehicle" [converter]="fahrzeugConverter">
      <soft-field-ok
        name="fahrzeug"
        [parameters]="form.value.customer ? { idktostamm_kunde: form.value.customer.id } : {}"
        [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.VEHICLE' | translate"
        #convertValue>
      </soft-field-ok>
    </soft-value-converter>
    <soft-validation-container class="soft-property-validation"
                               *ngIf="form.controls['vehicle'].touched"
                               [validation]="form|validationMessage:'vehicle'"></soft-validation-container>
  </div>

  <div class="horizontally-stacked c row no-wrap">
    <div class="c column mt-3 mr-2">
      <label class="soft-label top">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.KILOMETERS' | translate }}</label>
      <soft-number-input [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.KILOMETERS' | translate"
                         formControlName="kilometersStart"></soft-number-input>
      <soft-validation-container class="soft-property-validation"
                                 *ngIf="form.controls['kilometersStart'].touched"
                                 [validation]="form|validationMessage:'kilometersStart'"></soft-validation-container>
    </div>

    <div class="c column mt-3 ml-2">
      <label class="soft-label top">{{ '#WORKSHOP.DIALOGS.CREATE_TASK.OPERATING_HOURS' | translate }}</label>
      <soft-number-input [placeholder]="'#WORKSHOP.DIALOGS.CREATE_TASK.OPERATING_HOURS' | translate"
                         formControlName="operatingHours"></soft-number-input>
      <soft-validation-container class="soft-property-validation"
                                 *ngIf="form.controls['operatingHours'].touched"
                                 [validation]="form|validationMessage:'operatingHours'"></soft-validation-container>
    </div>
  </div>

  <div class="flex items-center justify-end no-wrap mt-4 fill gap-4">
    <button class="soft-link mr-3" type="reset" (click)="form.reset()">Zurücksetzen</button>
  </div>

  <button class="soft-button primary w-fill fixed bottom-0 left-0 right-0 h-16 fill-force text-center"
          [class.disabled-button]="form?.invalid || (isCreating$ | async)"
          [disabled]="form?.invalid || (isCreating$ | async)"
          (click)="onSubmit()">
    <i *ngIf="isCreating$ | async" class="fa-regular fa-spinner fa-spin mr-2"></i>
    {{ '#WORKSHOP.DIALOGS.CREATE_TASK.CREATE' | translate }}</button>
</form>

