import { Route } from '@angular/router';
import { AuthorizationResolver } from '@softline/auth';

export const moduleRoutes: Route[] = [
  //{ path: 'my-apps', component: MyApplicationsComponent },
  //{ path: 'favorites', component: FavoritesComponent },
  {
    path: '',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/app-overview.component').then(
            (o) => o.AppOverviewComponent
          ),
      },
    ],
    resolve: {
      permissions: AuthorizationResolver,
    },
  },
];
