import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
    BackNavigationService, BlockingLoadingSpinnerComponent, showRequestErrors, HeaderComponent,
    ScanButtonComponent, ScannerStore, SOFTLINE_FEATURE_SCANNER,
    SOFTLINE_FEATURE_TITLE, Step,
    StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG } from "../../lagerplatz-umlagerung.shared";
import { LagerplatzUmlagerungStore } from "../../store";
import { ArtikelMenge, ArtikelMengeAuswahlComponent } from "@softapps/lager/core";
import { asapScheduler, BehaviorSubject, observeOn, skip, Subscription } from "rxjs";

@Component({
  selector: 'soft-artikel-auswahl',
  standalone: true,
    imports: [CommonModule, UiCoreModule, StepHeaderComponent, ArtikelMengeAuswahlComponent, ScanButtonComponent, BlockingLoadingSpinnerComponent, HeaderComponent],
  templateUrl: './artikel-auswahl.page.html',
  styleUrls: ['./artikel-auswahl.page.scss']
})
export class ArtikelAuswahlPage implements OnInit {

  readonly steps: Step[] = [
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.QUELLLAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.QUELLLAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.ZIELLAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.ZIELLAGERPLATZ_SCANNEN.DESCRIPTION'
    },
  ]

  private subscription?: Subscription;

  loading$ = new BehaviorSubject<boolean>(false);
  quellLagerplatz$ = this.store.observe(SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG, LagerplatzUmlagerungStore.getters.quellLagerplatz);

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  ngOnInit(): void {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERPLATZ_UMLAGERUNG.TITLE');
    this.subscription = this.store.observe(SOFTLINE_FEATURE_SCANNER, ScannerStore.getters.latest)
      .pipe(
        skip(1),
        observeOn(asapScheduler)
      )
      .subscribe(async scan => {
        if(!scan || this.loading$.value)
          return;

        this.loading$.next(true);
        try {
          await this.store.dispatch(SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG, LagerplatzUmlagerungStore.actions.loadPalette, scan.data);
          this.loading$.next(false);
          await this.router.navigate(['/lagerplatz-umlagerung', 'mengen-auswahl'])
        }
        catch (e) {
          this.loading$.next(false);
          showRequestErrors(this.store, e);
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = undefined;
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/lagerplatz-umlagerung'])
  }

  async onSelect(artikel: ArtikelMenge): Promise<void> {
    this.store.commit(SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG, LagerplatzUmlagerungStore.mutations.setArtikel, {...artikel});
    await this.router.navigate(['/lagerplatz-umlagerung', 'mengen-auswahl'])
  }
}
