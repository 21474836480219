import { DateService, Dictionary, Store2Feature } from '@softline/core';
import { computed, inject, Injectable } from '@angular/core';
import { Favorite } from './favorite';
import { SOFTLINE_REPOSITORY_FAVORITE } from './favorite.shared';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FavoriteStore2<T> extends Store2Feature<Readonly<Dictionary<Favorite<T>[]>>> {

  name = 'favorite';
  override initialState = { } as Dictionary<Favorite<T>[]>;

  dateService = inject(DateService);
  repository = inject(SOFTLINE_REPOSITORY_FAVORITE);

  favorites = (key: string) => computed(() => {
    const value = this.state()[key] as Favorite<T>[];
    return value ?? [];
  })

  constructor() {
    super();
  }

  async load(key: string): Promise<Favorite<T>[]> {
    const favorites = await lastValueFrom(this.repository.loadItem(key));
    this.commit({ [key]: favorites });
    return favorites;
  }

  async add(key: string, id: string | number, object: T): Promise<void> {
    const timestamp = this.dateService.now();
    let favorites = this.state()[key] as Favorite<T>[] ?? [];
    favorites = [{ id, object, timestamp }, ...favorites];
    await lastValueFrom(this.repository.saveItem(key, favorites));
    this.commitPatch({ [key]: favorites } as any);
  }

  async remove(key: string, id: string | number): Promise<void> {
    let favorites = this.state()[key] as Favorite<T>[] ?? [];
    favorites = favorites.filter(favorite => favorite.id !== id);
    await lastValueFrom(this.repository.saveItem(key, favorites));
    this.commitPatch({ [key]: favorites } as any);
  }

  async clear(): Promise<void> {
    await lastValueFrom(this.repository.save({}));
    this.commit({ });
  }

  override getDefaultState(): Readonly<Dictionary<Favorite<T>[]>> {
    return { } as Dictionary<Favorite<T>[]>;
  }
}
