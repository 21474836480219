import { createAction, DefaultStore, on, SOFTLINE_SERVICE_HTTP, SyncedRemoteCollectionStore } from "@softline/core";
import { Auftrag } from "../data/auftrag";
import { lastValueFrom } from "rxjs";
import { SOFTLINE_API_WERKSTAETTENAUFTRAG_SIGN_TASK_RESOURCE_PATH } from "../auftrag.api";
import { SignatureService } from "../services/signature.service";

const collectionStore = SyncedRemoteCollectionStore.create<Auftrag>()
const templateStore = DefaultStore.create<Auftrag>()

export const getters = {
  ...collectionStore.getters,
  ...templateStore.getters,
}

export const mutations = {
  ...collectionStore.mutations,
  ...templateStore.mutations
}

export const actions = {
  ...collectionStore.actions,
  ...templateStore.actions,
  complete: createAction<SyncedRemoteCollectionStore.State, { auftrag: Auftrag; method: 'approval' | 'deliveryNote' | 'invoice' }, Auftrag>('werkstaettenauftrag/auftraege/complete'),
  sign: createAction<SyncedRemoteCollectionStore.State, { id: Auftrag['id'], signature: {  files: Blob[] } }, any>('werkstaettenauftrag/auftraege/sign')
}

export const feature = {
  initialState: {
    ...templateStore.feature.initialState,
    ...collectionStore.feature.initialState
  },
  getters: [
    ...collectionStore.feature.getters,
    ...templateStore.feature.getters,
  ],
  mutations: [
    ...collectionStore.feature.mutations,
    ...templateStore.feature.mutations
  ],
  actions: [
    ...collectionStore.feature.actions,
    ...templateStore.feature.actions,
    on(actions.complete, async ({ injector, featureName, commit, params }) => {
      const service = injector.get(SOFTLINE_SERVICE_HTTP);
      const result = await lastValueFrom(
        service.create(
          { path: `${SOFTLINE_API_WERKSTAETTENAUFTRAG_SIGN_TASK_RESOURCE_PATH}/${params.auftrag.id}/close` },
          { type: params.method }
        )
      );
      commit(featureName, mutations.remove, params.auftrag);
      return result;
    }),
    on(actions.sign, async ({ injector, params, commit, featureName, get }) => {
      const service = injector.get(SignatureService);
      const signature = await lastValueFrom(service.sign(params.id?.toString(), params.signature));
      commit(featureName, collectionStore.mutations.patch, { id: params.id, changes: { signature } })
      return get(featureName, collectionStore.getters.entity, params.id);
    })
  ]
}


