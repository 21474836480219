import { inject, StaticProvider } from '@angular/core';
import { IndexedDbService, SOFTLINE_STORE_FEATURES } from '@softline/core';
import { SOFTLINE_NAME_FAVORITES_OBJECT_STORE, SOFTLINE_REPOSITORY_FAVORITE } from './favorite.shared';
import { FavoriteStore2 } from './favorite.store2';
import { UserIndexedDbDictionaryRepository } from '../repositories/user-indexed-db-dictionary.repository';


export const favoriteProviders: StaticProvider[] = [
  { provide: SOFTLINE_REPOSITORY_FAVORITE,
    useFactory: () => new UserIndexedDbDictionaryRepository(
      inject(IndexedDbService),
      SOFTLINE_NAME_FAVORITES_OBJECT_STORE
    )},
  { provide: SOFTLINE_STORE_FEATURES, useExisting: FavoriteStore2, multi: true },
]
