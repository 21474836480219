import {
  Injectable,
  InjectFlags,
  Injector,
  Optional,
  Type,
} from '@angular/core';
import { AddOn } from '../add-on';
import { AddOnHost } from '../add-on-host';
import { Logger } from '@softline/core';

@Injectable()
export class AddOnService {
  constructor(private injector: Injector) {}

  async attachTo(host: AddOnHost): Promise<void> {
    const addOns = this.injector.get<AddOn<any>[]>(
      AddOn as Type<any>,
      [],
      InjectFlags.Optional
    );
    for (const addOn of addOns.filter(
      (o) => o.type.name === host.constructor.name
    )) {
      console.debug(
        `[AddOnService] Attatching ${addOn.constructor.name} to ${host.constructor.name}`
      );
      await addOn.onAttach(host);
    }
  }

  async detachFrom(host: AddOnHost): Promise<void> {
    const addOns = this.injector.get<AddOn<any>[]>(
      AddOn as Type<any>,
      [],
      InjectFlags.Optional
    );
    for (const addOn of addOns.filter(
      (o) => o.type.name === host.constructor.name
    )) {
      console.debug(
        `[AddOnService] Detaching ${addOn.constructor.name} from ${host.constructor.name}`
      );
      await addOn.onDetach(host);
    }
  }
}
