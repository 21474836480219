import {
  CancelledError,
  ConnectionError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  RequestError,
  ServerError,
  Store,
  UnauthorizedError,
  UnknownResponseError,
} from '@softline/core';
import {
  MessageBarStore,
  SOFTLINE_FEATURE_MESSAGE_BAR,
} from '@softline/ui-core';
import { JwtAuthenticationStore, SOFTLINE_FEATURE_JWT_AUTHENTICATION } from "@softline/auth";

export interface HandleRequestOptions {
  showCancellationWarnings?: boolean;
  connectionErrorAsWarning?: boolean;
  customUnauthorizedMessage?: string;
}

export function showRequestErrors(store: Store, error: unknown): void {
  if (error instanceof NetworkError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#APPLICATION.MESSAGES.ERRORS.NETWORK_TITLE',
        message: '#APPLICATION.MESSAGES.ERRORS.NETWORK',
        params: error
      }
    );
  else if (error instanceof ConnectionError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#APPLICATION.MESSAGES.ERRORS.CONNECTION_TITLE',
        message: '#APPLICATION.MESSAGES.ERRORS.CONNECTION',
        params: error
      }
    );
  else if (error instanceof RequestError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#APPLICATION.MESSAGES.ERRORS.REQUEST_TITLE',
        message: '#APPLICATION.MESSAGES.ERRORS.REQUEST',
        params: error
      }
    );
  else if (error instanceof UnauthorizedError) {
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      '#APPLICATION.MESSAGES.ERRORS.UNAUTHORIZED'
    );
    store.dispatch(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.actions.logout,
      { expired: true }
    );
  }
  else if (error instanceof ForbiddenError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        message: '#APPLICATION.MESSAGES.ERRORS.FORBIDDEN',
        params: error,
      }
    );
  else if (error instanceof NotFoundError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      '#APPLICATION.MESSAGES.ERRORS.NOT_FOUND'
    );
  else if (error instanceof ServerError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#APPLICATION.MESSAGES.ERRORS.SERVER_TITLE',
        message: '#APPLICATION.MESSAGES.ERRORS.SERVER',
        params: error
      }
    );
  else if (error instanceof UnknownResponseError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN_RESPONSE_TITLE',
        message: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN_RESPONSE',
        params: error,
      }
    );
  else if (error instanceof CancelledError)
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.warning,
      { message: '#APPLICATION.MESSAGES.ERRORS.CANCELLED', params: error }
    );
  else {
    store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN_TITLE',
        message: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN',
        params: error as Error,
      }
    );
    throw error;
  }
}
