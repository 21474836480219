@if(salesPrice(); as salesPrice) {
  <div class="flex flex-col">
    <div class="flex flex-row justify-between flex-wrap-reverse">
      <div class="flex flex-col">
        <span class="text-sm text-primary font-bold uppercase text-nowrap">{{salesPrice.priceLevel.name}}</span>
      </div>
      <div class="flex flex-col items-end">
        <span class="font-semibold text-xs text-nowrap">Gültig von: {{salesPrice.validFrom | format: 'DATE.DEFAULT'}}</span>
        @if(salesPrice.validTo) {
          <span class="font-semibold text-xs text-nowrap">bis: {{salesPrice.validTo | format: 'DATE.DEFAULT'}}</span>
        }
      </div>
    </div>
    <soft-price-card class="p-2" [price]="salesPrice.prices" [unit]="salesPrice.unit"></soft-price-card>
  </div>
}
