import { Component, OnInit } from '@angular/core';
import {
  PdfEditorTool,
  PdfEditorToolGroup,
  PdfEditorToolGroupName,
  PdfEditorToolType,
} from '../../data/pdf-editor-tool';
import { Observable } from 'rxjs';
import { SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT } from '../../pdf-editor.shared';
import { PdfEditorComponentStore } from '../../store/pdf-editor-component.store';
import { Store } from '@softline/core';
import { ModalStore, SOFTLINE_FEATURE_MODAL } from '@softline/ui-core';
import { PdfEditorSignatureDialogComponent } from '../pdf-editor-signature-dialog/pdf-editor-signature-dialog.component';
import { CommonModule } from '@angular/common';
import { PdfEditorToolCustomizationComponent } from '../pdf-editor-tool-customization/pdf-editor-tool-customization.component';

@Component({
  selector: 'soft-pdf-editor-tools',
  standalone: true,
  imports: [CommonModule, PdfEditorToolCustomizationComponent],
  templateUrl: './pdf-editor-tools.component.html',
  styleUrls: ['./pdf-editor-tools.component.scss'],
})
export class PdfEditorToolsComponent implements OnInit {
  toolGroups$: Observable<readonly PdfEditorToolGroup[]> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.toolGroups
  );

  selectedTool$: Observable<PdfEditorTool | undefined> = this.store.observe(
    SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
    PdfEditorComponentStore.getters.selectedTool
  );

  selectedToolGroup$: Observable<PdfEditorToolGroup | undefined> =
    this.store.observe(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.getters.selectedToolGroup
    );

  constructor(private store: Store) {}

  ngOnInit(): void {}

  selectTool(tool: PdfEditorTool): void {
    // Initially disable the free drawing
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.enableFreeDrawing,
      false
    );
    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.discardSelectedObjects
    );

    // Unset the tool if is already selected
    if (
      this.store.get(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.getters.selectedTool
      ) === tool
    ) {
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.selectTool,
        undefined
      );
      return;
    }

    this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
      PdfEditorComponentStore.mutations.selectTool,
      tool
    );

    if (tool.type === PdfEditorToolType.Pen) {
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.enableFreeDrawing,
        true
      );
    } else if (tool.type === PdfEditorToolType.Marker) {
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.enableFreeDrawing,
        true
      );
    } else if (tool.type === PdfEditorToolType.TextBox) {
      // this.store.commit(SOFTLINE_FEATURE_PDF_EDITOR, PdfEditorStore.mutations.enableFreeDrawing, false);
    } else if (tool.type === PdfEditorToolType.Image) {
      // this.store.commit(SOFTLINE_FEATURE_PDF_EDITOR, PdfEditorStore.mutations.enableFreeDrawing, false);
    }
  }

  async addToolInGroup(toolGroup: PdfEditorToolGroup): Promise<void> {
    if (toolGroup.name === PdfEditorToolGroupName.Signature) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open(),
        {
          component: PdfEditorSignatureDialogComponent,
          data: { toolGroup },
          dismiss: { button: true, escape: true, backdrop: false },
        }
      );
    }
  }

  async deleteSelectedCustomTool(): Promise<void> {
    const result = await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.ask,
      {
        question: '#PDF_EDITOR.ASK.CONFIRM_DELETE',
        dismiss: { escape: true, backdrop: true },
      }
    );

    if (result === 'YES') {
      const selectedToolGroup = this.store.get(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.getters.selectedToolGroup
      );
      const selectedTool = this.store.get(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.getters.selectedTool
      );
      const params = {
        groupName: selectedToolGroup?.name,
        tool: selectedTool,
      };

      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.deleteTool,
        params
      );
      this.store.commit(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.mutations.selectTool,
        undefined
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT,
        PdfEditorComponentStore.actions.deleteCustomTool,
        params
      );
    }
  }
}
