import { Directive, effect, EffectRef, inject, input, isSignal, OnDestroy, OnInit } from '@angular/core';

import { PageSettingsDirectiveBase } from '../page-settings-directive.base';
import { SettingsComponent } from '@softline/ui-core';

@Directive({
  selector: '[softPageSettingsComponent]',
  standalone: true,
})
export class ComponentSettingsDirective<T extends SettingsComponent> extends PageSettingsDirectiveBase<T>{

  override name = input.required<string>({alias: 'softPageSettings'});
  override component = input.required<T>({alias: 'softPageSettingsComponent'});

  constructor() {
    super();
  }

}
