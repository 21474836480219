import { computed, Directive, effect, inject, input, OnInit } from '@angular/core';
import { PageSettingsStore } from '../../page-settings.store';
import { equals } from '@softline/core';
import { ComponentSettings, PageSettings } from '../../page-settings';

@Directive({
  selector: '[softPageSettingsHost]',
  standalone: true,
})
export class PageSettingsHostDirective {

  readonly store = inject(PageSettingsStore);
  name = input.required<string>({alias: 'softPageSettingsHost'});

  nameEffect = effect(() => {
    try {
      this.store.repository.loadOnce(this.name(), {});
    }
    catch (e) {
      console.debug(`[PageSettingsHostDirective] no component settings found.`, e);
    }
  });

  pageSettings = computed<PageSettings>(() => {
    const state = this.store.settings.dict();
    return state[this.name()] ?? {name: this.name(), components: [] as ComponentSettings[]};
  })

  constructor() { }

  set(componentSettings: ComponentSettings): void {
    const name = this.name();
    const pageSettings = this.pageSettings();
    const index = pageSettings.components.findIndex(o => o.name === componentSettings.name);
    if(index > -1 && equals(componentSettings, pageSettings.components[index]))
      return;
    const components = [...pageSettings.components];
    if(index > -1)
      components.splice(index, 1, componentSettings);
    else
      components.push(componentSettings);
    this.store.repository.update({value: {...pageSettings, components}});
  }
}
