import { ENVIRONMENT_INITIALIZER, inject, ModuleWithProviders, NgModule } from '@angular/core';
import { SOFTLINE_CONFIG_INDEXED_DB, Store, Store2, StoreModule } from '@softline/core';
import { MenuItemStore2, QueryHistoryService, SOFTLINE_FEATURE_SHELL, TemplateService } from '@softline/application';
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from '@softline/ui-core';
import { default as de } from '../i18n/de.json';
import { SoftappsTemplateService } from './templates/services/softapps-template.service';
import { SoftappsQueryHistoryService } from './templates/services/softapps-query-history.service';
import * as ShellStore from './shell/shell.store';
import { pageSettingsProviders } from './user/page-settings/page-settings.providers';
import { favoriteProviders } from './user/favorites/favorite.providers';
import { SOFTLINE_INDEXED_DB_USER } from './user/user.indexed-db';
import { userProviders } from './user/user.providers';

@NgModule({
  declarations: [],
  imports: [],
  providers: [],
  exports: [],
})
export class SoftappsCoreModule {
  static forRoot(): ModuleWithProviders<SoftappsCoreRootModule> {
    return {
      ngModule: SoftappsCoreRootModule,
      providers: [
        { provide: TemplateService, useClass: SoftappsTemplateService },
        { provide: QueryHistoryService, useClass: SoftappsQueryHistoryService },
      ],
    };
  }
}

@NgModule({
  imports: [
    SoftappsCoreModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_SHELL,
      feature: ShellStore.feature,
    }),],
  exports: [SoftappsCoreModule],
  providers: [
    ...userProviders
  ],
})
export class SoftappsCoreRootModule {
  constructor(store: Store) {
    store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
      module: 'softapps-core',
      language: 'de',
      translations: de,
    });
  }
}
