import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuftragArtikel} from '../../data/auftrag-artikel';
import {Einheit} from '../../data/einheit';
import {Artikel} from '../../data/artikel';
import {UiCoreModule} from '@softline/ui-core';

export type ArtikelData = {
  key: { id: number | string; c1?: unknown; c2: unknown };
  artikel?: Artikel | null;
  quantity: number | null;
  einheit?: Einheit | null;
  customization?: AuftragArtikel['customization'];
  entities: AuftragArtikel[];
};

@Component({
  selector: 'soft-auftrag-artikel-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './auftrag-artikel-card.component.html',
  styleUrls: ['./auftrag-artikel-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuftragArtikelCardComponent {

  @Input() item?: ArtikelData;
  @Input() index?: number;
  @Input() removingIndex?: number | null;

  @Output() delete = new EventEmitter<ArtikelData>();
  @Output() moreTap = new EventEmitter<ArtikelData>();
}
