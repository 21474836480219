<div class="soft-card c row rounded center no-wrap space-between gap-4" [ngClass]="(index || 0) < 1 ? 'mt-2' : 'mt-3'">
  <div class="text-content truncate">
    <p class="text-sm font-semibold truncate mb-0 mr-2">
      <ng-container *ngIf="item?.activity">
        {{ item?.activity?.name }} {{ item?.activity?.title }}
      </ng-container>
      <ng-container *ngIf="!item?.activity">
        {{ '#WORKSHOP.WORK_REPORT.NO_ACTIVITY' | translate }}
      </ng-container>
    </p>
    <p class="text-sm mt-0 color-light mb-0">{{ item?.from | format:'TIME.DEFAULT'}} - {{ item?.to | format:'TIME.DEFAULT' }}</p>
  </div>
  <div class="right-content min-w-[7rem] mr-2 flex items-center justify-end">
    <ng-container *ngIf="hasWorkReportDeletePermission$ | async; else defaultTemplate">
      <span class="text-sm font-semibold text-accent-700 mb-0 mr-2">{{ item?.date | date:'dd.MM.yyyy':'MEZ' }}</span>
      <i class="fa-regular fa-trash text-danger-600 ml-2" (click)="delete.emit(item)"></i>
    </ng-container>
    <ng-template #defaultTemplate>
      <span class="text-sm font-semibold text-accent-700 margin-bottom-0">{{ item?.date | date:'dd.MM.yyyy':'MEZ' }}</span>
    </ng-template>
  </div>
</div>

