import { Dictionary } from '@softline/core';

export type BooleanString = 'true' | 'false';
export type PluginConfig =
  | BarcodePluginConfig
  | IntentPluginConfig
  | BdfPluginConfig
  | KeystrokePluginConfig;

export interface Profile {
  PROFILE_NAME: string;
  PROFILE_ENABLED: BooleanString;
  PLUGIN_CONFIG?: PluginConfig;
  APP_LIST?: App[];
}

export interface ProfileConfig {
  CONFIG_MODE: 'CREATE_IF_NOT_EXIST' | 'OVERRIDE' | 'UPDATE';
  PROFILE_NAME: string;
  PROFILE_ENABLED: BooleanString;
  PLUGIN_CONFIG?: PluginConfig[];
  APP_LIST?: App[];
}

export interface App {
  PACKAGE_NAME: string;
  ACTIVITY_LIST: string[];
}

export interface BarcodePluginConfig {
  PLUGIN_NAME: 'BARCODE';
  RESET_CONFIG: BooleanString;
  PARAM_LIST: Partial<ScannerInputParameters>;
}

export interface IntentPluginConfig {
  PLUGIN_NAME: 'INTENT';
  RESET_CONFIG: BooleanString;
  PARAM_LIST: Partial<IntentParameters>;
}
export interface BdfPluginConfig {
  PLUGIN_NAME: 'BDF';
  RESET_CONFIG: BooleanString;
  PARAM_LIST: Partial<BdfParameters>;
}
export interface KeystrokePluginConfig {
  PLUGIN_NAME: 'KEYSTROKE';
  RESET_CONFIG: BooleanString;
  PARAM_LIST: Partial<KeystrokeOutputParameters>;
}

export interface BdfParameters {
  bdf_enabled?: BooleanString;
  bdf_prefix?: string;
  bdf_suffix?: string;
  bdf_send_data?: string;
  bdf_send_hex?: BooleanString;
  bdf_send_tab?: BooleanString;
  bdf_send_enter?: BooleanString;
}

export interface IntentParameters {
  intent_output_enabled?: BooleanString;
  intent_action?: string;
  intent_category?: string;
  intent_delivery?: IntentDelivery;
}

export enum IntentDelivery {
  StartActivity = 0,
  StartService = 1,
  Broadcast = 2,
}

export enum AimType {
  Trigger = 0,
  TimedHold = 1,
  TimedRelease = 2,
  PressAndRelease = 3,
  ContinuousRead = 5,
}

export interface ScannerInputParameters {
  scanner_input_enabled: BooleanString;
  scanner_selection: 'auto' | number;
  trigger_wakeup: BooleanString;
  aim_mode: 'on' | 'off';
  aim_type: AimType;
  aim_timer: number;

  decoder_upca: BooleanString;
  decoder_upce0: BooleanString;
  decoder_code39: BooleanString;
  decoder_code93: BooleanString;
  decoder_code128: BooleanString;
  decoder_ean8: BooleanString;
  decoder_ean13: BooleanString;
  decoder_i2of5: BooleanString;

  decoder_pdf417: BooleanString;
  decoder_aztec: BooleanString;
  decoder_datamatrix: BooleanString;
  decoder_qrcode: BooleanString;
  decoder_maxicode: BooleanString;
}

export interface KeystrokeOutputParameters {
  keystroke_output_enabled: BooleanString;
  keystroke_action_character: 'NONE' | 'TAB' | 'LF' | 'CR';
  keystroke_delay_extended_ascii: number;
  keystroke_delay_control_characters: number;
}
