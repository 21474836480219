import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultComponent} from '@softapps/core';
import {I18nModule} from '@softline/ui-core';

interface Lagersymbol {
  addlagerstand: number;
  aktiv: string;
  bezeichnung: string;
  id: number;
  kzabfallbilanz: string;
  kzaddlager: 'J' | 'N' | string;
  kzbelegart: 'B' | string;
  kznachkalk: string;
  kzpalette: 'N' | 'J' | string;
  lagersymbol: string;
}

@Component({
  selector: 'soft-search-result-view',
  standalone: true,
  imports: [CommonModule, I18nModule],
  templateUrl: './search-result-view.component.html',
  styleUrl: './search-result-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LagersymFieldOkSearchResultViewComponent implements SearchResultComponent<Lagersymbol> {
  readonly result = input.required<Lagersymbol>();
}
