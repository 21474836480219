@if (result(); as item) {
  <div class="flex flex-col gap-0.5">
    <div class="flex flex-row text-sm color-primary">
      <span>{{item.cartikelnummer}}</span>
    </div>
    <div class="flex flex-row">
      <span class="font-semibold">{{item[5]}}</span>
    </div>
    <div *ngIf="item[26]" class="flex flex-row">
      <span class="text-xs text-light">{{item[26]}}</span>
    </div>
    <div *ngIf="item[27]" class="flex flex-row">
      <span class="text-xs text-light">{{item[27]}}</span>
    </div>
    <div *ngIf="item[28]" class="flex flex-row">
      <span class="text-xs text-light">{{item[28]}}</span>
    </div>
    <div *ngIf="item[29]" class="flex flex-row">
      <span class="text-xs text-light">{{item[29]}}</span>
    </div>
    <div *ngIf="item['lagerstand_gesamt'] !== null && item['lagerstand_gesamt'] !== undefined && item['lagereh']" class="flex flex-row">
      <span class="text-xs font-semibold text-light">{{ '#WWS_CORE.FOK.ARTSTAMM.LIST.LAGERSTAND' | translate }} {{item['lagerstand_gesamt'] | number:'1.2-2' }} {{item['lagereh']}}</span>
    </div>
  </div>
}

