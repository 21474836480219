import { Component, contentChild, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soft-drag-and-drop-select-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './drag-and-drop-select-item.component.html',
  styleUrl: './drag-and-drop-select-item.component.scss',
})
export class DragAndDropSelectItemComponent<T> {
  value = input.required<T>();
  template = viewChild<TemplateRef<any>>('template');
}
