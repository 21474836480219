<div class="soft-card">
  <div class="flex items-center justify-between w-full bg-surface"
       [class.pointer]="collapsable()"
       (click)="collapsable() ? toggleCollapse($event) : undefined" >
    <div class="flex flex-row w-full">
      @if(title(); as title) {
        <h6 class="text-lg font-semibold px-2 py-2">{{title | translate}}</h6>
      }
      @if (titleTemplate(); as template) {
        <ng-container *ngTemplateOutlet="template"></ng-container>
      }
      @if (panels(); as panels) {
        @if(panels.length > 1) {
          @for(panel of panels; track panel) {
            <button class="hover:bg-surface-tertiary rounded flex items-center justify-center px-4"
                    [class.text-primary]="panel.name() === (selectedPanelName() ?? firstPanelName())"
                    (click)="selectPanel(panel.name(), $event)">
              <soft-icon [name]="panel.icon()"></soft-icon>
            </button>
          }
        }
      }
    </div>
    <div>

      @if(collapsable()) {
        <button class="hover:bg-surface-tertiary rounded flex items-center justify-center p-1 mx-4">
          <soft-icon [name]="collapsed() ? 'fa-regular fa-chevron-up text-light' : 'fa-regular fa-chevron-down text-light'"></soft-icon>
        </button>
      }
    </div>
  </div>
  @if (!collapsed()) {
    <div class="soft-separator my-4"></div>
    @if (headerTemplate(); as headerTemplate) {
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <div class="soft-separator my-4"></div>
    }
    @if (selectedPanel(); as selectedPanel) {
      @if (selectedPanel.template(); as template) {
        <ng-container *ngTemplateOutlet="template"></ng-container>
      }
    }
    @if (footerTemplate(); as footerTemplate) {
      <div class="soft-separator my-4"></div>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    }
    <ng-content></ng-content>
  }
</div>

