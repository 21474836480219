import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { Scanner, SOFTLINE_CONFIG_SCANNER } from "@softline/application";
import { UiCoreModule } from "@softline/ui-core";

import { Store } from "@softline/core";
import { EbScannerService } from "./services/eb-scanner.service";
import { EbScannerSettings } from "./types/eb-scanner.settings";
import { EbBatteryService } from "./services/eb-battery.service";

export interface EbScannerModuleOptions {
  defaultSettings: EbScannerSettings;
}

export const defaultOptions: EbScannerModuleOptions = {
  defaultSettings: {
  },
};
export function ebScannerFactory(service: EbScannerService): Scanner {
  return {
    name: 'eb',
    priority: 100,
    title: 'Hardware (EnterpriseBrowser)',
    service,
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
})
export class EbModule {
  static forRoot(options: EbScannerModuleOptions = defaultOptions): ModuleWithProviders<EbRootModule> {
    return {
      ngModule: EbRootModule,
      providers: [
        EbScannerService,
        EbBatteryService,
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: ebScannerFactory,
          deps: [EbScannerService],
        }
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, UiCoreModule],
  exports: [],
})
export class EbRootModule {
  constructor(store: Store,
              service: EbScannerService,
              batteryService: EbBatteryService) {
    service.init();
    batteryService.init();
  }
}
