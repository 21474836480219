export interface Modal<T> {
  registerCloseHandler(handler: (result: T) => void): void;
}

export class ModalBase<T> implements Modal<T> {
  close: (result: T) => void = () => { }

  registerCloseHandler(handler: (result: T) => void): void {
    this.close = handler;
  }
}

export type Dismissed = 'DISMISSED';
