import { inject, Injectable } from "@angular/core";
import { DateService, isDefinedNotEmpty, SOFTLINE_SERVICE_UUID, Store } from "@softline/core";
import * as QueryHistoryStore from '../query-history.store';
import { Template } from "../template";
import { showRequestErrors } from "../../errors/show-request-errors.function";

type Constructor<T> = new(...args: any[]) => T;

export interface QueryHistoryMixinParameters {
  featureName: string,
  group?: string
}

export const WithQueryHistory = <T extends Constructor<{}>>(params: QueryHistoryMixinParameters, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class QueryHistoryMixin extends Base {

    store = inject(Store);
    dateService = inject(DateService);
    uuid = inject(SOFTLINE_SERVICE_UUID);

    constructor(...args: any[]) {
      super(...args);
    }

    async saveToQueryHistory(query: object): Promise<void> {
      const group = params.group ?? this.getQueryHistoryGroup();
      if(!isDefinedNotEmpty(group))
        throw new Error('[QueryHistoryMixin] Cannot save to query history without a group')
      const template: Template<any> = {
        id: this.uuid(),
        group,
        value: query,
        timestamp: this.dateService.now()
      }
      try {
        await this.store.dispatch(params.featureName, QueryHistoryStore.actions.queryHistory.create, template);
      }
      catch (e) {
        showRequestErrors(this.store, e);
      }
    }

    getQueryHistoryGroup(): string {
      return ''
    }
  }
  return QueryHistoryMixin;
}
