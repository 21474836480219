import { Artikel } from "./artikel";
import { Einheit } from "./einheit";

export interface ArtikelMenge {
  artikel: Artikel;
  einheit: Einheit;
  einheit_lg: Einheit;
  menge: number;
  menge_lg: number;
}
