import { Injectable, NgZone } from "@angular/core";
import { LabelType, Scan, ScannerService, ScannerStore, SOFTLINE_FEATURE_SCANNER } from "@softline/application";
import { DateService, Store } from "@softline/core";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { first, map } from "rxjs/operators";
import { EbScanResult } from "../types/eb-scan-result";

declare var EB: any;

@Injectable()
export class EbScannerService extends ScannerService{

  private subject?: BehaviorSubject<any>;

  constructor(private store: Store,
              private dateService: DateService,
              private ngZone: NgZone) {
    super();
  }

  override async init(): Promise<void> {
    this.isAvailable = !!(window as any).EBWeb
      && EB.Barcode.enumerate()?.length > 0;
  }

  override async activate(): Promise<void> {
    EB.Barcode.enable({allDecoders:true}, (result: EbScanResult) => {
      this.ngZone.run(() => this.onScan(result))
    });
  }

  override async deactivate(): Promise<void> {
    EB.Barcode.disable();
  }

  async scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    if (this.subject && !this.subject.closed){
      this.subject.error(
        '[Softline Zebra EB] EBScannerService - Cancelled by another scan ident'
      );
      this.subject = undefined;
    }

    this.subject = new BehaviorSubject<Scan | undefined>(undefined);
    await new Promise<void>( (done) => EB.Barcode.start((result: Scan) => done()));
    return lastValueFrom(
      this.subject.pipe(
        first((o) => !!o),
        map((o: Scan) => o)
      )
    );
  }

  cancel(): Promise<void> {
    if (this.subject && !this.subject.closed) {
      this.subject.error('[Softline Zebra EB] EBScannerService - Cancelled by action');
      this.subject = undefined;
      return new Promise( (done) => {
        EB.Barcode.stop(() => done());
      });
    } else
      throw Error(
        '[Softline Zebra EB] EBScannerService - Unable to cancel: no scan running'
      );
  }

  private onScan(result: EbScanResult): void {
    const scan: Scan = {
      data: result.data,
      labelType: this.convertLabelType(result.type) ?? (result.source + '-' + result.type as LabelType),
      timestamp: this.dateService.now(),
    };
    if (this.subject && !this.subject.closed) {
      this.subject.next(scan);
      this.subject.complete();
      this.subject = undefined;
    } else {
      this.store.commit(
        SOFTLINE_FEATURE_SCANNER,
        ScannerStore.mutations.add,
        scan
      );
    }
  }

  convertLabelType(type: string): LabelType | undefined {
    switch (type) {
      // 1D

      case 'codabar':
        return 'codabar';
        break;
      case '0x37':
        return 'code39';
        break;
      case '0x3b':
        return 'code93';
        break;
      case '0x3c':
        return 'code128';
        break;
      case '0x34':
        return 'ean8';
        break;
      case '0x35':
        return 'ean13';
        break;
      case '0x3f':
        return 'ean128';
        break;
      case '0x39':
        return 'itf';
        break;
      case '0x4c':
        return 'rss14';
        break;
      case '0x32':
        return 'upc_a';
        break;
      case '0x30':
        return 'upc_e';
        break;
      case '0x33':
        return 'msi';
        break;

      // 2D
      case '0x74':
        return 'aztec';
      case '0x49':
        return 'datamatrix';
      case '0x40':
        return 'pdf417';
      case '0x48':
        return 'maxicode';
      case '0x4e':
        return 'rss_expanded';
      case '0x45':
        return 'micropdf';
      case '0x4a':
        return 'qrcode';
      default:
        return undefined
    }
  }

}
