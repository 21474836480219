<div class="soft-card c row rounded center-v no-wrap space-between mt-3" *ngIf="auftrag as auftrag">
  <div class="c column center-v no-wrap text-content truncate">
    <span class="block text-sm soft-overline mb-0">{{ auftrag.number }}</span>
    <p class="block text-base font-semibold truncate mb-0">{{ auftrag.object.name }}</p>
    <p class="text-sm truncate mb-[3px]">
      <ng-container *ngIf="auftrag?.customer?.name1">{{ auftrag?.customer?.name1 }} </ng-container>
      <ng-container *ngIf="auftrag?.customer?.name2">{{ auftrag?.customer?.name2 }} </ng-container>
      <ng-container *ngIf="auftrag?.customer?.kurzbez && !auftrag?.customer?.name1 && !auftrag.customer?.name2">{{ auftrag?.customer?.kurzbez }}</ng-container>
    </p>
    <p class="body-list text-sm color-light truncate mt-0" *ngIf="auftrag?.customer?.strasse || auftrag?.customer?.strassenr">
      {{ auftrag.customer?.strasse }} {{ auftrag.customer?.strassenr }}
      <ng-container *ngIf="auftrag?.customer?.plz || auftrag?.customer?.ort">, {{ auftrag?.customer?.plz }} {{ auftrag?.customer?.ort }}</ng-container>
    </p>
  </div>
  <div class="disclosure-indicator">
    <i class="fa-regular fa-chevron-right text-gray-300"></i>
  </div>
</div>
