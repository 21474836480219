import {Component, Inject, OnInit, Optional, Type} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Store} from "@softline/core";
import {EditContextDialogComponent} from "../../../dialogs/edit-context-dialog/edit-context-dialog.component";
import {
  SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT,
  SOFTLINE_FEATURE_UTILITIES_FIRMA,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
  SOFTLINE_FEATURE_UTILITIES_VKFORGS
} from '../../../../utilities.shared'
import {map, switchMap} from "rxjs/operators";
import {AuthenticationContextStore, SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT} from "@softline/auth";
import {IsDefinedNotEmptyPipe, ModalOption, ModalStore, SOFTLINE_FEATURE_MODAL} from "@softline/ui-core";
import {BlockingLoadingSpinnerComponent, RefreshService} from "@softline/application";
import {VkforgStore} from '../../../vkforg.store';
import {combineLatest, Observable} from 'rxjs';
import {FirmaStore} from '../../../firma.store';
import {Vkforg} from '../../data/vkforg';
import {PatchContextStore} from '@softapps/allgemein/utils';
import {PatchContextService} from '../../../services/patch-context.service';

@Component({
  selector: 'soft-account-context-widget',
  standalone: true,
  imports: [CommonModule, BlockingLoadingSpinnerComponent, IsDefinedNotEmptyPipe],
  templateUrl: './vkforg-context-widget.component.html',
  styleUrls: ['./vkforg-context-widget.component.scss'],
})
export class VkforgContextWidgetComponent implements OnInit {

  readonly vkforgs$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_VKFORGS,
    VkforgStore.getters.all
  );

  readonly firmen$ = this.store.observe(
    SOFTLINE_FEATURE_UTILITIES_FIRMA,
    FirmaStore.getters.all
  );

  readonly context$ = this.store.observe(
    SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
    AuthenticationContextStore.getters.data
  ) as Observable<any>;

  readonly vkforg$ = combineLatest([this.vkforgs$, this.context$]).pipe(
    map(([o, context]) => o.find(v => v.id === context?.vkforgid) ?? o[0])
  );

  readonly firma$ = combineLatest([this.firmen$, this.vkforg$]).pipe(
    map(([firmen, vkforg]) => firmen.find(o => o?.id === vkforg?.idfirma))
  );

  readonly user$ = this.context$
    .pipe(
      switchMap(context => this.store.observe(
        SOFTLINE_FEATURE_AUTHENTICATION_CONTEXT,
        AuthenticationContextStore.getters.data,
        (context as any)
      ))
    );

  constructor(
    private store: Store,
    private refreshService: RefreshService,
    readonly patchContextService: PatchContextService,
    @Optional()
    @Inject(SOFTLINE_CONFIG_EDIT_CONTEXT_COMPONENT)
    public components?: Type<Component>[]
  ) {}

  async ngOnInit(): Promise<void> {
    await Promise.all([
      await this.store.dispatch(
        SOFTLINE_FEATURE_UTILITIES_VKFORGS,
        VkforgStore.actions.loadMany,
        { clear: true }
      ),
      await this.store.dispatch(
        SOFTLINE_FEATURE_UTILITIES_FIRMA,
        FirmaStore.actions.loadMany,
        { clear: true }
      )
    ]);
  }

  async onChangeAccount(vkforgs: Vkforg[]): Promise<void> {
    const options = vkforgs.map<ModalOption<string>>(o => ({
      value: o?.id + '',
      label: o?.kurzbezeichnung?.length < 1 ? o?.vkforg : (o?.kurzbezeichnung || o?.vkforg) ?? ''
    }));

    if (this.components && this.components?.length > 1) {
      options.push({
        value: 'FIRMENWECHSEL_OEFFNEN',
        label: '#UTILITIES.CONTEXT.DETAIL_CONTEXT_SETTINGS',
        icon: 'fa-regular fa-user-gear'
      })
    }

    await this.patchContextService.patchContextOptionsDialog<string>({
      title: 'Vkforg auswählen',
      options,
      editContextDialogOptionValue: 'FIRMENWECHSEL_OEFFNEN',
      setOnSelect: (selectedVkforgId) => {
        const vkforg = this.store.get(SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore.getters.entity, +selectedVkforgId);

        if (!vkforg)
          return null;

        return {
          vkforgid: +selectedVkforgId,
          firmaid: vkforg.idfirma
        }
      }
    });
  }
}
