<div class="soft-page-header -top-2 sticky"
     [ngClass]="{'!hidden': !header()?.template() && menu.menuItems().length === 0}">
  <header class="flex flex-row items-center flex-nowrap gap-4">
    <div class="w-full">
      <ng-container
        *ngTemplateOutlet="header()?.template() ?? null"
      ></ng-container>
    </div>
    <soft-menu-outlet name="responsive" class="!hidden md:!flex flex-row !w-auto pr-4 flex-nowrap justify-end items-center top-responsive-menu" #menu></soft-menu-outlet>
  </header>
</div>
<nav class="!hidden md:!flex !z-0 bg-surface">
  <soft-menu-outlet name="tab" class="top-tab-menu"></soft-menu-outlet>
</nav>
<main class="flex flex-col flex-grow mt-4 mb-4 px-4">
  @if (selectedTabTemplate(); as template) {
    <ng-container *ngTemplateOutlet="template"></ng-container>
  }
  <ng-content></ng-content>
</main>
<nav class="md:!hidden sticky bottom-0 bg-surface mt-4 soft-page-bottom">
  <soft-menu-outlet name="responsive" class="bottom-responsive-menu"></soft-menu-outlet>
  <soft-menu-outlet name="tab" class="bottom-tab-menu"></soft-menu-outlet>
</nav>
