import {ChangeDetectionStrategy, Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT} from '../../auftrag.shared';
import {WerkstattArbeitsberichtStore} from '../../store/werkstatt-arbeitsbericht.store'

@Component({
  selector: 'soft-edit-activity-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './end-activity-dialog.component.html',
  styleUrls: ['./end-activity-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EndActivityDialogComponent implements Modal<null> {

  close!: (result: null) => void

  constructor(private store: Store) {}

  registerCloseHandler(handler: (result: null) => void) {
    this.close = handler;
  }

  async stopActivity(): Promise<void> {
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
        WerkstattArbeitsberichtStore.actions.stopActive
      );

      console.log('RESULT: ', result)

      if (!result)
        return;

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        {
          title: `Die Tätigkeit '${result.activity.name} - ${result.activity.title}' wurde erfolgreich beendet`
        }
      );

      this.close(null);
    } catch (e) {
      console.error(e)
      await this.showErrorMessage();
    }
  }

  private async showErrorMessage(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        title: '#WORKSHOP.DIALOGS.ERROR_SUBJECT',
        message: '#WORKSHOP.DIALOGS.END_ACTIVITY.ERROR',
        container: 'END_ACTIVITY_MSG_BAR',
        duration: 10000,
        actions: [{
          title: '#WORKSHOP.DIALOGS.TRY_AGAIN',
          type: 'link',
          onExecute: async () => await this.stopActivity()
        }]
      }
    );
  }
}
