import {Component, input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Contact} from '../../types/contact'
import {UiCoreComponentsModule} from "@softline/ui-core";

@Component({
  selector: 'soft-contact-card',
  standalone: true,
  imports: [CommonModule, UiCoreComponentsModule],
  templateUrl: './contact-card.component.html',
  styleUrl: './contact-card.component.scss',
})
export class ContactCardComponent {
  contact = input<Contact>()
}
