import {
  ActionStore,
  createAction, createGetter,
  createMutation,
  KeyStore,
  mutate,
  on, select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { Umlagerung } from "../types/umlagerung";
import { lastValueFrom } from "rxjs";
import { PalettenUmlagerungService } from "../services/paletten-umlagerung.service";
import { LagerplatzInhalt } from "@softapps/lager/core";

export interface State extends ActionStore.State, SubscriptionStore.State, KeyStore.State  {
  palette?: LagerplatzInhalt;
};

export class PalettenUmlagerungStore {
  static mutations = {
    setPalette: createMutation<State, LagerplatzInhalt>('umlagerungPalette setPalette'),
    reset: createMutation<State>('umlagerungPalette reset'),
  };
  static getters = {
    palette: createGetter<State, LagerplatzInhalt>('umlagerungPalette palette')
  };
  static actions = {
    loadPalette: createAction<State, string, LagerplatzInhalt>('umlagerungPalette loadPalette'),
    umlagern: createAction<State, Umlagerung>('umlagerungPalette umlagern')
  };

  static feature: StoreFeature<State> = {
    initialState: {
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...SubscriptionStore.feature.initialState
    },
    mutations: [
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      mutate(PalettenUmlagerungStore.mutations.setPalette,
        ({state, params}) => ({...state, palette: params})
      ),
      mutate(PalettenUmlagerungStore.mutations.reset,
        ({state, params}) => ({...state, palette: undefined, zielLagerplatz: undefined})
      )
    ],
    getters: [
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(PalettenUmlagerungStore.getters.palette, ({state}) => state.palette),
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(PalettenUmlagerungStore.actions.loadPalette, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(PalettenUmlagerungService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getPalette(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            PalettenUmlagerungStore.actions.loadPalette.name,
            token
          )
        );
        commit(featureName, PalettenUmlagerungStore.mutations.setPalette, result);
        return result;
      }),
      on(PalettenUmlagerungStore.actions.umlagern, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(PalettenUmlagerungService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.umlagerung(params.artikelMenge, params.quellLagerplatz, params.zielLagerplatz),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            PalettenUmlagerungStore.actions.umlagern.name,
            token
          )
        );
        commit(featureName, PalettenUmlagerungStore.mutations.reset);
        return result;
      })
    ]
  }
}
