import {Injectable} from '@angular/core';
import {
  ConnectionHttpService,
  ConnectionResourceLocation,
  RequestEvent,
  ResponseRequestEvent,
  ResponseResult
} from '@softline/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {
  SOFTLINE_API_WERKSTAETTENAUFTRAG_FILE_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_SIGN_TASK_RESOURCE_PATH,
} from '../auftrag.api';
import {map, toArray} from 'rxjs/operators';
import {Auftrag} from '../data/auftrag';

@Injectable()
export class SignatureService {
  constructor(
    private service: ConnectionHttpService,
    private sanitizer: DomSanitizer
  ) {}

  getSignatureImage(encodedSignature: string): Observable<Blob> {
    const location: ConnectionResourceLocation = {
      path: `${SOFTLINE_API_WERKSTAETTENAUFTRAG_FILE_RESOURCE_PATH}/${encodedSignature}`,
    };

    return this.service.download(location).pipe(
      map(o => o as ResponseRequestEvent<Blob>),
      toArray(),
      map((response: ResponseRequestEvent<Blob>[]) => {
        const responseEvent = response[response?.length - 1] ?? undefined;
        // const objectURL = URL.createObjectURL(responseEvent?.response ?? new Blob());
        // return this.sanitizer.bypassSecurityTrustUrl(objectURL) ?? '';
        return responseEvent?.response ?? new Blob();
      })
    );
  }

  sign(id: string, entity: { files: Blob[] }): Observable<unknown> {
    return this.service.upload(
      { path: `${SOFTLINE_API_WERKSTAETTENAUFTRAG_SIGN_TASK_RESOURCE_PATH}/${id}/sign` },
      { ...entity, filename: 'signature.png' }
    ).pipe(
      toArray(),
      map(res => {
        const event = res.find((o: RequestEvent<any>) => o.type === 'response') as ResponseRequestEvent<ResponseResult<Auftrag>>;
        return event.response?.result?.signature ?? undefined
      })
    );
  }
}
