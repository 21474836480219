import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent
} from "@softline/dynamic";
import { ArtunteruntergrpListView } from "./list-view/artunteruntergrp.list-view";

export const artunteruntergrpFieldOk: FieldOkConfig = {
  name: 'artunteruntergrp',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtunteruntergrpListView,
      sort: {
        properties: [
          {property: 'id', title: 'Erfassungszeitpunkt'},
          {property: 'bezeichnung', title: 'Bezeichung'},
        ]
      }
    }
  }
  ]
};
export const artunteruntergrpMultiselectFieldOk: FieldOkConfig = {
  name: 'artunteruntergrp',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: FieldOkMultiselectListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtunteruntergrpListView,
      sort: {
        properties: [
          {property: 'id', title: 'Erfassungszeitpunkt'},
          {property: 'bezeichnung', title: 'Bezeichung'},
        ]
      }
    }
  }
  ]
};

export const artunteruntergrpQueryFieldOk: FieldOkConfig = {
  name: 'artunteruntergrp',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{bezeichnung}}',
  autoQuery: true,
  views: [{
    name: 'list',
    component: QueryFieldOkListViewComponent,
    title: 'Auswahl',
    config: {
      component: ArtunteruntergrpListView,
      sort: {
        properties: [
          {property: 'id', title: 'Erfassungszeitpunkt'},
          {property: 'bezeichnung', title: 'Bezeichung'},
        ]
      }
    }
  }
  ]
};
