import { requestErrorMessageFactory } from '@softline/application';
import { inject, Injector } from '@angular/core';
import { TranslationStore2 } from '@softline/ui-core';
import { interpolate, Store, UnauthorizedError } from '@softline/core';
import { JwtAuthenticationStore, SOFTLINE_FEATURE_JWT_AUTHENTICATION } from '@softline/auth';

export function handleRequestError(error: unknown, injector: Injector): void {
  const message = requestErrorMessageFactory(error);

  const translator = injector.get(TranslationStore2);
  const subject = message.subject ? interpolate(translator.translate(message.subject)(), message.data as any) : null;
  const text = interpolate(translator.translate(message.message)(), message.data as any);

  switch (message.type) {
    case 'critical':
      console.error('Softapps Critical error:', subject ?? 'Critical error', '\n', text, '\n', error);
      throw error;
    case 'error':
      console.error('Softapps Error:', subject ?? 'Error', '\n', text, '\n\n', error);
      break;
    case 'warning':
      console.warn('Softapps Warning:', subject ?? 'Warning', '\n', text, '\n\n', error);
      break;
    case 'info':
      console.info('Softapps Info:', subject ?? 'Info', '\n', text, '\n\n', error);
      break;
    case 'success':
      console.debug('Softapps Success:', subject ?? 'Success', '\n', text, '\n\n', error);
      break;
    default:
      console.error('Softapps', subject ?? 'Unknown Error', message, '\n', text, '\n\n', error);
      throw error;
  }

  if (error instanceof UnauthorizedError) {
    const store = injector.get(Store);
    store.dispatch(
      SOFTLINE_FEATURE_JWT_AUTHENTICATION,
      JwtAuthenticationStore.actions.logout,
      { expired: true }
    );
  }
}
