import { Component, computed, inject, input, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_MESSAGE_BAR } from '../message-bar.shared';
import * as MessageBarStore from '../message-bar.store';
import { MessageBarConfig } from '../data/message-bar.config';
import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { MessageBarService } from '../message-bar.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'soft-message-bar-container',
  templateUrl: './message-bar-container.component.html',
  styleUrls: ['./message-bar-container.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10rem)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)'})),
        animateChild({}),
      ]),
      transition(':leave', [

        // animate('200ms ease-in', style({  opacity: 0, transform: 'translateY(10rem)' })),
        query('@*', animateChild()),
      ])
    ])
  ]
})
export class MessageBarContainerComponent implements OnInit {
  service = inject(MessageBarService);

  name = input<string | undefined>()
  private storeMessageBars = toSignal(
    this.store.observe(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.getters.items)
  , {initialValue: []})

  messageBars = computed(() => {
    const messages = [...this.service.messageBars(), ...this.storeMessageBars()];
    const name = this.name();

    const filteredMessages: MessageBarConfig[] = []
    const traverse = [...messages];
    while (traverse.length > 0) {
      const current = traverse.pop();
      if(!current || current.container !== name)
        continue;
      if(current.group === undefined || filteredMessages.findIndex(o => o.group === current.group) === -1)
        filteredMessages.push(current)
    }
    return filteredMessages.reverse();
  })

  constructor(readonly store: Store) {}

  ngOnInit(): void {}

  onDismiss(config: MessageBarConfig): void {
    this.service.close(config.id ?? '');
  }
}
