import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import { StoreModule } from "@softline/core";
import { SOFTLINE_FEATURE_INVENTUR, SOFTLINE_PERMISSION_INVENTUR } from "./inventur.shared";
import { InventurStore } from "./store";
import { InventurService } from "./services/inventur.service";

export const inventurRoutes: Routes = [
  {
    path: 'inventur',
    children: [
      { path: 'mengen-auswahl', loadComponent: () => import('./pages/mengen-auswahl/mengen-auswahl.page').then(o => o.MengenAuswahlPage)},
      { path: 'artikel-auswahl', loadComponent: () => import('./pages/artikel-auswahl/artikel-auswahl.page').then(o => o.ArtikelAuswahlPage)},
      { path: '', loadComponent: () => import('./pages/lagerplatz-scannen/lagerplatz-scannen.page').then(o => o.LagerplatzScannenPage) },
      { path: '**', redirectTo: '/inventur' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_INVENTUR},
    providers: [
      InventurService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_INVENTUR,
          feature: InventurStore.feature,
        }),
      ),
    ]
  },
];
