import {
  SOFTLINE_SERVICE_HTTP, SOFTLINE_STORE_ENTITY_SERVICE,
  SOFTLINE_STORE_REMOTE_OBJECT_SERVICE, SoftlineEntityService,
  SoftlineObjectService, Store,
  StoreModule
} from "@softline/core";
import { ENVIRONMENT_INITIALIZER, importProvidersFrom, inject } from "@angular/core";
import { SOFTLINE_FEATURE_LAGERPLATZ_SUCHE, SOFTLINE_FEATURE_LAGERSYMBOL } from "./lager-core.shared";
import { LagerplatzSucheStore } from "./store/lagerplatz-suche.store";
import { SOFTLINE_API_LAGERPLATZ, SOFTLINE_API_LAGERSYMBOL } from "./lager-core.api";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application";
import { default as de } from "../i18n/de.json";
import { LagersymbolStore } from "./store/lagersymbol.store";

export const lagerCoreProviders = [
  importProvidersFrom(
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_LAGERPLATZ_SUCHE,
      feature: LagerplatzSucheStore.feature,
      providers: [{
        provide: SOFTLINE_STORE_REMOTE_OBJECT_SERVICE,
        useFactory: (service) => new SoftlineObjectService(service, SOFTLINE_API_LAGERPLATZ),
        deps: [SOFTLINE_SERVICE_HTTP]
      }]
    }),
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_LAGERSYMBOL,
      feature: LagersymbolStore.feature,
      providers: [
        {
          provide: SOFTLINE_STORE_ENTITY_SERVICE,
          useFactory: (service) => new SoftlineEntityService(service, SOFTLINE_API_LAGERSYMBOL),
          deps: [SOFTLINE_SERVICE_HTTP]
        }
      ]
    })
  ),
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'lager-core',
        language: 'de',
        translations: de
      });
    },
    multi: true
  }
]
