import { KommmissionierungStrategy } from "./kommmissionierung.strategy";
import { ArtikelMenge, LagerplatzInhalt } from "@softapps/lager/core";
import { KommissionsauftragKopf } from "../types/kommissionsauftrag";

export class KommscheinStrategy implements KommmissionierungStrategy {

  loadLagerplatzVorschlag = true;
  begleitscheindruck = true;

  constructor(public lagerplatzAuswahl: boolean) {
  }

  getMaxMenge(lagerplatz: LagerplatzInhalt | undefined, artikelMenge: ArtikelMenge | undefined): number {
    if(!lagerplatz || !artikelMenge)
      return 0;
    const lagerplatzMenge = lagerplatz.inhalt.find(o => o.artikel.id === (artikelMenge.artikel?.id ?? -1))?.menge ?? 0;
    const beweMenge = artikelMenge.menge ?? 0;
    return beweMenge < lagerplatzMenge ? beweMenge : lagerplatzMenge;
  }

  getPalettenBegleitscheinMenge(auftrag: KommissionsauftragKopf): number {
    switch (auftrag.lieferart) {
      case "A":
        return 0;
      default:
        return 1;
    }
  }
}
