import { StaticProvider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestLogInterceptor } from './request-log.interceptor';
import { SOFTLINE_STORE_FEATURES } from '@softline/core';
import { RequestLogStore } from './request-log.store';

export const requestLogProviders: any[] = [
  {provide: SOFTLINE_STORE_FEATURES, useExisting: RequestLogStore, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: RequestLogInterceptor, multi: true}
];
