<div class="pdf-editor-tools">
  <div
    class="pdf-editor-tools-group"
    *ngFor="let toolGroup of toolGroups$ | async"
  >
    <div class="pdf-editor-tools-group-header">
      <h4 class="pdf-editor-tools-group-title">{{ toolGroup.title }}</h4>
      <div *ngIf="toolGroup.allowCustomTool">
        <button
          type="button"
          title="Hinzufügen"
          (click)="addToolInGroup(toolGroup)"
        >
          <span class="fa-regular fa-plus"></span>
        </button>
      </div>
    </div>

    <div class="pdf-editor-tools-group-content">
      <div
        class="pdf-editor-tool-container"
        *ngIf="toolGroup.tools && toolGroup.tools.length; else noToolsTemplate"
      >
        <button
          type="button"
          class="pdf-editor-tool"
          *ngFor="let tool of toolGroup.tools"
          [ngClass]="{ active: (selectedTool$ | async) === tool }"
          (click)="selectTool(tool)"
        >
          <div class="pdf-editor-tool-image" *ngIf="tool.type === 'IMAGE'">
            <img [class]="tool.icon" [src]="tool.url" [alt]="tool.type" />
          </div>
          <div class="pdf-editor-tool-icon" *ngIf="tool.type !== 'IMAGE'">
            <span [class]="tool.icon"></span>
          </div>
          <div class="pdf-editor-tool-title">{{ tool.title }}</div>
        </button>
      </div>
      <ng-template #noToolsTemplate>
        <p class="text-sm text-center">Es sind keine Werkzeuge in dieser Kategorie vorhanden.</p>
      </ng-template>
    </div>

    <div class="pdf-editor-tools-group-footer">
      <ng-container
        *ngIf="(selectedToolGroup$ | async)?.name === toolGroup.name"
      >
        <div
          class="pdf-editor-tool-controls"
          *ngIf="(selectedTool$ | async)?.isCustom"
        >
          <button
            type="button"
            class="pdf-editor-tool-control text-danger"
            (click)="deleteSelectedCustomTool()"
          >
            <span class="fa-regular fa-trash"></span>
            Delete
          </button>
        </div>
        <soft-pdf-editor-tool-customization
          *ngIf="(selectedTool$ | async)?.customizations"
        ></soft-pdf-editor-tool-customization>
      </ng-container>
    </div>
  </div>
</div>
