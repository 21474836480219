@if(supplierPrice(); as supplierPrice) {
  <div class="flex flex-col">
    <div class="flex flex-row justify-between flex-wrap-reverse">
      <div class="flex flex-col">
        <span class="text-sm text-primary font-bold uppercase text-nowrap">{{supplierPrice.distributionChannel.key}}</span>
        <span class="font-semibold text-lg text-nowrap">{{supplierPrice.distributionChannel.name}}</span>
      </div>
      <div class="flex flex-col items-end">
        <span class="font-semibold text-xs text-nowrap">Gültig von: {{supplierPrice.validFrom | format: 'DATE.DEFAULT'}}</span>
        @if(supplierPrice.validTo) {
          <span class="font-semibold text-xs text-nowrap">bis: {{supplierPrice.validTo | format: 'DATE.DEFAULT'}}</span>
        }
      </div>
    </div>
    <soft-price-card class="p-2" [price]="supplierPrice.prices" [unit]="supplierPrice.unit" [currency]="supplierPrice.currency"></soft-price-card>
    @if(supplierPrice.supplierConditions; as conditions) {
      <div class="flex flex-col w-full text-info">
        <div class="flex flex-row w-full justify-between">
          <div class="font-semibold text-sm">
            <soft-icon name="fa-regular fa-circle-info" class="!inline"></soft-icon>
            {{conditions.key}}
          </div>
          <div class="text-sm">
            {{conditions.terms}}
          </div>
        </div>
        <div class="text-xs">
          {{conditions.name}}
        </div>
      </div>
    }
  </div>
}
