import { Component, InjectionToken } from '@angular/core';
import { AppInfo } from './app/data/app-info';
import { Settings } from "./data/settings";

export const SOFTLINE_CONST_SETTINGS_GROUP_INTERNAL = 'internal';

export const SOFTLINE_CONFIG_APP_INFO = new InjectionToken<AppInfo>(
  'SOFTLINE_CONFIG_APP_INFO'
);
export const SOFTLINE_CONFIG_SETTINGS = new InjectionToken<Settings>('SOFTLINE_SETTINGS');
export const SOFTLINE_CONFIG_DASHBOARD_COMPONENT = new InjectionToken(
  'SOFTLINE_CONFIG_DASHBOARD_COMPONENT'
);
export const SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME = new InjectionToken<number>('SOFTLINE_CONFIG_DEFAULT_DEBOUNCE_TIME')

export const SOFTLINE_FEATURE_SETTINGS = 'settings';
export const SOFTLINE_FEATURE_SHELL = 'shell';
export const SOFTLINE_FEATURE_APPLICATIONS = 'applications';
export const SOFTLINE_FEATURE_APP_INFO = 'appInfo';
export const SOFTLINE_FEATURE_MODULE = 'modules';
export const SOFTLINE_FEATURE_ROUTE = 'route';
export const SOFTLINE_FEATURE_PRINTER = 'printer';
export const SOFTLINE_FEATURE_ARCHIVE = 'archive';
export const SOFTLINE_FEATURE_MASTER_DATA = 'masterData';
export const SOFTLINE_FEATURE_SCANNER = 'scanner';
export const SOFTLINE_FEATURE_TITLE = 'title';
export const SOFTLINE_FEATURE_COMMANDS = 'commands';
export const SOFTLINE_FEATURE_BACK_NAVIGATION = 'backNavigation';
export const SOFTLINE_FEATURE_NOTIFICATION = 'notification';

export const SOFTLINE_WIDGET_SIDE_MENU_USER_INFO = 'sideMenuUserInfo';

