import {
  AfterContentInit,
  Component,
  ComponentFactoryResolver,
  HostListener, inject,
  Injector, input,
  Input,
  output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Modal } from '../../data/modal';
import { ModalConfig } from '../../data/modal-config';
import { ModalService } from '../../modal.service';
import { boolean } from 'mathjs';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_MODAL } from '../../modal.shared';
import * as ModalStore from '../../modal.store'

@Component({
  selector: 'soft-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterContentInit {
  private contentInit = false;
  private _config!: ModalConfig<Modal<unknown>, unknown>;

  private modalService = inject(ModalService);
  //TODO: Remove when ModalStore is removed
  private store = inject(Store);

  @ViewChild('container', { static: true, read: ViewContainerRef })
  container!: ViewContainerRef;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent): void {
    this.dismiss(this.config.dismiss === true || (this.config.dismiss && this.config.dismiss.escape), event);
  }

  get config(): ModalConfig<Modal<unknown>, unknown, object> {
    return this._config;
  }
  @Input() set config(value: ModalConfig<Modal<unknown>, unknown, object>) {
    this._config = value;
    if(this.contentInit)
      this.reloadContent(value);
  }
  active = input<boolean>(false)

  constructor(
    private resolver: ComponentFactoryResolver
  ) {}

  ngAfterContentInit(): void {
    this.reloadContent(this._config);
    this.contentInit = true;
  }

  close(result: unknown): void {
    if (this.config.callback)
      this.config.callback(result);

    if(this.modalService.configs().some((c) => c.id === this.config.id)) {
      this.modalService.close(this.config.id);
    }
    else {
      //TODO: Remove when ModalStore is removed
      this.store.commit(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.mutations.remove,
        this._config
      );
    }
  }

  dismiss(dismissible: boolean | undefined, event?: KeyboardEvent): void {
    if (!this.config.dismiss || !dismissible || !this.active())
      return;
    if (this.config.onDismiss)
      this.config.onDismiss();

    if(this.modalService.configs().some((c) => c.id === this.config.id)) {
      this.modalService.close(this.config.id);
    }
    else {
      //TODO: Remove when ModalStore is removed
      this.store.commit(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.mutations.remove,
        this._config
      );
    }

    if(event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  reloadContent(config: ModalConfig<Modal<unknown>, unknown, object>): void {
    if (!this.container) return;
    let resolver = this.resolver;
    let injector: Injector | undefined;
    if (config.getInjector) {
      injector = config.getInjector();
      resolver = injector.get(ComponentFactoryResolver);
    }

    this.container.clear();
    const factory = resolver.resolveComponentFactory(config.component);
    const componentRef = this.container.createComponent(
      factory,
      undefined,
      injector
    );
    const instance = componentRef.instance;
    instance.registerCloseHandler((result) => {
      this.close(result);
    });

    if (config.data) {
      for (const [key, value] of Object.entries(config.data))
        (instance as any)[key] = value;
    }
  }
}
