import { Injectable } from "@angular/core";
import { Store } from "@softline/core";
import { Subscription } from "rxjs";

declare var EB: any;

@Injectable()
export class EbBatteryService {
  private subscription?: Subscription;
  constructor(private store: Store) {
  }

  async init(): Promise<void> {
    if(!(window as any).EBWeb)
      return;

    EB.Battery.batteryStatus(
      {trigger: EB.Battery.BATTERY_TRIGGER_SYSTEM},
      (data: any) => {
        if(data.batteryLifePercent < 20) {
          EB.Battery.showIcon({
            layout: EB.Battery.BATTERY_LAYOUT_LEFT,
            left: window.outerWidth,
            top: 10,
            color: '#FF8000'
          });
        }
        else if(data.batteryLifePercent < 10) {
          EB.Battery.showIcon({
            layout: EB.Battery.BATTERY_LAYOUT_LEFT,
            left: window.outerWidth,
            top: 10,
            color: '#FF4000'
          });
        }
        else if(data.batteryLifePercent < 5) {
          EB.Battery.showIcon({
            layout: EB.Battery.BATTERY_LAYOUT_LEFT,
            left: window.outerWidth,
            top: 10,
            color: '#FF0000'
          });
        }
        else {
          EB.Battery.hideIcon();
        }
      }
    )
  }
}
