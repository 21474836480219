import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  KeyStore,
  mutate,
  on,
  select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { Umlagerung } from "../types/umlagerung";
import { lastValueFrom } from "rxjs";
import { LagerplatzUmlagerungService } from "../services/lagerplatz-umlagerung.service";
import { ArtikelMenge, LagerplatzInhalt } from "@softapps/lager/core";
import {
  PalettenUmlagerungService
} from "../../../../paletten-umlagerung/src/lib/services/paletten-umlagerung.service";

export interface State extends ActionStore.State, SubscriptionStore.State, KeyStore.State  {
  quellLagerplatz?: LagerplatzInhalt;
  artikel?: ArtikelMenge;
};

export class LagerplatzUmlagerungStore {
  static mutations = {
    setQuellagerplatz: createMutation<State, LagerplatzInhalt>('umlagerungLagerplatz setQuellagerplatz'),
    setArtikel: createMutation<State, ArtikelMenge>('umlagerungLagerplatz setArtikel'),
    reset: createMutation<State>('umlagerungLagerplatz reset'),
  };
  static getters = {
    quellLagerplatz: createGetter<State, LagerplatzInhalt>('umlagerungLagerplatz quellLagerplatz'),
    artikel: createGetter<State, ArtikelMenge>('umlagerungLagerplatz artikel'),
  };
  static actions = {
    loadQuellLagerplatz: createAction<State, string, LagerplatzInhalt>('umlagerungLagerplatz loadQuellLagerplatz'),
    umlagern: createAction<State, Umlagerung>('umlagerungLagerplatz umlagern'),
    loadPalette: createAction<State, string, LagerplatzInhalt>('umlagerungLagerplatz loadPalette'),
  };

  static feature: StoreFeature<State> = {
    initialState: {
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...SubscriptionStore.feature.initialState
    },
    mutations: [
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      mutate(LagerplatzUmlagerungStore.mutations.setQuellagerplatz,
        ({state, params}) => ({...state, quellLagerplatz: params})
      ),
      mutate(LagerplatzUmlagerungStore.mutations.setArtikel,
        ({state, params}) => ({...state, artikel: params})
      ),
      mutate(LagerplatzUmlagerungStore.mutations.reset,
        ({state, params}) => ({...state, quellLagerplatz: undefined, artikel: undefined, zielLagerplatz: undefined})
      )
    ],
    getters: [
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(LagerplatzUmlagerungStore.getters.quellLagerplatz, ({state}) => state.quellLagerplatz),
      select(LagerplatzUmlagerungStore.getters.artikel, ({state}) => state.artikel),
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(LagerplatzUmlagerungStore.actions.loadQuellLagerplatz, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerplatzUmlagerungService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getQuelllagerplatz(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerplatzUmlagerungStore.actions.loadQuellLagerplatz.name,
            token
          )
        );
        commit(featureName, LagerplatzUmlagerungStore.mutations.setQuellagerplatz, result);
        return result;
      }),
      on(LagerplatzUmlagerungStore.actions.loadPalette, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerplatzUmlagerungService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getPalette(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerplatzUmlagerungStore.actions.loadPalette.name,
            token
          )
        );

        if(result.inhalt.length === 0)
          throw new Error('#LAGERPLATZ_UMLAGERUNG.MESSAGES.ERROR.KEINE_ARTIKEL');
        else if (result.inhalt.length > 1)
          throw new Error('#LAGERPLATZ_UMLAGERUNG.MESSAGES.ERROR.ZU_VIELE_ARTIKEL');
        const palettenInhalt = result.inhalt[0];

        let lagerplatzInhalt = state.quellLagerplatz?.inhalt
          .find(o => o.artikel.id === palettenInhalt.artikel.id
                  && o.einheit_lg.id === palettenInhalt.einheit_lg.id);

        if(!lagerplatzInhalt)
          throw new Error('#LAGERPLATZ_UMLAGERUNG.MESSAGES.ERROR.ARTIKEL_NICHT_VORHANDEN');

        lagerplatzInhalt = {...lagerplatzInhalt, menge: palettenInhalt.menge_lg};

        commit(featureName, LagerplatzUmlagerungStore.mutations.setArtikel, lagerplatzInhalt);
        return result;
      }),
      on(LagerplatzUmlagerungStore.actions.umlagern, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerplatzUmlagerungService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.umlagerung(params.artikelMenge, params.quellLagerplatz, params.zielLagerplatz),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerplatzUmlagerungStore.actions.umlagern.name,
            token
          )
        );
        commit(featureName, LagerplatzUmlagerungStore.mutations.reset, result);
        return result;
      })
    ]
  }
}
