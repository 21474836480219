<div class="flex flex-row max-h-inherit flex-grow">
  <div class="w-full sm:w-1/3 flex-grow sm:border-r max-h-inherit overflow-auto"
       [ngClass]="{
            'hidden sm:block': !!selected(),
          }"
       (touchstart)="preventScrolling($event, master)" #master>
    <ng-content select=".soft-master-view-title"></ng-content>
    <ul>
      @for (value of data(); track value; let i = $index) {
        <li
          class="flex flex-row justify-between pointer hover:bg-hover items-center gap-2 px-2"
          (click)="select(i)"
          (dblclick)="masterDblClick.emit(value)"
          [ngClass]="{
            '!bg-selected': value === selected(),
          }"
        >
          <ng-container *ngTemplateOutlet="masterTemplate(); context: { data: value }"></ng-container>
          <soft-icon [name]="'fa-regular fa-chevron-right'"></soft-icon>
        </li>
      }
    </ul>
  </div>
  <div class="flex flex-col flex-grow w-full sm:w-2/3 sm:px-2 max-h-inherit overflow-auto"
       [ngClass]="{
            'hidden sm:block': !selected()
          }"
       (touchstart)="preventScrolling($event, detail)" #detail>
    @if(selected(); as selected) {
      @if(detailHeaderVisible()) {
        <div class="flex flex-row items-center sm:hidden justify-between w-full gap-2 px-2 pointer hover:bg-hover"
                    (click)="select(-1)">
          <soft-icon [name]="'fa-regular fa-chevron-left'"></soft-icon>
          <ng-container *ngTemplateOutlet="masterTemplate(); context: { data: selected }"></ng-container>
        </div>
        <div class="w-full border-b my-2 sm:hidden"></div>
      }
      <ng-container *ngTemplateOutlet="detailTemplate(); context: { data: selected }"></ng-container>
    }
  </div>
</div>
