@if(promotion(); as promotion) {
  <div class="flex flex-col">
    <div class="flex flex-row justify-between flex-wrap-reverse">
      <div class="flex flex-col">
        <span class="text-sm text-primary font-bold uppercase text-nowrap">{{promotion.key}}</span>
        <span class="font-semibold text-lg text-nowrap">{{promotion.name}}</span>
      </div>
      <div class="flex flex-col items-end">
        <span class="font-semibold text-xs text-nowrap">Gültig von: {{promotion.validFrom | format: 'DATE.DEFAULT'}}</span>
        <span class="font-semibold text-xs text-nowrap">bis: {{promotion.validTo | format: 'DATE.DEFAULT'}}</span>
      </div>
    </div>
    <soft-price-card class="p-2" [price]="promotion.prices" [unit]="promotion.unit" [promotion]="true"></soft-price-card>
  </div>
}
