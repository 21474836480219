import { ENVIRONMENT_INITIALIZER, ImportedNgModuleProviders, inject, Provider } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import {
  ApplicationStore,
  SOFTLINE_CONFIG_OFFLINE_FEATURE,
  SOFTLINE_FEATURE_APPLICATIONS
} from "@softline/application";
import { default as de } from "../i18n/de.json";
import { SOFTLINE_FEATURE_KOMMISSIONIERUNG, SOFTLINE_PERMISSION_KOMMISSIONIERUNG } from "./kommissionierung.shared";

export const kommissionierungProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'kommissionierung',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#KOMMISSIONIERUNG.APP',
        description: '#KOMMISSIONIERUNG.APP_DESC',
        route: '/kommissionierung',
        icon: 'fa-regular fa-cart-flatbed-boxes',
        permission: SOFTLINE_PERMISSION_KOMMISSIONIERUNG
      });
    },
    multi: true
  },
  {
    provide: SOFTLINE_CONFIG_OFFLINE_FEATURE,
    useValue: {featureName: SOFTLINE_FEATURE_KOMMISSIONIERUNG, fields: ['query']},
    multi: true
  },
];
