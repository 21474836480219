import {ArchiveFile, Command, showRequestErrors, UploadFileCommand} from '@softline/application';
import {DownloadStore, saveAs, SOFTLINE_FEATURE_DOWNLOAD, Store} from '@softline/core';
import {Router} from '@angular/router';
import {Injector} from '@angular/core';
import {Auftrag} from '../../data/auftrag';
import {Arbeitsbericht} from '../../data/stempel';
import {AddTaskItemCommand} from '../../commands/add-task-item.command';
import {EndActivityCommand} from '../../commands/end-activity.command';
import {SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_ERSTELLEN} from '../../auftrag.permissions';
import {ManualTimeReportCommand} from '../../commands/manual-time-report.command';
import {of} from 'rxjs';
import {EditHeaderTextCommand} from '../../commands/edit-header.command';
import {
  SOFTLINE_API_WERKSTAETTENAUFTRAG_ABRECHNUNGSVORSCHAU,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_ARCHIVE_KEY_RESOURCE_PATH
} from '../../auftrag.api';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE} from '../../auftrag.shared';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store'
import {StartActivityCommand} from '../../commands/start-activity.command';
import {SignatureCommand} from '../../commands/signature.command';
import {CloseTaskCommand} from '../../commands/close-task.command';
import {MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR} from '@softline/ui-core';

export function createAuftragCommands({
  store,
  router,
  injector,
  getTask,
  permission,
  timeReport
}: {
  store: Store,
  router: Router,
  injector: Injector,
  getTask: () => Auftrag,
  permission: string[],
  timeReport: Arbeitsbericht | undefined
}): Command[] {
  const commands: Command[] = [
    new AddTaskItemCommand(router, getTask),
    new StartActivityCommand(store, getTask),
    new EndActivityCommand(store, timeReport, getTask)
  ];

  if (permission.indexOf(SOFTLINE_PERMISSION_WERKSTAETTENAUFTRAG_ARBEITSBERICHT_ERSTELLEN) > -1) {
    commands.push(new ManualTimeReportCommand(router, getTask));
  }

  return [
    ...commands,
    new UploadFileCommand(
      store,
      {
        canExecute: of(true),
        sources: ['camera', 'cameraRoll'],
        archiveKey: getTask()?.archiveKey ?? {
          path: SOFTLINE_API_WERKSTAETTENAUFTRAG_ARCHIVE_KEY_RESOURCE_PATH,
          pathParams: {id: getTask()?.id}
        },
        onSuccess: async (files: ArchiveFile[]) => {
          store.commit(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
            WerkstattAuftraegeStore.mutations.patch,
             { id: getTask().id, changes: { archiveKey: (files[0] as any).result?.archiveKey } }
          );

          await store.dispatch(
            SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
            WerkstattAuftraegeStore.actions.load,
            { id: getTask().id}
          )
        }
      }
    ),
    {
      name: 'Abrechnungsvorschau',
      icon: 'fa-regular fa-file-invoice-dollar',
      class: 'menu action-menu action-menu-top',
      execute: async () => {
        let messageBarId;

        const timeout = setTimeout(async () =>
          messageBarId = await store.dispatch(
            SOFTLINE_FEATURE_MESSAGE_BAR,
            MessageBarStore.actions.loading,
          ), 1000);

        try {
          const result = await store.dispatch(
            SOFTLINE_FEATURE_DOWNLOAD,
            DownloadStore.actions.download,
            {
              filename: `AU${getTask().number}_Abrechnungsvorschau.pdf`,
              location: {
                path: SOFTLINE_API_WERKSTAETTENAUFTRAG_ABRECHNUNGSVORSCHAU,
                pathParams: {
                  id: getTask().id
                }
              }
            }
          );

          clearTimeout(timeout);

          if (messageBarId)
            await store.dispatch(
              SOFTLINE_FEATURE_MESSAGE_BAR,
              MessageBarStore.actions.close,
              messageBarId
            );

          saveAs(result.content, result.filename);
        } catch (e) {
          clearTimeout(timeout);

          if (messageBarId)
            await store.dispatch(
              SOFTLINE_FEATURE_MESSAGE_BAR,
              MessageBarStore.actions.close,
              messageBarId
            );

          showRequestErrors(store, e)
        }
      }
    },
    new SignatureCommand(store, getTask),
    new CloseTaskCommand(store, getTask, router),
    new EditHeaderTextCommand(store, getTask)
  ];
}
