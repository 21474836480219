import { Component, OnInit } from '@angular/core';
import { Store } from '@softline/core';
import { SOFTLINE_FEATURE_ZEBRA } from '../zebra.shared';
import { ZebraStore } from '../zebra.store';
import { Observable } from 'rxjs';
import { SOFTLINE_FEATURE_MESSAGE_BAR } from '@softline/ui-core';
import { MessageBarStore } from '@softline/ui-core';

@Component({
  selector: 'lib-zebra-scanner-settings',
  templateUrl: './zebra-scanner-settings.component.html',
  styleUrls: ['./zebra-scanner-settings.component.scss'],
})
export class ZebraScannerSettingsComponent implements OnInit {
  resetting = false;
  state$: Observable<ZebraStore.State> = this.store.observe<ZebraStore.State>(
    SOFTLINE_FEATURE_ZEBRA
  );

  constructor(private store: Store) {}

  ngOnInit(): void {}

  async onResetClick(): Promise<void> {
    try {
      this.resetting = true;
      const profile = this.store.get<ZebraStore.State>(
        SOFTLINE_FEATURE_ZEBRA
      ).activeProfile;
      await this.store.dispatch(
        SOFTLINE_FEATURE_ZEBRA,
        ZebraStore.actions.deleteProfile,
        profile
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_ZEBRA,
        ZebraStore.actions.createProfile,
        profile
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_ZEBRA,
        ZebraStore.actions.assignApp,
        profile
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        'Das Profil wurde zurückgesetzt'
      );
    } catch (e) {
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.error,
        'Das Profil konnte nicht zurückgesetzt werden'
      );
    } finally {
      this.resetting = false;
    }
  }
}
