import { lastValueFrom } from 'rxjs';
import { computed, Injectable, InjectionToken } from '@angular/core';
import { CancelledError } from '../../../types/errors';
import { SavingState } from '../../store2.shared';
import { SaveListParameters } from '../../../data2/abstraction';
import {
  ReadonlyRepositoryDictionaryState,
  ReadonlyRepositoryDictionaryStore2
} from './readonly-repository-dictionary.store2';

export interface RepositoryDictionaryState extends ReadonlyRepositoryDictionaryState {
  savingState: SavingState;
}

export interface DictionarySaveActionParameters extends SaveListParameters {
  token?: string;
}

@Injectable()
export class RepositoryDictionaryStore2<T extends object, TState extends RepositoryDictionaryState = RepositoryDictionaryState> extends ReadonlyRepositoryDictionaryStore2<T, TState>{

  savingState = computed(() => this.state().savingState);

  constructor() {
    super();
  }

  async save(params: DictionarySaveActionParameters): Promise<T[]> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.save(params)
      ));
      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed' } as Partial<TState>);
      throw e;
    }
  }

  async saveItem(key: string, params: DictionarySaveActionParameters): Promise<T[]> {
    this.commitPatch({ savingState: 'saving' } as Partial<TState>);
    const token = params.token ?? this.uuid();
    try {
      const value = await lastValueFrom(this.subscription.observe(
        token,
        this.service.saveItem(key, params)
      ));
      this.commitPatch({ savingState: 'saved' } as Partial<TState>);
      this.commitResponse.set(value);
      return value;
    } catch (e) {
      if (e instanceof CancelledError)
        this.commitPatch({ savingState: 'canceled' } as Partial<TState>);
      else
        this.commitPatch({ savingState: 'failed' } as Partial<TState>);
      throw e;
    }
  }

  override getDefaultState(): TState {
    return {
      loadingState: null,
      savingState: null,
    } as TState;
  }
}
