import { Component, contentChildren, effect, input, untracked } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFieldComponent } from './data-field/data-field.component';

@Component({
  selector: 'soft-data-grid',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './data-grid.component.html',
  styleUrl: './data-grid.component.scss',
})
export class DataGridComponent {
  title = input<string>();

  dataFieldComponents = contentChildren<DataFieldComponent>(DataFieldComponent);

  _removeBorderOnLastElementEffect = effect(() => {
    const children = this.dataFieldComponents();
    console.log(children);

    if (!children || children.length < 1) {
      return;
    }

    untracked(() => {
      const last = children[children.length - 1];
      last.setBorderBottomVisible(false);
    });
  }, { allowSignalWrites: true });
}
