import { FieldOkSearchConfig } from '@softapps/core';
import { LagersymFieldOkSearchResultViewComponent } from './search-result-view/search-result-view.component';

export const lagersymSearchPageConfig: FieldOkSearchConfig<any> = {
  name: 'lagersym',
  view: LagersymFieldOkSearchResultViewComponent,
  options: {
    autoLoad: true
  }
};
