import { ENVIRONMENT_INITIALIZER, ImportedNgModuleProviders, inject, Provider } from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { ApplicationStore, SOFTLINE_FEATURE_APPLICATIONS } from "@softline/application";
import { default as de } from "../i18n/de.json";
import { SOFTLINE_PERMISSION_INVENTUR } from "./inventur.shared";

export const inventurProviders: (Provider | ImportedNgModuleProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'inventur',
        language: 'de',
        translations: de
      });
      store.commit(SOFTLINE_FEATURE_APPLICATIONS, ApplicationStore.mutations.add, {
        name: '#INVENTUR.APP',
        description: '#INVENTUR.APP_DESC',
        icon: 'fa-regular fa-warehouse-full',
        route: '/inventur',
        permission: SOFTLINE_PERMISSION_INVENTUR
      });
    },
    multi: true
  }
];
