import { Contact } from '@softapps/wws/core';

export interface Account {
  category: number;
  contact: Contact;
  deletedOn: string;
  id: number;
  lockedOn: string;
  name: string;
  number: number;
  subNumber: number;
}
