import { inject, Injectable, Type } from "@angular/core";
import { DateService, SOFTLINE_SERVICE_UUID, Store } from "@softline/core";
import { MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";
import * as TemplateStore from '../../template/template.store';
import { showRequestErrors } from "../../errors/show-request-errors.function";
import { Template } from "../template";

type Constructor<T> = new(...args: any[]) => T;

export interface SaveTemplateMixinParameters {
  featureName: string,
  group?: string
  dialog: Type<any>
}

export const WithSaveTemplate = <T extends Constructor<{}>>(params: SaveTemplateMixinParameters, Base: T = (class {} as any)) => {

  @Injectable()
  abstract class SaveTemplateMixin extends Base {

    store = inject(Store);

    constructor(...args: any[]) {
      super(...args);
    }

    async saveTemplate(): Promise<void> {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open<Template<any>, any>(),
        {
          component: params.dialog,
          data: {
            featureName: params.featureName,
            group: params.group,
            ...this.createSaveTemplateModalParameters()
          },
          dismiss: true
        }
      )
      if(result === 'DISMISSED')
        return;
      try {
        await this.store.dispatch(
          params.featureName,
          TemplateStore.actions.template.create,
          result);
        await this.store.dispatch(
          SOFTLINE_FEATURE_MESSAGE_BAR,
          MessageBarStore.actions.success,
          '#APPLICATION.TEMPLATE.SAVE_SUCCESS'
        );
      }
      catch (e) {
        showRequestErrors(this.store, e);
      }
    }

    createSaveTemplateModalParameters(): object {
      return {}
    }
  }
  return SaveTemplateMixin;
}
