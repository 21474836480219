import { Component, HostListener, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import {
  BackNavigationService,
  MengenEingabeComponent,
  SOFTLINE_FEATURE_TITLE, Step,
  StepHeaderComponent,
  TitleStore
} from "@softline/application";
import { Modal, UiCoreModule } from "@softline/ui-core";
import { SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL } from "../../lagerkorrektur-artikel.shared";
import { LagerkorrekturArtikelStore } from "../../store";

@Component({
  selector: 'soft-mengen-auswahl-dialog',
  standalone: true,
  imports: [CommonModule, UiCoreModule, MengenEingabeComponent, StepHeaderComponent],
  templateUrl: './mengen-auswahl.dialog.html',
  styleUrls: ['./mengen-auswahl.dialog.scss']
})
export class MengenAuswahlDialog implements OnInit, Modal<any> {

  close!: (result: any) => void;
  artikel$ = this.store.observe(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.artikel);

  readonly steps: Step[] = [
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.DESCRIPTION'
    },
  ];

  constructor(private store: Store) {
  }

  ngOnInit(): void {
  }


  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  async onMengeChange(menge: number | null | undefined): Promise<void> {
    const artikel = this.store.get(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.getters.artikel);
    this.store.commit(SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, LagerkorrekturArtikelStore.mutations.setArtikel, {...artikel, menge});
  }

  @HostListener('window:keydown.enter', ['$event'])
  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    this.close(undefined);
  }
}
