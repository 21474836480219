export interface FavoriteList<T> {
  key: string;
  favorites: Favorite<T>[];
}

export interface Favorite<T> {
  id: string | number;
  object: T;
  timestamp: string;
}
