import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldOkItemComponent } from '@softline/dynamic';

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './artuntergrp.list-view.html',
  styleUrls: ['./artuntergrp.list-view.scss'],
})
export class ArtuntergrpListView implements FieldOkItemComponent<any> {
  @Input() item: any;

  constructor() {}
}
