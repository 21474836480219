@if (productSupplier(); as productSupplier) {
  <div class="flex flex-row justify-between items-center w-full flex-wrap">
    <div class="flex flex-col mt-2">
      <div class="flex flex-row justify-between w-full flex-wrap-reverse items-center">
        <div class="text-sm text-primary font-bold uppercase">{{productSupplier.supplier.number}}</div>
        <soft-chip [ngClass]="{
                              'bg-primary text-primary-contrast' :productSupplier.status.value === 'H'
                            }">
          {{productSupplier.status.description}}</soft-chip>
      </div>
      <div class="font-semibold mt-1">{{productSupplier.supplier.name}}</div>
      <div class="flex flex-row justify-between flex-wrap w-full">
        <div class="text-sm">Preis EH: {{productSupplier.priceUnit.key}}</div>
        <div class="text-sm">Liefer EH: {{productSupplier.unit.key}}</div>
      </div>
      <div class="text-sm">Mindestbestellmenge: {{productSupplier.minQuantity}}</div>
      @if(productSupplier.deliveryDays) {
        <div class="text-sm">Liefertage: {{productSupplier.deliveryDays}}</div>
      }
      @if(productSupplier.info1) {
        <div class="text-sm">
          <soft-icon name="fa-regular fa-info-circle" class="text-info"></soft-icon>
          {{productSupplier.info1}}
        </div>
      }
    </div>
    <div class="flex flex-col justify-end">
      <soft-barcode [size]="'sm'" [data]="productSupplier.ean" [labelType]="'ean13'"></soft-barcode>
      <div class="w-full text-end text-sm">Lief.-Art.-Nr.: {{productSupplier.supplierProductNumber}}</div>
    </div>
  </div>
}
