import {
  CancelledError,
  ConnectionError,
  ForbiddenError,
  Message,
  NetworkError,
  NotFoundError,
  RequestError,
  ServerError,
  UnauthorizedError,
  UnknownResponseError
} from '@softline/core';

export function requestErrorMessageFactory<T = Error>(error: T): Message<T> {
  if (error instanceof NetworkError)
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.NETWORK_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.NETWORK',
      type: 'error',
      icon: 'fa-regular fa-wifi-slash',
      data: error
    };
  else if (error instanceof ConnectionError)
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.CONNECTION_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.CONNECTION',
      type: 'error',
      icon: 'fa-regular fa-cloud-xmark',
      data: error
    };
  else if (error instanceof RequestError)
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.REQUEST_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.REQUEST',
      type: 'error',
      icon: 'fa-regular fa-file-circle-exclamation',
      data: error
    };
  else if (error instanceof UnauthorizedError) {
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.UNAUTHORIZED_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.UNAUTHORIZED',
      type: 'error',
      icon: 'fa-regular fa-user-slash',
      data: error
    };
  }
  else if (error instanceof ForbiddenError)
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.FORBIDDEN_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.FORBIDDEN',
      type: 'error',
      icon: 'fa-regular fa-lock',
      data: error
    };
  else if (error instanceof NotFoundError)
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.NOT_FOUND_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.NOT_FOUND',
      type: 'error',
      icon: 'fa-regular fa-compass-slash',
      data: error
    };
  else if (error instanceof ServerError)
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.SERVER_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.SERVER',
      type: 'error',
      icon: 'fa-regular fa-cloud-exclamation',
      data: error
    };
  else if (error instanceof UnknownResponseError)
    return {
      message: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN_RESPONSE',
      type: 'error',
      icon: 'fa-regular fa-file-circle-question',
      data: error
    };
  else if (error instanceof CancelledError)
    return {
      message: '#APPLICATION.MESSAGES.ERRORS.CANCELLED',
      type: 'warning',
      icon: 'fa-regular fa-triangle-exclamation',
      data: error
    };
  else {
    return {
      subject: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN_TITLE',
      message: '#APPLICATION.MESSAGES.ERRORS.UNKNOWN',
      type: 'critical',
      icon: 'fa-regular fa-diamond-exclamation',
      data: error
    };
  }
}
