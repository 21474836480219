import {Injectable} from '@angular/core';
import {Arbeitsbericht} from '../data/stempel';
import {map, Observable} from 'rxjs';
import {ConnectionHttpService, ConnectionResourceLocation, ResponseResult} from '@softline/core';
import {
  SOFTLINE_API_WERKSTAETTENAUFTRAG_TIME_REPORT_RESOURCE_PATH,
  SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH
} from '../auftrag.api';
import {SOFTLINE_ERROR_MULTIPLE_ACTIVE_TIME_REPORTS} from '../auftrag.shared';

@Injectable()
export class ArbeitsberichtService {

  constructor(private connectionHttpService: ConnectionHttpService) {}

  start(id: string, arbeitsbericht: Arbeitsbericht): Observable<Arbeitsbericht> {
    const location = { path: SOFTLINE_API_WERKSTAETTENAUFTRAG_TIME_REPORT_RESOURCE_PATH + '/start' }
    return this.connectionHttpService
      .create<Arbeitsbericht[], ResponseResult<Arbeitsbericht | Arbeitsbericht[]>>(location, [arbeitsbericht])
      .pipe(map(response => Array.isArray(response?.result) ? response?.result[0] : response?.result ?? response));
  }

  stop(): Observable<Arbeitsbericht> {
    const location = { path: SOFTLINE_API_WERKSTAETTENAUFTRAG_TIME_REPORT_RESOURCE_PATH + '/stop' }
    return this.connectionHttpService
      .create<unknown, ResponseResult<Arbeitsbericht | Arbeitsbericht[]>>(location, undefined)
      .pipe(map(response => Array.isArray(response?.result ?? response) ? (response?.result[0] ?? response[0]) : (response.result ?? response[0])));
  }

  load(userId: string): Observable<Arbeitsbericht | undefined> {
    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH,
      queryParams: { onlyActive: true, userId }
    }

    return this.connectionHttpService
      .get<ResponseResult<Arbeitsbericht[]>>(location)
      .pipe(
        map(o => {
          if (o?.result?.length > 1)
            throw new Error(SOFTLINE_ERROR_MULTIPLE_ACTIVE_TIME_REPORTS);
          else if (o?.result?.length === 0)
            return undefined;

          return o?.result[0];
        })
      );
  }
}
