import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  KeyStore,
  mutate,
  on,
  RemoteCollectionStore,
  select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { LagerkorrekturArtikel } from "../types/lagerkorrektur";
import { lastValueFrom } from "rxjs";
import { LagerkorrekturArtikelService } from "../services/lagerkorrektur-artikel.service";
import { Artikel, ArtikelMenge, LagerplatzInhalt } from "@softapps/lager/core";

export interface State extends ActionStore.State, SubscriptionStore.State, KeyStore.State  {
  lagerplatz?: LagerplatzInhalt;
  artikel?: ArtikelMenge;
  umbuchungsartikel?: Artikel;
};

export class LagerkorrekturArtikelStore {
  static mutations = {
    setLagerplatz: createMutation<State, LagerplatzInhalt>('setLagerplatz'),
    setArtikel: createMutation<State, ArtikelMenge>('setArtikel'),
    setUmbuchungsartikel: createMutation<State, Artikel>('setUmbuchungsartikel'),
    reset: createMutation<State>('reset'),
  };
  static getters = {
    lagerplatz: createGetter<State, LagerplatzInhalt>('quellLagerplatz'),
    artikel: createGetter<State, ArtikelMenge>('artikel'),
    umbuchungsartikel: createGetter<State, Artikel>('umbuchungsartikel')
  };
  static actions = {
    loadLagerplatz: createAction<State, string, LagerplatzInhalt>('loadLagerplatz'),
    lagerkorrektur: createAction<State, LagerkorrekturArtikel>('lagerkorrektur')
  };

  static feature: StoreFeature<State> = {
    initialState: {
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...SubscriptionStore.feature.initialState
    },
    mutations: [
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      mutate(LagerkorrekturArtikelStore.mutations.setLagerplatz,
        ({state, params}) => ({...state, lagerplatz: params})
      ),
      mutate(LagerkorrekturArtikelStore.mutations.setArtikel,
        ({state, params}) => ({...state, artikel: params})
      ),
      mutate(LagerkorrekturArtikelStore.mutations.setUmbuchungsartikel,
        ({state, params}) => ({...state, umbuchungsartikel: params})
      ),
      mutate(LagerkorrekturArtikelStore.mutations.reset,
        ({state, params}) => ({...state, lagerplatz: undefined, artikel: undefined, umbuchungsartikel: undefined})
      )
    ],
    getters: [
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(LagerkorrekturArtikelStore.getters.lagerplatz, ({state}) => state.lagerplatz),
      select(LagerkorrekturArtikelStore.getters.artikel, ({state}) => state.artikel),
      select(LagerkorrekturArtikelStore.getters.umbuchungsartikel, ({state}) => state.umbuchungsartikel)
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(LagerkorrekturArtikelStore.actions.loadLagerplatz, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerkorrekturArtikelService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getQuelllagerplatz(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerkorrekturArtikelStore.actions.loadLagerplatz.name,
            token
          )
        );
        commit(featureName, LagerkorrekturArtikelStore.mutations.setLagerplatz, result);
        return result;
      }),
      on(LagerkorrekturArtikelStore.actions.lagerkorrektur, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerkorrekturArtikelService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.lagerkorrektur(params.lagerplatzInhalt, params.umbuchungsartikel),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerkorrekturArtikelStore.actions.lagerkorrektur.name,
            token
          )
        );
        commit(featureName, LagerkorrekturArtikelStore.mutations.reset);
        return result;
      })
    ]
  }
}

export const UmbuchungsartikelStore = RemoteCollectionStore.create<Artikel>()
