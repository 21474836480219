import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestLog } from '../../request-log';
import { UiCoreModule } from '@softline/ui-core';

@Component({
  selector: 'soft-request-log',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './request-log.component.html',
  styleUrl: './request-log.component.scss',
})
export class RequestLogComponent {
  log = input.required<RequestLog>();
}
