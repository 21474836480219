import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Auftrag} from '../../data/auftrag';
import {Store} from '@softline/core';
import {BehaviorSubject} from 'rxjs';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE} from '../../auftrag.shared';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'soft-close-task-dialog',
  standalone: true,
  imports: [
    UiCoreModule,
    CommonModule
  ],
  templateUrl: './close-task-dialog.component.html',
  styleUrls: ['./close-task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseAuftragDialogComponent implements Modal<any> {

  auftrag!: Auftrag;
  close!: (result: 'success' | 'cancel' | undefined) => void;

  readonly isSaving$ = new BehaviorSubject(false);

  // tslint:disable-next-line:variable-name
  private _selectedMethod: 'approval' | 'deliveryNote' | 'invoice' | undefined = undefined;

  get canContinue(): boolean {
    return this._selectedMethod !== undefined;
  }

  get selectedMethod(): 'approval' | 'deliveryNote' | 'invoice' | undefined {
    return this._selectedMethod;
  }

  constructor(private store: Store) { }

  registerCloseHandler(handler: (result: any) => void): void {
    this.close = handler;
  }

  select(method: 'approval' | 'deliveryNote' | 'invoice'): void {
    this._selectedMethod = method;
  }

  async continue(): Promise<void> {
    this.isSaving$.next(true);

    if (!this.selectedMethod)
      return;

    try {
      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
        WerkstattAuftraegeStore.actions.complete,
        {
          auftrag: this.auftrag,
          method: this.selectedMethod,
        }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        { title: 'Der Auftrag wurder erfolgreich abgeschlossen' }
      );

      this.close('success');
    } catch (e) {
      await this.showErrorMessage(false);
    } finally {
      this.isSaving$.next(false)
    }
  }

  private async showErrorMessage(isUnauthorized: boolean): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        container: 'CLOSE_TASK_MSG_BAR',
        duration: 10000,
        title: isUnauthorized ? 'Keine Berechtigung' : '#WORKSHOP.DIALOGS.ERROR_SUBJECT',
        message: isUnauthorized ? 'Keine Berechtigung für diese Abschlussart' : '#WORKSHOP.DIALOGS.CLOSE_TASK.ERROR',
        actions: isUnauthorized
          ? []
          : [{
            title: '#WORKSHOP.DIALOGS.TRY_AGAIN',
            type: 'link',
            onExecute: async () => await this.continue()
          }],
      }
    );
  }
}
