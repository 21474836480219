import { createAction, createMutation, on, RemoteObjectStore, StoreBuilder } from "@softline/core";
import { Lagerplatz } from "../types/lagerplatz";
import { MessageBarStore, ModalStore, SOFTLINE_FEATURE_MESSAGE_BAR, SOFTLINE_FEATURE_MODAL } from "@softline/ui-core";

export class LagerplatzSucheStoreExtension {
  static actions = {
    suche: createAction<RemoteObjectStore.State<Lagerplatz>, string, Lagerplatz | undefined>('suche')
  };
  static feature = {
    actions: [
      on(LagerplatzSucheStoreExtension.actions.suche, async ({ featureName, dispatch, params }) => {
        const lagerplaetze = await dispatch(featureName, RemoteObjectStore.actions.load, {pathParams: { code: params }}) as unknown as Lagerplatz[];
        if(lagerplaetze.length === 0) {
          await dispatch(SOFTLINE_FEATURE_MESSAGE_BAR, MessageBarStore.actions.warning, '#LAGERPLATZ_CORE.MESSAGES.NO_LAGERPLATZ_FOUND')
          return undefined;
        }
        else if(lagerplaetze.length > 1) {
          const result = await dispatch(SOFTLINE_FEATURE_MODAL, ModalStore.actions.choose<Lagerplatz>(), {
            title: '#LAGERPLATZ_CORE.DIALOGS.CHOOSE_LAGERPLATZ',
            options: lagerplaetze.map(o => ({label: o.lagerplatz, value: o})),
            dismiss: true,
          })
          if(result === "DISMISSED")
            return undefined;
          return result;
        }
        return lagerplaetze[0]
      })
    ]
  }
}

export const LagerplatzSucheStore = new StoreBuilder(RemoteObjectStore.create<Lagerplatz[]>())
  .add(LagerplatzSucheStoreExtension)
  .value
