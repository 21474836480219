import { StaticProvider } from '@angular/core';
import { SOFTLINE_CONFIG_INDEXED_DB } from '@softline/core';
import { SOFTLINE_INDEXED_DB_USER } from './user.indexed-db';
import { pageSettingsProviders } from './page-settings/page-settings.providers';
import { favoriteProviders } from './favorites/favorite.providers';
import { lastUsedProviders } from './last-used/last-used.providers';


export const userProviders: StaticProvider[] = [
  { provide: SOFTLINE_CONFIG_INDEXED_DB, useValue: SOFTLINE_INDEXED_DB_USER, multi: true },
  ...pageSettingsProviders,
  ...favoriteProviders,
  ...lastUsedProviders
]
