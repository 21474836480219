import { inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { PageSettingsService } from './page-settings.service';

type Constructor<T extends {}> = new (...args: any[]) => T;

export const WithPageSettings = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class PageSettingsMixin extends Base implements OnInit, OnDestroy{

    #pageSettingsService = inject(PageSettingsService);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();

      try {
        this.#pageSettingsService.load();
      }
      catch (e) {
        console.debug(`[PageSettingsMixin] no component settings found.`, e);
      }
    }

    ngOnDestroy(): void {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }
  }
  return PageSettingsMixin;
}
