import {InjectionToken} from '@angular/core';
import {WerkstattConfig} from './data/werkstatt-config';

export const SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT = 'werkstatt/arbeitsbericht'
export const SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE = 'werkstatt/auftraege'
export const SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARTIKEL = 'werkstatt/artikel'

export const SOFTLINE_ERROR_MULTIPLE_ACTIVE_TIME_REPORTS = 'MULTIPLE_ACTIVE_TIME_REPORTS_ERROR';

export const SOFTLINE_CONFIG_WERKSTAETTENAUFTRAG = new InjectionToken<WerkstattConfig>(
  'SOFTLINE_CONFIG_WERKSTAETTENAUFTRAG'
);
