import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierPromotion } from '../../types/supplier-promotion';
import { SupplierPrice } from '../../types/supplier-price';
import { IconComponent, IsDefinedNotEmptyPipe, L10nModule } from '@softline/ui-core';
import { PriceCardComponent } from '../price-card/price-card.component';

@Component({
  selector: 'soft-supplier-price-card',
  standalone: true,
  imports: [CommonModule, L10nModule, PriceCardComponent, IconComponent, IsDefinedNotEmptyPipe],
  templateUrl: './supplier-price-card.component.html',
  styleUrl: './supplier-price-card.component.scss',
})
export class SupplierPriceCardComponent {
  supplierPrice = input.required<SupplierPrice>();
}
