import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Input,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  TemplateRef,
  untracked,
  ViewContainerRef
} from '@angular/core';
import { UiCorePipesModule } from '../../pipes/ui-core-pipes.module';
import { I18nModule } from '../../i18n/i18n.module';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { WithBreakpoints } from '../../utilities/breakpoints/breakpoint.mixin';
import { isDefined } from '@softline/core';

@Component({
  selector: 'soft-master-detail',
  standalone: true,
  templateUrl: './master-detail.component.html',
  styleUrls: ['./master-detail.component.scss'],
  imports: [CommonModule, UiCorePipesModule, I18nModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDetailComponent<T> extends WithBreakpoints() implements OnInit, OnDestroy {
  private _viewContainerRef = inject(ViewContainerRef);
  #selectedIndex = signal<number>(-1);

  data = input<T[]>([]);
  masterTemplate = input<TemplateRef<any> | null>(null);
  detailTemplate = input<TemplateRef<any> | null>(null);
  detailPlaceholder = input<TemplateRef<any> | null>(null);
  detailHeaderVisible = input<boolean>(true);

  masterDblClick = output<T>();

  @Input({alias: 'selected'})
  set selectedInput(value: T | null) {
    const index = isDefined(value) ? this.data().indexOf(value) : -1;
    this.#selectedIndex.set(index);
  }

  selected = computed<T | null>(() => this.data()[this.#selectedIndex()]);
  selectedChange = output<T | null>();

  view = computed<'detail' | 'master' | 'both'>(() => {
    const selectedIndex = this.#selectedIndex();
    const smBreakpoint = this.smBreakpoint();
    if(selectedIndex > -1 && !smBreakpoint)
      return 'detail';
    else if(!smBreakpoint)
      return 'master'
    return 'both'
  });

  dataEffect = effect(() => {
    const data = this.data();
    const selectedIndex = untracked(() => this.#selectedIndex());
    const smBreakpoint = untracked(() => this.smBreakpoint());
    //im Desktop Modus wird der erste Eintrag selektiert
    if(data.length > 0 && selectedIndex === -1 && smBreakpoint) {
      this.#selectedIndex.set(0);
      untracked(() => {
        this.selectedChange.emit(this.selected());
      })
    }
  }, {allowSignalWrites: true});

  constructor() {
    super();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  select(index: number): void {
    this.#selectedIndex.set(index);
    this.selectedChange.emit(this.selected());
  }

  // Um beim Pull To refresh Container nicht voreilig neu zu laden (wenn der untere scrollcontainer
  // irgendwo in der mitte steht der vom parent aber ganz oben) darf das touchstart event
  // nicht weitergegeben werden ausser der untere scrollcontainer ist ganz oben.
  preventScrolling(event: TouchEvent, container: HTMLDivElement): void {
    if (container.scrollTop > 0)
      event.stopPropagation();
  }
}
