import { Component, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../page/page.component';
import { MenuOutletComponent } from '../../menu/menu-outlet/menu-outlet.component';
import { Application, WithRouter } from '@softline/application';
import { IconComponent } from '@softline/ui-core';
import { PageHeaderComponent } from '../page/page-header/page-header.component';

@Component({
  selector: 'soft-module-page',
  standalone: true,
  imports: [CommonModule, PageComponent, PageHeaderComponent, MenuOutletComponent, IconComponent],
  templateUrl: './module-page.component.html',
  styleUrl: './module-page.component.scss'
})
export class ModulePageComponent extends WithRouter(){
  modules = input.required<Application[]>();

  constructor() {
    super();
  }

  async onClick(module: Application): Promise<void> {
    await this.router.navigate([module.route], module.routeParams)
  }
}
