import { Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Price } from '../../types/price';
import { UiCoreModule } from '@softline/ui-core';
import { Unit } from '../../types/unit';

@Component({
  selector: 'soft-price-card',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './price-card.component.html',
  styleUrl: './price-card.component.scss',
})
export class PriceCardComponent {
  price = input<Price | Price[]>();
  unit = input<Unit>();
  promotion = input<boolean>(false);
  currency = input<string>('EUR');

  firstPrice = computed(() => {
    const price = this.price();
    if(Array.isArray(price))
      return price.find(o => o.fromAmount === 0) ?? price[0];
    return price;
  })

  bulkPrices = computed<Price[]>(() => {
    let price = this.price();
    if(!price)
      return [];
    if(!Array.isArray(price))
      price = [price];
    return price.filter(o => o.fromAmount > 0);
  })
}
