import {Command} from '@softline/application';
import {Router} from '@angular/router';
import {Auftrag} from '../data/auftrag';

export class ManualTimeReportCommand implements Command {
  icon = 'fa-regular fa-business-time';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.MANUAL_TIME_REPORT';
  class = 'menu action-menu action-menu-top';
  execute = () => this.navigateToTrackActivity();

  constructor(private router: Router, private task: () => Auftrag) {}

  private navigateToTrackActivity = async (): Promise<void> => {
    await this.router.navigate(['/werkstaettenauftrag', this.task().id, 'arbeitsbericht']);
  }
}
