import { Inject, Injectable } from "@angular/core";
import { ResourceService, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable } from "rxjs";
import { LagerplatzInhalt } from "@softapps/lager/core";
import { SOFTLINE_API_LAGERSTAND_ARTIKEL_SUCHE } from "../lagerstand.api";
import { SOFTLINE_API_SCAN_PALETTE } from "../../../../paletten-umlagerung/src/lib/paletten-umlagerung.api";

@Injectable()
export class LagerstandService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ResourceService<any>) {
  }

  getPalette(code: string): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_SCAN_PALETTE,
      pathParams: {code}
    }
    return this.service.get(location);
  }
  getLagerstand(id: string | number): Observable<LagerplatzInhalt[]> {
    const location = {
      path: SOFTLINE_API_LAGERSTAND_ARTIKEL_SUCHE,
      pathParams: {id}
    }
    return this.service.get(location);
  }
}
