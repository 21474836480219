import { Component, contentChild, inject, input, output, signal, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Command2, MenuItem, ResponsiveMenuComponent, WithCommands2, WithMenuItems } from '@softline/application';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from '../page/page.component';
import { PageHeaderComponent } from '../page/page-header/page-header.component';
import { IconComponent, UiCoreModule } from '@softline/ui-core';
import { QueryShortcut } from './query-shortcut';
import { QueryPageShortcutsComponent } from './query-page-shortcuts/query-page-shortcuts.component';
import { MetaQueryBarComponent } from '../../components/meta-query-bar/meta-query-bar.component';

@Component({
  selector: 'soft-query-page',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PageComponent, PageHeaderComponent, ResponsiveMenuComponent, IconComponent, UiCoreModule, QueryPageShortcutsComponent, MetaQueryBarComponent],
  templateUrl: './query-page.component.html',
  styleUrl: './query-page.component.scss'
})
export class QueryPageComponent<T extends object>
  extends WithMenuItems(
    WithCommands2()
  )
{
  formRef = inject(FormGroupDirective);

  header = contentChild(PageHeaderComponent, { descendants: true });
  shortcuts = input<QueryShortcut<T>[]>([]);

  override menuItems: Signal<MenuItem[]> = signal([
    {
      type: 'command',
      name: 'QueryPageComponentQueryCommand',
      outlet: 'responsive',
      class: 'soft-button accent',
      icon: 'fa-regular fa-magnifying-glass',
      title: '#SOFTAPPS_CORE.MENU_ITEMS.QUERY',
    }
    ]);
  override commands: Signal<Command2[]> = signal([
    {
      name: 'QueryPageComponentQueryCommand',
      canExecute: true,
      execute: async () => {
        this.formRef.onSubmit(this.formRef.form.value);
        if(this.formRef.form.valid)
          this.query.emit(this.formRef.form.value);
      },
    },]);

  query = output<T>();

  constructor() {
    super();
  }

  onShortcutClick(query: T): void {
    this.formRef.form.patchValue(query);
    this.formRef.onSubmit(this.formRef.form.value);
    if(this.formRef.form.valid)
      this.query.emit(this.formRef.form.value);
  }
}
