import {Command, showRequestErrors} from '@softline/application';
import {map, Observable} from 'rxjs';
import {Store} from '@softline/core';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT} from '../auftrag.shared';
import {WerkstattArbeitsberichtStore} from '../store/werkstatt-arbeitsbericht.store'
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {EndActivityDialogComponent} from '../dialogs/end-activity-dialog/end-activity-dialog.component';
import {Arbeitsbericht} from '../data/stempel';
import {Auftrag} from '../data/auftrag';

export class EndActivityCommand implements Command {
  icon = 'fa-regular fa-circle-xmark';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.END_ACTIVITY';
  class = 'menu action-menu action-menu-top';

  canExecute: Observable<boolean> = this.store.observe(
    SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
    WerkstattArbeitsberichtStore.getters.active
  ).pipe(map(o => !!o));

  execute = async () => await this.endActivity();

  constructor(private store: Store, private timeReport: Arbeitsbericht | undefined, private auftrag?: () => Auftrag, ) {}

  private endActivity = async (): Promise<void> => {
    try {
      const result = await this.store.dispatch(
        SOFTLINE_FEATURE_MODAL,
        ModalStore.actions.open(),
        {
          id: 'END_ACTIVITY',
          component: EndActivityDialogComponent,
          data: { timeReport: this.timeReport },
          dismiss: { backdrop: true }
        }
      );

      if (result !== 'DISMISSED' && this.auftrag) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_ARBEITSBERICHT,
          WerkstattArbeitsberichtStore.actions.loadMany,
          { clear: false, queryParams: { orderId: this.auftrag().id } }
        );
      }
    } catch (e) {
      showRequestErrors(this.store, e);
    }
  }
}
