import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchResultComponent} from '@softapps/core';
import {I18nModule} from '@softline/ui-core';

@Component({
  selector: 'soft-search-result-view',
  standalone: true,
  imports: [CommonModule, I18nModule],
  templateUrl: './search-result-view.component.html',
  styleUrl: './search-result-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArtstammFieldOkSearchResultViewComponent implements SearchResultComponent<any> {
  readonly result = input.required<any>();
}
