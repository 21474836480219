<div class="soft-carousel overflow-hidden">
  @if(items.length > 1) {
    <span
      class="soft-carousel-indicator left pointer"
      [class.disabled]="(canPrevious$ | async) === false"
      (click)="onPrevious(container)"
    >
    <i class="fa-regular fa-chevron-left"></i>
  </span>
  }
  <div class="w-full" #container [class.whitespace-nowrap]="fluid">
    <div
      *ngFor="let item of items$ | async; let i = index; trackBy: trackByFn"
      class="w-full"
    >
      <ng-container
        *ngTemplateOutlet="template; context: { $implicit: item, index: i }"
      ></ng-container>
    </div>
  </div>

  @if(items.length > 1) {
    <span
      class="soft-carousel-indicator right pointer"
      [class.disabled]="(canNext$ | async) === false"
      (click)="onNext(container)"
    >
      <i class="fa-regular fa-chevron-right"></i>
    </span>
  }
</div>
