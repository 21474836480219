import {ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider, StaticProvider} from "@angular/core";
import { Store } from "@softline/core";
import { SOFTLINE_FEATURE_TRANSLATION, TranslationStore } from "@softline/ui-core";
import { default as de } from "../i18n/de.json";
import { CommandStore, MenuItemStore2, SOFTLINE_FEATURE_COMMANDS } from '@softline/application';

export const moduleProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: ENVIRONMENT_INITIALIZER,
    useValue: () => {
      const store = inject(Store);
      store.commit(
        SOFTLINE_FEATURE_TRANSLATION,
        TranslationStore.mutations.add,
        {
          module: 'app-overview',
          language: 'de',
          translations: de,
        }
      );

      const menuItemStore = inject(MenuItemStore2);
      menuItemStore.setItem('moduleProviders', [{
        name: 'moduleRoute',
        type:'route',
        outlet: 'nav-static',
        priority: 1,
        routerLink: ['/'],
        icon: 'fa-regular fa-grid',
        title: '#APP_OVERVIEW.ALL_APPLICATIONS',
      }]);
    },
    multi: true,
  },
];
