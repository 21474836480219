import {
  Component,
  contentChild, EventEmitter,
  input, Output,
  output,
  TemplateRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingState } from '@softline/core';
import { DynamicModule } from '@softline/dynamic';
import { I18nModule, UiCorePipesModule } from '@softline/ui-core';
import { PageSummaryComponent } from './page-summary/page-summary.component';
import { PageComponent } from '../page/page.component';
import { RemoteDataContainerComponent } from '../../components/remote-data-container/remote-data-container.component';
import { PageHeaderComponent } from '../page/page-header/page-header.component';
import { MetaQueryBarComponent } from '../../components/meta-query-bar/meta-query-bar.component';

@Component({
  selector: 'soft-list-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    DynamicModule,
    I18nModule,
    RemoteDataContainerComponent,
    UiCorePipesModule,
    PageSummaryComponent,
    PageHeaderComponent,
    MetaQueryBarComponent
  ],
  templateUrl: './list-page.component.html',
  styleUrl: './list-page.component.scss',
})
export class ListPageComponent<T> {
  loadingState = input.required<LoadingState>();
  items = input.required<T[]>();
  cancelLoad = output();

  @Output() itemClick = new EventEmitter<T>();

  header = contentChild(PageHeaderComponent, { descendants: true });
  itemTemplate = contentChild<TemplateRef<{ item: T }>>(TemplateRef);
  summary = contentChild(PageSummaryComponent, { descendants: true });
}
