export const FILTER_CATEGORY = 'android.intent.category.DEFAULT';

export const INTENT_ACTION = 'com.symbol.datawedge.api.ACTION';
export const INTENT_ACTION_RESULT = 'com.symbol.datawedge.api.RESULT_ACTION';

export const INTENT_EXTRA_SOFT_SCAN_TRIGGER =
  'com.symbol.datawedge.api.SOFT_SCAN_TRIGGER';
export const INTENT_EXTRA_CREATE_PROFILE =
  'com.symbol.datawedge.api.CREATE_PROFILE';

export const INTENT_EXTRA_GET_VERSION_INFO =
  'com.symbol.datawedge.api.GET_VERSION_INFO';
export const INTENT_EXTRA_GET_VERSION_INFO_RESULT =
  'com.symbol.datawedge.api.RESULT_GET_VERSION_INFO';

export const INTENT_EXTRA_ENUMERATE_SCANNERS =
  'com.symbol.datawedge.api.ENUMERATE_SCANNERS';
export const INTENT_EXTRA_ENUMERATE_SCANNERS_RESULT =
  'com.symbol.datawedge.api.RESULT_ENUMERATE_SCANNERS';

export const INTENT_EXTRA_GET_ACTIVE_PROFILE =
  'com.symbol.datawedge.api.GET_ACTIVE_PROFILE';
export const INTENT_EXTRA_GET_ACTIVE_PROFILE_RESULT =
  'com.symbol.datawedge.api.RESULT_GET_ACTIVE_PROFILE';

export const INTENT_EXTRA_GET_PROFILES_LIST =
  'com.symbol.datawedge.api.GET_PROFILES_LIST';
export const INTENT_EXTRA_GET_PROFILES_LIST_RESULT =
  'com.symbol.datawedge.api.RESULT_GET_PROFILES_LIST';

export const INTENT_EXTRA_GET_CONFIG = 'com.symbol.datawedge.api.GET_CONFIG';
export const INTENT_EXTRA_GET_CONFIG_RESULT =
  'com.symbol.datawedge.api.RESULT_GET_CONFIG';

export const INTENT_EXTRA_SET_CONFIG = 'com.symbol.datawedge.api.SET_CONFIG';
export const INTENT_EXTRA_GET_SET_CONFIG_RESULT =
  'com.symbol.datawedge.api.RESULT_GET_PROFILES_LIST';

export const INTENT_EXTRA_SWITCH_TO_PROFILE =
  'com.symbol.datawedge.api.SWITCH_TO_PROFILE';

export const INTENT_EXTRA_DELETE_PROFILE =
  'com.symbol.datawedge.api.DELETE_PROFILE';
