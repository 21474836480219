import { Component, computed, input, OnInit, output } from '@angular/core';
import { Store } from '@softline/core';
import { MessageBarConfig } from '../../data/message-bar.config';
import { SOFTLINE_FEATURE_MESSAGE_BAR } from '../../message-bar.shared';
import * as MessageBarStore from '../../message-bar.store';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'soft-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10rem)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({  opacity: 0, transform: 'translateY(10rem)' }))
      ])
    ])
  ]
})
export class MessageBarComponent implements OnInit {
  config = input.required<MessageBarConfig>();
  dismiss = output<MessageBarConfig>();

  hasActions = computed(() =>
    (this.config().actions ?? []).length > 0
  )
  buttons = computed(() =>
    (this.config().actions ?? []).filter(o => o.type === 'button')
  );
  links = computed(() =>
    (this.config().actions ?? []).filter(o => o.type === 'link')
  );

  constructor(private store: Store) {}

  ngOnInit(): void {}

  onDismiss(id?: string, callback?: () => void): void {
    if (callback)
      callback();
    this.dismiss.emit(this.config());
  }
}
