import { KeyNameObject } from './product';
import { Price } from './price';
import { Unit } from './unit';

export interface Promotion {
  key: string;
  name: string;
  validFrom: string;
  validTo: string;
  prices: Price[];
  unit: Unit;
}
