<div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2">
  <soft-step-header [steps]="steps" [currentIndex]="1" #stepper></soft-step-header>
  <soft-default-header [overline]="stepper.stepText"
                    [title]="stepper.current()?.header | translate"
                    [subtitle]="stepper.current()?.subHeader | translate"></soft-default-header>
</div>

<div class="soft-overline mt-4">
  {{'#LAGERPLATZ_UMLAGERUNG.PAGES.ARTIKEL_AUSWAHL.ARTIKEL' | translate}}
</div>
<soft-artikel-menge-auswahl [lagerplatzInhalt]="quellLagerplatz$ | async"
                            (artikelMengeChange)="onSelect($event)"></soft-artikel-menge-auswahl>

<soft-scan-button class="mt-4"></soft-scan-button>
<soft-blocking-loading-spinner *ngIf="loading$ | async" class="top-0 right-0"></soft-blocking-loading-spinner>
