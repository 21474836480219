<ng-container *ngIf="selectedTool$ | async as selectedTool">
  <div
    class="pdf-editor-tool-customization"
    *ngIf="selectedTool.customizations"
  >
    <div
      class="pdf-editor-tool-customization-items"
      *ngIf="selectedTool.type === pdfEditorToolType.Pen"
    >
      <div
        class="pdf-editor-tool-customization-item"
        *ngIf="$any(selectedTool.customizations)?.width"
      >
        <label class="font-medium text-xs">Stärke</label>
        <input
          type="range"
          class="h-6"
          [value]="selectedTool.options.width ?? 1"
          [min]="$any(selectedTool.customizations)?.width?.min ?? 1"
          [max]="$any(selectedTool.customizations)?.width?.max ?? 16"
          (input)="onOptionChange(selectedTool, 'width', +$any($event.target).value)"
        />
      </div>
      <div
        class="pdf-editor-tool-customization-item"
        *ngIf="$any(selectedTool.customizations)?.color"
      >
        <label class="font-medium text-xs">Farbe</label>
        <input
          type="color"
          class="h-6"
          [value]="selectedTool.options.color"
          (input)="onOptionChange(selectedTool, 'color', $any($event.target).value)"
        />
      </div>
    </div>

    <div
      class="pdf-editor-tool-customization-items"
      *ngIf="selectedTool.type === pdfEditorToolType.Marker"
    >
      <div
        class="pdf-editor-tool-customization-item"
        *ngIf="$any(selectedTool.customizations)?.color"
      >
        <label class="font-medium text-xs">Farbe</label>
        <div class="flex flex-row w-full place-content-evenly">
          <div
            class="circle pointer"
            [style]="{ background: 'rgba(255, 0, 0, 0.3)' }"
            [class.selected]="
              selectedTool.options.color === 'rgba(255, 0, 0, 0.3)'
            "
            (click)="
              onOptionChange(selectedTool, 'color', 'rgba(255, 0, 0, 0.3)')
            "
          ></div>
          <div
            class="circle pointer"
            [style]="{ background: 'rgba(255, 255, 0, 0.5)' }"
            [class.selected]="
              selectedTool.options.color === 'rgba(255, 255, 0, 0.5)'
            "
            (click)="
              onOptionChange(selectedTool, 'color', 'rgba(255, 255, 0, 0.5)')
            "
          ></div>
          <div
            class="circle pointer"
            [style]="{ background: 'rgba(0, 0, 255, 0.3)' }"
            [class.selected]="
              selectedTool.options.color === 'rgba(0, 0, 255, 0.3)'
            "
            (click)="
              onOptionChange(selectedTool, 'color', 'rgba(0, 0, 255, 0.3)')
            "
          ></div>
          <div
            class="circle pointer"
            [style]="{ background: 'rgba(0, 255, 0, 0.3)' }"
            [class.selected]="
              selectedTool.options.color === 'rgba(0, 255, 0, 0.3)'
            "
            (click)="
              onOptionChange(selectedTool, 'color', 'rgba(0, 255, 0, 0.3)')
            "
          ></div>
        </div>
      </div>
    </div>

    <div
      class="pdf-editor-tool-customization-items"
      *ngIf="selectedTool.type === pdfEditorToolType.TextBox"
    >
      <div
        class="pdf-editor-tool-customization-item"
        *ngIf="$any(selectedTool.customizations)?.fontSize"
      >
        <label class="font-medium text-xs">Schriftgröße</label>
        <input
          type="range"
          class="h-6"
          [value]="selectedTool.options.fontSize ?? 1"
          [min]="$any(selectedTool.customizations)?.fontSize?.min ?? 1"
          [max]="$any(selectedTool.customizations)?.fontSize?.max ?? 16"
          (input)="
            onOptionChange(selectedTool, 'fontSize', +$any($event.target).value)
          "
        />
      </div>
      <div
        class="pdf-editor-tool-customization-item"
        *ngIf="$any(selectedTool.customizations).backgroundColor"
      >
        <label class="font-medium text-xs">Hintergrundfarbe</label>
        <input
          type="color"
          class="h-6"
          [value]="selectedTool.options.backgroundColor"
          (input)="
            onOptionChange(selectedTool, 'backgroundColor', $any($event.target).value)
          "
        />
      </div>
      <div
        class="pdf-editor-tool-customization-item"
        *ngIf="$any(selectedTool.customizations)?.fill"
      >
        <label class="font-medium text-xs">Textfarbe</label>
        <input
          type="color"
          class="h-6"
          [value]="selectedTool.options.fill"
          (input)="onOptionChange(selectedTool, 'fill', $any($event.target).value)"
        />
      </div>
    </div>
  </div>
</ng-container>
