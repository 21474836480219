import { Component, forwardRef, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingState } from '@softline/core';
import { PanelViewBase } from '@softline/ui-core';
import { RemoteDataContainerComponent } from '../../components/remote-data-container/remote-data-container.component';
import { RemoteDataPanelViewBase } from '../remote-data-panel-view.base';

@Component({
  selector: 'soft-remote-data-panel-view',
  standalone: true,
  imports: [CommonModule, RemoteDataContainerComponent],
  templateUrl: './remote-data.panel-view.html',
  styleUrl: './remote-data.panel-view.scss',
  providers: [
    {provide: PanelViewBase, useExisting: forwardRef(() => RemoteDataPanelView)},
    {provide: RemoteDataPanelViewBase, useExisting: forwardRef(() => RemoteDataPanelView)}
  ]
})
export class RemoteDataPanelView<T> extends RemoteDataPanelViewBase<T> {
  override name = input.required<string>();
  override label = input<string>('Data');
  override icon = input<string>('fa-regular fa-grid-2');
  override template = viewChild<TemplateRef<any>>('template');
  override data = input<T | null>(null);
  override loadingState = input<LoadingState>(null);
  override loadingError = input<Error | null>(null);
}
