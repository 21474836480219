import {ChangeDetectionStrategy, Component, forwardRef, viewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldOkComponent} from '@softline/dynamic';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DefaultFieldOkConfiguration} from '../field-ok-configuration';
import {FieldOkWrapper} from '../field-ok-wrapper';

@Component({
  selector: 'soft-abteilung-field-ok',
  standalone: true,
  imports: [CommonModule, FieldOkComponent],
  templateUrl: './abteilung-field-ok.component.html',
  styleUrl: './abteilung-field-ok.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AbteilungFieldOkComponent),
      multi: true,
    },
  ],
})
export class AbteilungFieldOkComponent extends FieldOkWrapper<DefaultFieldOkConfiguration> implements ControlValueAccessor {
  readonly fieldOkComponent = viewChild.required(FieldOkComponent);
}
