import {Inject, Injectable, signal} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class ScrollPositionService {
  private readonly lastScrollPosition  = signal<[number, number]>([0, 0]);

  constructor(
    @Inject(DOCUMENT)
    private readonly document: Document
  ) {}

  get scrollPosition(): [number, number] {
    return this.lastScrollPosition()
  }

  scrollToLastPosition(element?: Element): void {
    if (!element)
      element = this.document.getElementsByClassName('content')[0];

    element.scrollTo({ top: this.lastScrollPosition()[1] });
  }

  setScrollPosition(element?: Element): void {
    if (!element)
      element = this.document.getElementsByClassName('content')[0];

    this.lastScrollPosition.set([0, element.scrollTop]);
  }
}
