import { computed, inject } from '@angular/core';
import { PageSettingsStore } from './page-settings.store';
import { equals } from '@softline/core';
import { ComponentSettings, PageSettings } from './page-settings';

export class PageSettingsService {

  readonly store = inject(PageSettingsStore);

  pageSettings = computed<PageSettings>(() => {
    const state = this.store.settings.dict();
    return state[this.name] ?? {name: this.name, components: [] as ComponentSettings[]};
  })

  constructor(public name: string) { }

  async load(): Promise<void> {
    await this.store.repository.loadOnce(this.name, {});
  }

  set(componentSettings: ComponentSettings): void {
    const pageSettings = this.pageSettings();
    const index = pageSettings.components.findIndex(o => o.name === componentSettings.name);
    if(index > -1 && equals(componentSettings, pageSettings.components[index]))
      return;
    const components = [...pageSettings.components];
    if(index > -1)
      components.splice(index, 1, componentSettings);
    else
      components.push(componentSettings);
    this.store.settings.addOrUpdate({...pageSettings, components});
    this.store.repository.update({value: {...pageSettings, components}});
  }

}
