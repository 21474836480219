import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import { MessageBarStore, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule } from "@softline/ui-core";
import {
    BackNavigable,
    BackNavigationService, BlockingLoadingSpinnerComponent, showRequestErrors, HeaderComponent,
    ScannerModule, ScannerStore,
    SOFTLINE_FEATURE_SCANNER,
    SOFTLINE_FEATURE_TITLE, Step, StepHeaderComponent,
    TitleStore
} from "@softline/application";
import { asapScheduler, BehaviorSubject, observeOn, skip, Subscription } from "rxjs";
import { Store } from "@softline/core";
import { Router } from "@angular/router";
import { LagerplatzUmlagerungStore } from "../../store";
import { SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG } from "../../lagerplatz-umlagerung.shared";
import { Lagerplatz, LagerplatzSucheComponent } from "@softapps/lager/core";

@Component({
  selector: 'soft-quelllagerplatz-scannen',
  standalone: true,
    imports: [CommonModule, UiCoreModule, ScannerModule, StepHeaderComponent, BlockingLoadingSpinnerComponent, LagerplatzSucheComponent, HeaderComponent],
  templateUrl: './quelllagerplatz-scannen.page.html',
  styleUrls: ['./quelllagerplatz-scannen.page.scss']
})
export class QuelllagerplatzScannenPage implements OnInit, OnDestroy, BackNavigable {

  readonly steps: Step[] = [
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.QUELLLAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.QUELLLAGERPLATZ_SCANNEN.DESCRIPTION'
    },
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.ARTIKEL_AUSWAHL.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.ARTIKEL_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.MENGEN_AUSWAHL.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.MENGEN_AUSWAHL.DESCRIPTION'
    },
    {
      header: '#LAGERPLATZ_UMLAGERUNG.PAGES.ZIELLAGERPLATZ_SCANNEN.TITLE',
      subHeader: '#LAGERPLATZ_UMLAGERUNG.PAGES.ZIELLAGERPLATZ_SCANNEN.DESCRIPTION'
    },
  ];

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store,
              private router: Router,
              private backNavigationService: BackNavigationService) {
  }

  async ngOnInit(): Promise<void> {
    this.backNavigationService.set(this);
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '#LAGERPLATZ_UMLAGERUNG.TITLE')
  }

  ngOnDestroy() {
    this.store.commit(SOFTLINE_FEATURE_TITLE, TitleStore.mutations.setTitle, '')
    this.backNavigationService.set(undefined);
  }

  async navigateBack(): Promise<void> {
    await this.router.navigate(['/'])
  }

  async onLagerplatzSelected(lagerplatz: Lagerplatz): Promise<void> {
    console.log('[LAGERPLATZ SCANNED 1]', lagerplatz);
    if (!lagerplatz)
      return;

    this.loading$.next(true);
    try {
      await this.store.dispatch(SOFTLINE_FEATURE_LAGERPLATZ_UMLAGERUNG, LagerplatzUmlagerungStore.actions.loadQuellLagerplatz, lagerplatz.barcode);
      this.loading$.next(false);
      await this.router.navigate(['/lagerplatz-umlagerung/artikel-auswahl'])
    }
    catch (e) {
      this.loading$.next(false);
      showRequestErrors(this.store, e);
    }
  }
}
