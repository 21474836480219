import { Inject, Injectable } from "@angular/core";
import { ResourceService, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable } from "rxjs";
import {
  SOFTLINE_API_LAGERKORREKTUR,
  SOFTLINE_API_SCAN_LAGERPLATZ
} from "../lagerkorrektur.api";
import { LagerplatzInhalt, Lagersymbol } from "@softapps/lager/core";

@Injectable()
export class LagerkorrekturService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ResourceService<any>) {
  }

  getQuelllagerplatz(code: string): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_SCAN_LAGERPLATZ,
      pathParams: {code}
    }
    return this.service.get(location);
  }

  lagerkorrektur(lagerplatzInhalt: LagerplatzInhalt, lagersymbol: Lagersymbol) {
    const location = { path: SOFTLINE_API_LAGERKORREKTUR }
    return this.service.get(location, {
      lagerplatzInhalt,
      lagersymbol
    });
  }
}
