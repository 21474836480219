import {Inject, Injectable} from '@angular/core';
import { Scan } from '@softline/application';
import {Observable, of} from 'rxjs';
import {WarenverprobungBelegeingabeService} from './warenverprobung-belegeingabe-service';
import {Beleg} from '../data/beleg';
import {SOFTLINE_API_MDE_WARENVERPROBUNG_FIND_BELEG} from '../warenverprobung.api';
import {ConnectionHttpService, SOFTLINE_SERVICE_HTTP} from '@softline/core';

@Injectable()
export class WarenuebernahmeBelegeingabeService implements WarenverprobungBelegeingabeService {

  get inputLabel(): string {
    return 'Warenübernahme'
  }

  get inputPlaceholder(): string {
    return 'Bezugnsummer'
  }

  get pageTitle(): string {
    return 'Warenübernahme eingeben'
  }

  constructor(
    @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ConnectionHttpService
  ) { }

  scan(scan: Scan): Observable<Beleg> {
    console.log('[WarenuebernahmeBelegeingabeService] scan');

    return this.httpService.get({
      path: 'v1/warenuebernahme/scan/{{code}}',
      pathParams: { code: scan.data }
    });
  }

  find(belegnumer: string): Observable<Beleg> {
    console.log('[WarenuebernahmeBelegeingabeService] find');
    return this.httpService.get({
      path: SOFTLINE_API_MDE_WARENVERPROBUNG_FIND_BELEG,
      queryParams: { nummer: belegnumer, variante: 'WARENUEBERNAHME' }
    });
  }
}
