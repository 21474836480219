import { Observable } from 'rxjs';
import {
  Dictionary,
  DictionaryRepository,
  Id,
  IndexedDbResourceLocation,
  LoadCollectionItemParameters,
  LoadDictionaryParameters,
  ResourceService,
  SaveDictionaryParameters,
  toIdString
} from '@softline/core';
import { AuthenticationContextStore2 } from '@softline/auth';
import { inject } from '@angular/core';
import { SOFTLINE_NAME_USER_INDEXED_DB } from '../../softapps-core.shared';
import { map } from 'rxjs/operators';

export class UserIndexedDbDictionaryRepository<T, TResult> implements DictionaryRepository<T, TResult> {

  authStore = inject(AuthenticationContextStore2);

  constructor(protected service: ResourceService<IndexedDbResourceLocation>,
              protected objectStoreName: string) {
  }

  loadItem(id: Id, params: LoadCollectionItemParameters): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: toIdString(id),
      params: { id: this.authStore.userId() }
    };
    return this.service.get<any, any>(location)
      .pipe(map(o => o?.value));
  }

  load(params?: LoadDictionaryParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      params: { id: this.authStore.userId() }
    };
    return this.service.get<any, any>(location)
      .pipe(map(o => o.value.map(p => p.value)));
  }

  save(dictionary: Dictionary<T>, params?: SaveDictionaryParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      params: { id: this.authStore.userId() }
    };
    return this.service.update<any, any>(location, dictionary)
      .pipe(map(o => o.value.map(p => p.value)));
  }

  saveItem(key: string, value: T, params?: SaveDictionaryParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: key,
      params: { id: this.authStore.userId() }
    };
    return this.service.update<any, any>(location, { key, value })
      .pipe(map(o => o.value));
  }

  deleteItem(key: string, params?: SaveDictionaryParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      key: key,
      params: { id: this.authStore.userId() }
    };
    return this.service.delete<any, any>(location)
      .pipe(map(o => o.value));
  }

  delete(params?: SaveDictionaryParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: SOFTLINE_NAME_USER_INDEXED_DB,
      objectStoreName: this.objectStoreName,
      params: { id: this.authStore.userId() }
    };
    return this.service.delete<any, any>(location)
      .pipe(map(o => o.value.map(p => p.value)));
  }
}
