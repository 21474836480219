import { Pipe, PipeTransform } from '@angular/core';
import {isDefinedNotEmpty} from "@softline/core";

@Pipe({
  name: 'default',
  standalone: true,
})
export class DefaultPipe implements PipeTransform {
  transform<TIn, TOut = TIn>(value: TIn, replace: TOut): TIn | TOut {
    return isDefinedNotEmpty(value) ? value : replace;
  }
}
