import { Component, forwardRef, input, output, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingState } from '@softline/core';
import { PanelViewBase, UiCoreModule } from '@softline/ui-core';
import { RemoteDataContainerComponent } from '../../components/remote-data-container/remote-data-container.component';
import { RemoteDataPanelViewBase } from '../remote-data-panel-view.base';

@Component({
  selector: 'soft-master-detail-panel-view',
  standalone: true,
  imports: [CommonModule, RemoteDataContainerComponent, UiCoreModule],
  templateUrl: './master-detail.panel-view.html',
  styleUrl: './master-detail.panel-view.scss',
  providers: [
    {provide: PanelViewBase, useExisting: forwardRef(() => MasterDetailPanelView)},
    {provide: RemoteDataPanelViewBase, useExisting: forwardRef(() => MasterDetailPanelView)}
  ]
})
export class MasterDetailPanelView<T> extends RemoteDataPanelViewBase<T[]> {
  override name = input.required<string>();
  override label = input<string>('Tabelle');
  override icon = input<string>('fa-regular fa-table-layout');
  override template = viewChild<TemplateRef<any>>('template');
  override data = input<T[] | null>(null);
  override loadingState = input<LoadingState>(null);
  override loadingError = input<Error | null>(null);

  selectionChange = output<T | null>();

  masterTemplate = input<TemplateRef<any> | null>(null);
  detailTemplate = input<TemplateRef<any> | null>(null);
}
