import { NavigationExtras } from '@angular/router';

export type MenuItem = CommandMenuItem | RouteMenuItem | LinkMenuItem;

 interface IMenuItem {
  type: string;
  name: string;
  outlet: string | string[];
  priority?: number;
  title?: string;
  description?: string;
  icon?: string;
  src?: string;
  badge?: string;
  class?: string;
  visible?: boolean;
  children?: MenuItem[];
}

export interface CommandMenuItem extends IMenuItem {
  type: 'command';
}

export interface RouteMenuItem extends IMenuItem {
  type: 'route';
  routerLink: any[];
  routerLinkParams?: Pick<NavigationExtras, 'queryParams' | 'queryParamsHandling' | 'fragment' | 'onSameUrlNavigation' | 'preserveFragment' | 'replaceUrl' | 'skipLocationChange'>;
  target?: string;
}

export interface LinkMenuItem extends IMenuItem {
  type: 'link';
  src: string;
  target?: string;
}
