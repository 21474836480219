import {Routes} from '@angular/router';
import {AuthenticationGuard, AuthorizationGuard} from '@softline/auth';
import {RemoteConfigResolver} from '@softline/application';
import {BestellerfassungResolver} from './resolver/bestellerfassung.resolver';
import { PageSettingsService } from '@softapps/core';

export const bestellerfassungRoutes: Routes = [
  {
    path: 'mde-bestellung',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./components/bestellerfassung.component').then(
            (o) => o.BestellerfassungComponent
          ),
        children: [
          {
            path: 'lieferant',
            loadComponent: () => import('./pages/start-wizard/start-wizard.component')
          },
          {
            path: 'liefertermin',
            loadComponent: () => import('./pages/liefertermin/liefertermin.component').then(
              o => o.LieferterminComponent
            )
          },
          {
            path: 'lagerort',
            loadComponent: () => import('./pages/lagerort/lagerort.component').then(
              o => o.LagerortComponent
            )
          },
          {
            path: 'artikel-erfassen',
            loadComponent: () => import('./pages/artikel-erfassen/artikel-erfassen.component').then(
              c => c.ArtikelErfassenComponent
            ),
            loadChildren: () => [
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt1/schritt1.component').then(
                  c => c.Schritt1Component
                ),
                path: 'suche'
              },
              {
                loadComponent: () => import('./pages/artikel-erfassen/schritt2/schritt2.component').then(
                  c => c.Schritt2Component
                ),
                path: ':idartstamm',
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/mde-bestellung/artikel-erfassen/suche'
              }
            ]
          },
          {
            path: '',
            loadComponent: () => import('./pages/erfassung/erfassung.component').then(
              o => o.ErfassungComponent
            )
          },
          {
            path: '**',
            redirectTo: '/mde-bestellung'
          },
        ],
      },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: { permission: 'BESTELLUNG' },
    resolve: { config: RemoteConfigResolver, bestellerfassung: BestellerfassungResolver },
    providers: [],
  },
];
