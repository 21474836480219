import { Component, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { string } from 'mathjs';

@Component({
  selector: 'soft-page-tab',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-tab.component.html',
  styleUrl: './page-tab.component.scss',
})
export class PageTabComponent {
  name = input.required<string>();
  title = input.required<string>();
  icon = input<string>();
  template = viewChild(TemplateRef);
}
