import {Component, Input} from "@angular/core";
import {CommonModule} from '@angular/common';
import {FieldOkItemComponent} from "@softline/dynamic";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vertreter.list-view.html',
  styleUrls: ['./vertreter.list-view.scss'],
})
export class VertreterListView implements FieldOkItemComponent<any>{

  @Input() item: any;

  constructor() {}
}
