import {Component, Input} from "@angular/core";
import {CommonModule} from '@angular/common';
import {FieldOkItemComponent} from "@softline/dynamic";

@Component({
  selector: 'soft-field-ok-data-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './kondgruppe.list-view.html',
  styleUrls: ['./kondgruppe.list-view.scss'],
})
export class KondgruppeListView implements FieldOkItemComponent<any> {

  @Input() item: any;

  constructor() {}
}
