import {Injectable} from '@angular/core';
import {
  BulkEntityService,
  Change, ChangeSuccess,
  ConnectionHttpService,
  ConnectionResourceLocation,
  CreateChange, DeleteChange,
  LazySaveResult,
  ResponseResult
} from '@softline/core';
import {concat, forkJoin, map, Observable, of} from 'rxjs';
import {SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH} from '../auftrag.api';
import {Arbeitsbericht} from '../data/stempel';
import {toArray} from 'rxjs/operators';

@Injectable()
export class BulkArbeitsberichtService implements BulkEntityService<Arbeitsbericht> {

  constructor(private connectionHttpService: ConnectionHttpService) {}

  getTimeOnly(time: string): string {
    return time.substring(1, 9);
  }

  save(changes: Readonly<Change<Arbeitsbericht>[]>): Observable<LazySaveResult<Arbeitsbericht>> {
    const arbeitsberichtCreateChanges = changes.filter(o => o.action === 'create') as CreateChange<Arbeitsbericht>[];
    const arbeitsberichtDeleteChanges = changes.filter(o => o.action === 'delete') as DeleteChange<Arbeitsbericht>[];

    const artikel = arbeitsberichtCreateChanges.map(o => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...entityWithoutUUID } = o.entity
      entityWithoutUUID['from'] = this.getTimeOnly(entityWithoutUUID['from'] ?? '')
      entityWithoutUUID['to'] = this.getTimeOnly(entityWithoutUUID['to'] ?? '')
      return entityWithoutUUID
    });

    const artikelIdsForDeletion = arbeitsberichtDeleteChanges.map(o => o.id)

    if ((!artikel || artikel?.length < 1) && artikelIdsForDeletion.length < 1)
      return of({ failed: [], succeeded: [] });

    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH
    };

    return forkJoin([
      concat(
        ...artikel.map(art =>
          this.connectionHttpService
            .create<Omit<Arbeitsbericht, 'id'>, ResponseResult<Arbeitsbericht>>(location, art)
            .pipe(map(o => o?.result))
        )
      ).pipe(toArray()),
      concat(
        ...artikelIdsForDeletion.map(id =>
          this.connectionHttpService
            .delete<ResponseResult<Arbeitsbericht>>({ path: SOFTLINE_API_WERKSTAETTENAUFTRAG_WORK_REPORT_RESOURCE_PATH + '/' + id })
            .pipe(map((r) => r?.result))
        )
      ).pipe(toArray())
    ]).pipe(
      map(([createResults, deleteResults]) => ({
        failed: [],
        succeeded: [
          ...createResults.map(entity => ({ action: 'create', entity }) as ChangeSuccess<Arbeitsbericht>),
          ...deleteResults.map(entity => ({ action: 'delete', entity }) as ChangeSuccess<Arbeitsbericht>),
        ]
      }))
    );
  }
}
