import {ModuleWithProviders, NgModule} from '@angular/core';
import {SOFTLINE_STORE_FEATURES, Store, StoreModule} from '@softline/core';
import {
  SOFTLINE_CONFIG_DEVELOPER_PASSWORD,
  SOFTLINE_CONST_DEFAULT_DEVELOPER_PASSWORD,
  SOFTLINE_FEATURE_DEVELOPER,
  SOFTLINE_SETTINGS_DEVELOPER
} from './developer.shared';
import {DeveloperStore} from './developer.store';
import {DeveloperSettingsComponent} from './settings/developer-settings.component';
import {DeveloperService} from './service/developer.service';
import {SOFTLINE_CONFIG_SETTINGS} from '../application.shared';
import {DeveloperStore2} from './developer.store2';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [],
})
export class DeveloperModule {
  static forRoot(): ModuleWithProviders<DeveloperRootModule> {
    return {
      ngModule: DeveloperRootModule,
      providers: [ {
        provide: SOFTLINE_CONFIG_SETTINGS,
        multi: true,
        useValue: {
          key: SOFTLINE_SETTINGS_DEVELOPER,
          component: DeveloperSettingsComponent,
          title: '#APPLICATION.DEVELOPER.SETTINGS.TITLE',
          icon: 'fa-regular fa-screwdriver-wrench',
          priority: -1
        },
      }],
    };
  }
}

@NgModule({
  imports: [
    DeveloperModule,
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_DEVELOPER,
      feature: DeveloperStore.feature,
    }),
  ],
  providers: [
    DeveloperService,
    {provide: SOFTLINE_CONFIG_DEVELOPER_PASSWORD, useValue: SOFTLINE_CONST_DEFAULT_DEVELOPER_PASSWORD},
    {provide: SOFTLINE_STORE_FEATURES, multi: true, useExisting: DeveloperStore2}
  ]
})
export class DeveloperRootModule {
  constructor(private store: Store) {
    this.store.dispatch(SOFTLINE_FEATURE_DEVELOPER, DeveloperStore.actions.load);
  }
}
