@if(salesUnit(); as unit) {
    <div class="flex flex-row justify-between items-center flex-wrap">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <span class="text-lg font-bold mr-2">
            {{unit.unit.key}}
          </span>
          <span class="">
            ({{unit.unit.name}})
          </span>
        </div>
        <div class="flex flex-row">
          <span class="font-semibold text-sm mr-2">Faktor:</span>
          <span>{{unit.factor  | number:'1.2-5'}}</span>
        </div>
      </div>
      <div class="min-h-[82px]">
        @if (unit.ean) {
          <soft-barcode [data]="unit.ean" [labelType]="'ean13'" size="sm"></soft-barcode>
        }
      </div>
    </div>
}
