import { TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

export type GalleryImage = (Blob | string | Promise<Blob | string> | Observable<Blob | string>);

export interface GalleryModalConfig {
  title?: string;
  subtitle?: string;
  content?: string | TemplateRef<any>;
  images: GalleryImage[];
  index: number;
  class?: string;
  params?: object;
}
