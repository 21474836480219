import { ResourceService } from '../../data/abstraction';
import { CollectionRepository, CRUDCollectionParameters, Id } from '../abstraction';
import { StorageResourceLocation } from '../../data/specialized/storage/storage.location';
import { interpolate } from '../../functions/interpolate.function';
import { mergeMap, Observable } from 'rxjs';
import { ReadonlyStorageCollectionRepository } from './readonly-storage-collection.repository';
import { map } from 'rxjs/operators';
import { toIdString } from '../functions/to-id-string';
import { Dictionary } from '../../types/dictionary';

export class StorageCollectionRepository<TItem> extends ReadonlyStorageCollectionRepository<TItem> implements CollectionRepository<TItem, TItem, TItem[]> {

  constructor(service: ResourceService<StorageResourceLocation>,
              key: string) {
    super(service, key);
  }

  create(id: Id | null | undefined, value: TItem, params?: CRUDCollectionParameters): Observable<TItem> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    const idString = toIdString(id);
    return this.service.get<Dictionary<TItem>>(location)
      .pipe(
        map(o => {
          const item = o[idString];
          if(item)
            throw new Error(`[StorageCollectionDataService]create: Object with id '${id}' already exists in collection '${this.key}'`);
          return o;
        }),
        map(o => ({ ...o, [idString]: value })),
        mergeMap(dict => this.service.create<Dictionary<TItem>, Dictionary<TItem>>(location, dict)),
        map(_ => value)
      );
  }

  update(id: Id, value: TItem, params?: CRUDCollectionParameters): Observable<TItem> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    const idString = toIdString(id);
    return this.service.get<Dictionary<TItem>>(location)
      .pipe(
        map(o => {
          const item = o[idString];
          if(!item)
            throw new Error(`[StorageCollectionDataService]update: Object with id '${id}' does not exist in collection '${this.key}'`);
          return o;
        }),
        map(o => ({ ...o, [idString]: value })),
        mergeMap(dict => this.service.update<Dictionary<TItem>, Dictionary<TItem>>(location, dict)),
        map(_ => value)
      );
  }

  patch(id: Id, changes: Partial<TItem>, params?: CRUDCollectionParameters): Observable<TItem> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    const idString = toIdString(id);
    return this.service.get<Dictionary<TItem>>(location)
      .pipe(
        map(o => {
          const item = o[idString];
          if(!item)
            throw new Error(`[StorageCollectionDataService]patch: Object with id '${id}' does not exist in collection '${this.key}'`);
          return {o, item};
        }),
        map(({o, item}) => ({ ...o, [idString]: {...item, ...changes} })),
        mergeMap(dict => this.service.update<Dictionary<TItem>, Dictionary<TItem>>(location, dict)),
        map(o => o[idString] as TItem)
      );
  }

  delete(id: Id, params?: CRUDCollectionParameters): Observable<TItem> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    const idString = toIdString(id);
    return this.service.get<Dictionary<TItem>>(location)
      .pipe(
        map(dict => {
          const item = dict[idString];
          if(!item)
            throw new Error(`[StorageCollectionDataService]patch: Object with id '${id}' does not exist in collection '${this.key}'`);
          return {dict, item};
        }),
        map(({dict, item}) => {
          dict = { ...dict };
          delete dict[idString];
          return {dict, item}
        }),
        mergeMap(({ dict, item }) =>
          this.service.update<Dictionary<TItem>, Dictionary<TItem>>(location, dict)
            .pipe(
              map(_ => item)
            )),
      );
  }

}
