import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store2 } from '@softline/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { JsonObjectTreeComponent, ModalBase } from '@softline/ui-core';

@Component({
  selector: 'soft-store',
  standalone: true,
  imports: [CommonModule, JsonObjectTreeComponent],
  templateUrl: './store.component.html',
  styleUrl: './store.component.scss',
})
export class StoreComponent extends ModalBase<any>{
  store = inject(Store2);
  state = toSignal(this.store.state$);
}
