import {
  createAction,
  createGetter,
  createMutation,
  CustomStore,
  mutate,
  on,
  select,
  StoreBuilder
} from "@softline/core";
import {InventurService} from '../services/inventur.service';
import {lastValueFrom} from 'rxjs';
import {ArtikelDetailStore} from '@softapps/wws/core';
import {SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS, SOFTLINE_FEATURE_MDE_INVENTUR_BEWE} from "../inventur.shared";
import {InventurBeweStore} from './inventur-bewe.store';
import {ItemScan, Scan} from '@softline/application';
import {InventurKopf} from '../data/inventur-kopf';
import {InventurLager} from '../data/inventur-lager';

export interface InventurState {
  kopf?: InventurKopf | null
  lager?: any | null
  lagerplatz?: any | null
  belegdatum?: any | null
  endeEtikett?: string | null;
  itemScanResult?: ItemScan
}

const mutations = {
  setKopf: createMutation<InventurState, InventurKopf | undefined>('mdeInventur/setKopf'),
  setLager: createMutation<InventurState, any | null>('mdeInventur/setLager'),
  setLagerplatz: createMutation<InventurState, any | null>('mdeInventur/setLagerplatz'),
  setBelegdatum: createMutation<InventurState, string | null>('mdeInventur/setBelegdatum'),
  setItemScanResult: createMutation<InventurState, ItemScan | undefined>('mdeInventur/setItemScanResult'),
  setEndeEtikett: createMutation<InventurState, string | undefined>('mdeInventur/setEndeEtikett'),
}

const getters = {
  kopf: createGetter<InventurState, InventurKopf | null | undefined>('mdeInventur/getKopf'),
  lager: createGetter<InventurState, InventurLager | null | undefined>('mdeInventur/getLager'),
  lagerplatz: createGetter<InventurState, any | null | undefined>('mdeInventur/getLagerplatz'),
  belegdatum: createGetter<InventurState, string | null | undefined>('mdeInventur/getBeledatum'),
  itemScanResult: createGetter<InventurState, ItemScan | undefined>('mdeInventur/getItemScanResult'),
  endeEtikett: createGetter<InventurState, string | undefined>('mdeInventur/getEndeEtikett'),
}

const actions = {
  abschluss: createAction<InventurState, { schwundlisteDrucken: boolean, differenz: number }, void>('mdeInventur/abschluss'),
  loadKopf: createAction<InventurState, void, InventurKopf | undefined | null>('mdeInventur/loadKopf'),
  loadDefaultLager: createAction<InventurState, void, InventurLager | undefined | null>('mdeInventur/loadDefaultLager'),
  getLagerplatz: createAction<InventurState, Scan, any>('mdeInventur/getLagerplatz'),
  removeAll: createAction<InventurState, void, void>('mdeInventur/removeAll'),
}

const inventurStore: CustomStore<
  InventurState,
  typeof mutations,
  typeof getters,
  typeof actions
> = {
  mutations,
  getters,
  actions,
  feature: {
    initialState: {
      kopf: null,
      lager: null,
      endeEtikett: null
    },
    mutations: [
      mutate(mutations.setKopf, ({ params: kopf, state }) => ({
        ...state,
        kopf
      })),
      mutate(mutations.setLager, ({ params: lager, state }) => ({
        ...state,
        lager
      })),
      mutate(mutations.setLagerplatz, ({ params: lagerplatz, state }) => ({
        ...state,
        lagerplatz
      })),
      mutate(mutations.setBelegdatum, ({ params: belegdatum, state }) => ({
        ...state,
        belegdatum
      })),
      mutate(mutations.setEndeEtikett, ({ params: endeEtikett, state }) => ({
        ...state,
        endeEtikett
      })),
      mutate(mutations.setItemScanResult, ({ params, state }) => ({
        ...state,
        itemScanResult: params
      }))
    ],
    getters: [
      select(getters.kopf, ({ state }) => state?.kopf),
      select(getters.lager, ({ state }) => state?.lager),
      select(getters.lagerplatz, ({ state }) => state?.lagerplatz),
      select(getters.belegdatum, ({ state }) => state?.belegdatum),
      select(getters.itemScanResult, ({ state }) => state?.itemScanResult),
      select(getters.endeEtikett, ({ state }) => state?.endeEtikett),
    ],
    actions: [
      on(actions.loadKopf, async ({ injector, featureName, commit, get }) => {
        const service = injector.get(InventurService);
        const storeKopf = get(featureName, getters.kopf);

        if (storeKopf)
          return storeKopf;

        const result = await lastValueFrom(service.getKopf());
        commit(featureName, mutations.setKopf, result);
        return result ?? null
      }),
      on(actions.loadDefaultLager, async ({ injector, featureName, get }) => {
        const service = injector.get(InventurService);
        const result = await lastValueFrom(service.getDefaultLager());
        return result ?? null
      }),
      on(actions.removeAll, async ({ injector, featureName, get, commit }) => {
        const service = injector.get(InventurService);
        try {
          await lastValueFrom(service.removeAll());
          commit(SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
          commit(SOFTLINE_FEATURE_MDE_INVENTUR_BEWE, InventurBeweStore.mutations.clear);
          commit(featureName, mutations.setKopf, null);
          commit(featureName, mutations.setLager, null);
          commit(featureName, mutations.setBelegdatum, null);
          commit(featureName, mutations.setItemScanResult, null);
        } catch (e) {
          console.log(e);
        }
      }),
      on(actions.abschluss, async ({ injector, featureName, params, commit }) => {
        const service = injector.get(InventurService);
        await lastValueFrom(service.abschluss(params));
        commit(SOFTLINE_FEATURE_MDE_INVENTUR_ARTIKEL_DETAILS, ArtikelDetailStore.mutations.clear);
        commit(SOFTLINE_FEATURE_MDE_INVENTUR_BEWE, InventurBeweStore.mutations.clear);
        commit(featureName, mutations.setKopf, null);
        commit(featureName, mutations.setLager, null);
        commit(featureName, mutations.setBelegdatum, null);
        commit(featureName, mutations.setItemScanResult, null);
      }),
      on(actions.getLagerplatz, async ({ injector, featureName, params, commit }) => {
        const service = injector.get(InventurService);
        const scanResult = await lastValueFrom(service.getLagerplatz(params));
        return scanResult
      })
    ],
  }
}

export const InventurStore = new StoreBuilder(inventurStore)
  .value;
