import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import { StoreModule } from "@softline/core";
import { SOFTLINE_FEATURE_LAGERSTAND, SOFTLINE_PERMISSION_LAGERSTAND } from "./lagerstand.shared";
import { LagerstandStore } from "./store";
import { LagerstandService } from "./services/lagerstand.service";

export const lagerstandRoutes: Routes = [
  {
    path: 'lagerstand',
    children: [
      { path: ":id", loadComponent: () => import('./pages/lagerstand-ergebnis/lagerstand-ergebnis.page').then(o => o.LagerstandErgebnisPage)},
      { path: '', loadComponent: () => import('./pages/artikel-eingabe/artikel-eingabe.page').then(o => o.ArtikelEingabePage) },
      { path: '**', redirectTo: '/inventur' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_LAGERSTAND},
    providers: [
      LagerstandService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_LAGERSTAND,
          feature: LagerstandStore.feature,
        }),
      ),
    ]
  },
];
