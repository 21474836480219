import {
  Component,
  computed,
  DestroyRef,
  effect,
  input,
  output,
  signal,
  untracked,
  WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MetaControl,
  MetaControlNameDirective,
  MetaForm,
  MetaFormDirective,
  MetaFormHelper,
  WithMetaForm
} from '@softline/dynamic';
import { equals, isDefined } from '@softline/core';
import { MetaQueryBarItemComponent } from './meta-query-bar-item/meta-query-bar-item.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LogPipe } from '@softline/ui-core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'soft-meta-query-bar',
  standalone: true,
  imports: [CommonModule, MetaControlNameDirective, MetaQueryBarItemComponent, ReactiveFormsModule, MetaFormDirective, LogPipe],
  templateUrl: './meta-query-bar.component.html',
  styleUrl: './meta-query-bar.component.scss',
})
export class MetaQueryBarComponent<T extends object>
  extends WithMetaForm()
{
  _formInitialized = signal(false);
  override form = new FormGroup({});
  private formValue = toSignal(this.form.valueChanges);

  metaForm = input.required<MetaForm<T>>({alias: 'metaForm'});

  metaFormEffect = effect(() => {
    const metaForm = this.metaForm();
    MetaFormHelper.updateMeta(this.form, metaForm as any);
    this._formInitialized.set(true);
  }, {allowSignalWrites: true});

  query = input.required<T | null>();
  queryEffect = effect(() => {
    const query = this.query();
    const formInitialized = this._formInitialized();
    if(!formInitialized || !query)
      return;
    this.form.patchValue(query, {emitEvent: false});
  }, {allowSignalWrites: true});

  queryChange = output<T | null>();

  items = computed(() => {
    const meta = this.metaForm();
    const query = this.query();
    const formInitialized = this._formInitialized();

    const returnValue: {key: string, meta: MetaControl<any>}[] = [];
    if(!formInitialized || !query || !meta)
      return returnValue;

    for(const [key, value] of Object.entries(query)) {
      const control = meta[key];
      if(isDefined(value) && isDefined(control))
        returnValue.push({key, meta: control});
    }
    return returnValue;
  })
  constructor(destroyRef: DestroyRef) {
    super();
    this.form.valueChanges.pipe(
      takeUntilDestroyed(destroyRef),
    ).subscribe(value => {
      if(!this._formInitialized() || equals(value, this.query()))
        return;
      this.queryChange.emit(value as any);
    })
  }
}
