import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule
} from "@softline/core";
import {
  SOFTLINE_FEATURE_LAGERKORREKTUR_ARTIKEL, SOFTLINE_FEATURE_UMBUCHUNGSARTIKEL,
  SOFTLINE_PERMISSION_LAGERKORREKTUR_ARTIKEL
} from "./lagerkorrektur-artikel.shared";
import { LagerkorrekturArtikelStore, UmbuchungsartikelStore } from "./store";
import { LagerkorrekturArtikelService } from "./services/lagerkorrektur-artikel.service";
import { SOFTLINE_API_UMBUCHUNGSARTIKEL } from "./lagerkorrektur-artikel.api";

export const lagerkorrekturArtikelRoutes: Routes = [
  {
    path: 'lagerkorrektur-artikel',
    children: [
      { path: 'umbuchungsartikel-auswahl', loadComponent: () => import('./pages/umbuchungsartikel-auswahl/umbuchungsartikel-auswahl.page').then(o => o.UmbuchungsartikelAuswahlPage)},
      { path: 'mengen-auswahl', loadComponent: () => import('./pages/mengen-auswahl/mengen-auswahl.page').then(o => o.MengenAuswahlPage)},
      { path: 'artikel-auswahl', loadComponent: () => import('./pages/artikel-auswahl/artikel-auswahl.page').then(o => o.ArtikelAuswahlPage)},
      { path: '', loadComponent: () => import('./pages/lagerplatz-scannen/lagerplatz-scannen.page').then(o => o.LagerplatzScannenPage) },
      { path: '**', redirectTo: '/lagerkorrektur-artikel' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_LAGERKORREKTUR_ARTIKEL},
    providers: [
    ]
  },
];
