import { Component, forwardRef, input, TemplateRef, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingState } from '@softline/core';
import { RemoteDataPanelViewBase } from '../remote-data-panel-view.base';
import { PanelViewBase } from '@softline/ui-core';
import { RemoteDataContainerComponent } from '../../components/remote-data-container/remote-data-container.component';

@Component({
  selector: 'soft-table-panel-view',
  standalone: true,
  imports: [CommonModule, RemoteDataContainerComponent],
  templateUrl: './table.panel-view.html',
  styleUrl: './table.panel-view.scss',
  providers: [
    {provide: PanelViewBase, useExisting: forwardRef(() => TablePanelView)},
    {provide: RemoteDataPanelViewBase, useExisting: forwardRef(() => TablePanelView)}
  ]
})
export class TablePanelView<T> extends RemoteDataPanelViewBase<T[]> {
  override name = input.required<string>();
  override label = input<string>('Tabelle');
  override icon = input<string>('fa-regular fa-table');
  override template = viewChild<TemplateRef<any>>('template');
  override data = input<T[] | null>(null);
  override loadingState = input<LoadingState>(null);
  override loadingError = input<Error | null>(null);
}
