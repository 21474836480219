import { InjectionToken } from '@angular/core';
import { PdfEditorConfig } from './data/pdf-editor-config';

export const SOFTLINE_CONFIG_PDF_EDITOR = new InjectionToken<PdfEditorConfig[]>(
  'SOFTLINE_CONFIG_PDF_EDITOR'
);

export const SOFTLINE_FEATURE_PDF_EDITOR_COMPONENT = 'pdfEditorComponent';
export const SOFTLINE_FEATURE_PDF_EDITOR_SIGNATURES = 'pdfEditorSignatures';
export const SOFTLINE_FEATURE_PDF_EDITOR = 'pdfEditor';

export const SOFTLINE_DB_PDF_EDITOR = 'pdfEditor';
export const SOFTLINE_CONST_CUSTOM_TOOLS_OBJECT_STORE = 'customTools';
