<div class="soft-card" *ngIf="state$ | async as state">
  <p class="c row space-between">
    <span class="mr-2 font-semibold">Profil:</span> {{ state.activeProfile }}
  </p>
  <p class="c row space-between">
    <span class="mr-2 font-semibold">Datawedge Version:</span>
    {{ state.versionInfo?.DATAWEDGE }}
  </p>
  <div class="c row end mt-4">
    <button
      class="soft-button primary"
      (click)="onResetClick()"
      [disabled]="resetting"
    >
      <i *ngIf="resetting" class="fa-regular fa-spinner fa-spin mr-2"></i>
      Profil zurücksetzen
    </button>
  </div>
</div>
