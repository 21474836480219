import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  select,
  StoreFeature,
} from '@softline/core';
import { NavigationExtras, Router } from '@angular/router';

export interface State {
  loading: boolean;
  saving: boolean;
  files: PdfFile[];
}

export interface PdfFile {
  id: string | number;
  data: Blob;
  name: string;
  fromRoute: { url: any[]; extras?: NavigationExtras };
  edited: boolean;
}

export interface OpenPdfEditorParams {
  id: string | number;
  data: Blob;
  name: string;
  fromRoute: { url: any[]; extras?: NavigationExtras };
}

export interface EditPdfParams {
  id: string | number;
  data: Blob;
}

// tslint:disable-next-line:no-namespace
export namespace PdfEditorStore {
  const initialState: State = {
    loading: false,
    saving: false,
    files: [],
  };

  export const mutations = {
    loading: createMutation<State, boolean>('pdf editor loading'),
    saving: createMutation<State, boolean>('pdf editor saving'),
    add: createMutation<State, PdfFile>('pdf editor add'),
    edit: createMutation<State, EditPdfParams>('pdf editor edit'),
    remove: createMutation<State, string>('pdf editor remove'),
  };

  export const actions = {
    open: createAction<State, OpenPdfEditorParams, any>('pdf editor open'),
  };

  export const getters = {
    loading: createGetter<State, boolean>('pdf editor loading'),
    document: createGetter<State, PdfFile, string>('pdf editor document'),
  };

  export const feature: StoreFeature<State> = {
    initialState,
    mutations: [
      mutate(mutations.loading, ({ state, params }) => ({
        ...state,
        loading: params,
      })),
      mutate(mutations.saving, ({ state, params }) => ({
        ...state,
        saving: params,
      })),
      mutate(mutations.add, ({ state, params }) => {
        const files = [...state.files];
        const index = state.files.findIndex((o) => o.id === params.id);
        params = { ...params, edited: false };
        if (index > -1) files.splice(index, 1, params);
        else files.push(params);
        state = { ...state, files };
        return state;
      }),
      mutate(mutations.edit, ({ state, params }) => {
        const files = [...state.files];
        const index = state.files.findIndex((o) => o.id === params.id);
        if (index > -1) {
          let file = state.files[index];
          file = { ...file, data: params.data, edited: true };
          files.splice(index, 1, file);
        }
        state = { ...state, files };
        return state;
      }),
      mutate(mutations.remove, ({ state, params }) => {
        const files = [...state.files];
        const index = state.files.findIndex((o) => o.id === params);
        if (index > -1) files.splice(index, 1);
        state = { ...state, files };
        return state;
      }),
    ],
    actions: [
      on(
        actions.open,
        async ({ featureName, injector, state, get, commit, params }) => {
          const router = injector.get(Router);
          if (!get(featureName, getters.document, params.id))
            commit(featureName, mutations.add, params);
          return await router.navigate(['/pdf-editor', params.id]);
        }
      ),
    ],
    getters: [
      select(getters.loading, ({ state }) => state.loading),
      select(getters.document, ({ state, params }) =>
        state.files.find((o) => o.id === params)
      ),
    ],
  };
}
