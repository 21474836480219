import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  Scanner,
  ScannerService,
  SOFTLINE_CONFIG_SCANNER,
} from '@softline/application';
import { ZebraService } from './services/zebra.service';
import {
  SOFTLINE_CONFIG_DATAWEDGE_PROFILE,
  SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION,
  SOFTLINE_CONFIG_DATAWEDGE_SCAN_CATEGORY,
  SOFTLINE_FEATURE_ZEBRA,
} from './zebra.shared';
import { Store, StoreModule } from '@softline/core';
import { ZebraStore } from './zebra.store';
import { ZebraScannerSettingsComponent } from './settings/zebra-scanner-settings.component';
import { CommonModule } from '@angular/common';

export const SOFTLINE_CONST_DATAWEDGE_PROFILE = 'SoftApps';
export const SOFTLINE_CONST_DATAWEDGE_SCAN_ACTION = 'at.softline.SOFTAPPS';
export const SOFTLINE_CONST_DATAWEDGE_SCAN_CATEGORY =
  'android.intent.category.DEFAULT';

export function zebraScannerFactory(service: ScannerService): Scanner {
  return {
    name: 'zebra',
    priority: 101,
    title: 'Hardware (Zebra)',
    service,
    settingsComponent: ZebraScannerSettingsComponent,
  };
}

@NgModule({
  declarations: [ZebraScannerSettingsComponent],
  imports: [CommonModule],
  exports: [],
  providers: [],
})
export class ZebraModule {
  static forRoot(): ModuleWithProviders<ZebraRootModule> {
    return {
      ngModule: ZebraRootModule,
      providers: [
        ZebraService,
        {
          provide: SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION,
          useValue: SOFTLINE_CONST_DATAWEDGE_SCAN_ACTION,
        },
        {
          provide: SOFTLINE_CONFIG_DATAWEDGE_SCAN_CATEGORY,
          useValue: SOFTLINE_CONST_DATAWEDGE_SCAN_CATEGORY,
        },
        {
          provide: SOFTLINE_CONFIG_DATAWEDGE_PROFILE,
          useValue: SOFTLINE_CONST_DATAWEDGE_PROFILE,
        },
        {
          provide: SOFTLINE_CONFIG_SCANNER,
          multi: true,
          useFactory: zebraScannerFactory,
          deps: [ZebraService],
        },
      ],
    };
  }
}

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature({
      name: SOFTLINE_FEATURE_ZEBRA,
      feature: ZebraStore.feature,
    }),
  ],
  exports: [],
  providers: [],
})
export class ZebraRootModule {
  // Service has to be injected here for first creation and registering the broadcast-receiver
  // otherwise the Side scanner button will only work after the first software side scan trigger
  constructor(service: ZebraService, store: Store) {
    this.init(service, store);
  }

  async init(service: ZebraService, store: Store): Promise<void> {
    await store.dispatch(SOFTLINE_FEATURE_ZEBRA, ZebraStore.actions.init);
  }
}
