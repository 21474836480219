import { Inject, Injectable } from "@angular/core";
import { ResourceService, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable } from "rxjs";
import {
  SOFTLINE_API_KOMMSCAN,
  SOFTLINE_API_KOPFKOMM_ABSCHLUSS,
  SOFTLINE_API_KOPFKOMM_BEGLEITSCHEINDRUCK, SOFTLINE_API_KOPFKOMM_SPERRE,
  SOFTLINE_API_LAGERPLATZVORSCHLAG,
  SOFTLINE_API_SCAN_LAGERPLATZ
} from "../kommissionierung.api";
import { Kommscan } from "../types/kommscan";
import { KommissionsauftragKopf } from "../types/kommissionsauftrag";
import { ArtikelMenge, LagerplatzInhalt } from "@softapps/lager/core";

@Injectable()
export class KommissionierungService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ResourceService<any>) {
  }

  getLagerplatz(code: string): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_SCAN_LAGERPLATZ,
      pathParams: {code}
    }
    return this.service.get(location);
  }

  getLagerplatzVorschlag(artikalmenge: ArtikelMenge): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_LAGERPLATZVORSCHLAG
    }
    return this.service.get(location, artikalmenge);
  }

  artikelKommissionierung(kommscan: Kommscan): Observable<KommissionsauftragKopf> {
    const location = { path: SOFTLINE_API_KOMMSCAN }
    return this.service.get(location, kommscan);
  }

  abschluss(id: number): Observable<KommissionsauftragKopf> {
    const location = {
      path: SOFTLINE_API_KOPFKOMM_ABSCHLUSS,
      pathParams: {id}
    }
    return this.service.create(location, undefined);
  }

  begleitscheinDruck(id: number, anzahl: number): Observable<KommissionsauftragKopf> {
    const location = {
      path: SOFTLINE_API_KOPFKOMM_BEGLEITSCHEINDRUCK,
      pathParams: {id},
      queryParams: {anzahl}
    }
    return this.service.create(location, undefined);
  }

  sperren(id: number, force: boolean = false): Observable<KommissionsauftragKopf> {
    const location = {
      path: SOFTLINE_API_KOPFKOMM_SPERRE,
      pathParams: {id},
      queryParams: {force}
    }
    return this.service.create(location, undefined);
  }
}
