import {
  createAction,
  createGetter,
  createMutation,
  mutate,
  on,
  StoreFeature,
} from '@softline/core';
import { VersionInfo } from './types/version-info';
import { Scanner } from './types/scanner';
import { IntentDelivery, Profile, ProfileConfig } from './types/profile-config';
import { ZebraService } from './services/zebra.service';
import {
  SOFTLINE_CONFIG_DATAWEDGE_PROFILE,
  SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION,
  SOFTLINE_CONFIG_DATAWEDGE_SCAN_CATEGORY,
} from './zebra.shared';
import { AppInfoService } from '@softline/application';

export namespace ZebraStore {
  export interface State {
    activeProfile?: string;
    versionInfo?: VersionInfo;
    scanners?: Scanner[];
    config?: Profile;
  }

  export const mutations = {
    patch: createMutation<State, Partial<State>>('patch'),
  };

  export const actions = {
    init: createAction<State>('init'),

    deleteProfile: createAction<State, string>('deleteProfile'),
    createProfile: createAction<State, string>('createProfile'),
    assignApp: createAction<State, string>('assignApp'),

    loadVersionInfo: createAction<State, VersionInfo>('load version info'),
    loadScanners: createAction<State, undefined, Scanner[]>('load scanner'),
    setScanner: createAction<State, Scanner>('set scanner'),
    setConfig: createAction<State, string, undefined>('set config'),
    loadActiveProfile: createAction<State, undefined, Profile>(
      'load active profile'
    ),
  };

  export const getters = {
    versionInfo: createGetter<State, VersionInfo>('version info'),
    scanners: createGetter<State, Scanner[]>('scanners'),
  };

  export const feature: StoreFeature<State> = {
    initialState: {},
    mutations: [
      mutate(mutations.patch, ({ state, params }) => ({ ...state, ...params })),
    ],
    actions: [
      on(
        actions.init,
        async ({ injector, get, dispatch, featureName, commit }) => {
          console.log('Zebra: Initialize Datawedge');
          const service = injector.get(ZebraService);
          const profile = injector.get(SOFTLINE_CONFIG_DATAWEDGE_PROFILE);

          if(!service.isAvailable)
            return;

          let activeProfile = await service.loadActiveProfile();
          const versionInfo = await service.loadVersionInfo();
          const profilesList = await service.loadProfilesList();

          if (activeProfile !== profile) {
            if (profilesList.indexOf(profile) === -1)
              await dispatch(featureName, actions.createProfile, profile);
            await dispatch(featureName, actions.assignApp, profile);
            activeProfile = await service.loadActiveProfile();
          }

          const config = await service.loadConfig(profile);
          commit(featureName, mutations.patch, {
            activeProfile,
            versionInfo,
            config,
          });
        }
      ),

      on(actions.createProfile, async ({ injector, params }) => {
        console.log(`Zebra: Creating new Profile \'${params}\'`);
        const service = injector.get(ZebraService);
        await service.createProfile(params);
        const scanAction = injector.get(SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION);
        const scanCategory = injector.get(
          SOFTLINE_CONFIG_DATAWEDGE_SCAN_CATEGORY
        );

        await service.setConfig({
          PROFILE_NAME: params,
          PROFILE_ENABLED: 'true',
          CONFIG_MODE: 'UPDATE',
          PLUGIN_CONFIG: [
            {
              PLUGIN_NAME: 'INTENT',
              RESET_CONFIG: 'true',
              PARAM_LIST: {
                intent_output_enabled: 'true',
                intent_action: scanAction,
                intent_category: scanCategory,
                intent_delivery: IntentDelivery.Broadcast,
              },
            },
          ],
        });

        await service.setConfig({
          PROFILE_NAME: params,
          PROFILE_ENABLED: 'true',
          CONFIG_MODE: 'UPDATE',
          PLUGIN_CONFIG: [
            {
              PLUGIN_NAME: 'BARCODE',
              RESET_CONFIG: 'false',
              PARAM_LIST: {
                scanner_selection: 'auto',
                scanner_input_enabled: 'true',
                decoder_code93: 'true',
                decoder_i2of5: 'true',
              },
            },
          ],
        } as any);
      }),
      on(actions.assignApp, async ({ injector, params }) => {
        console.log(`Zebra: Associating Profile \'${params}\' with the app`);
        const service = injector.get(ZebraService);
        const appInfo = await injector.get(AppInfoService).getAppInfo();

        await service.setConfig({
          PROFILE_NAME: params,
          PROFILE_ENABLED: 'true',
          CONFIG_MODE: 'UPDATE',
          APP_LIST: [
            {
              PACKAGE_NAME: appInfo.id,
              ACTIVITY_LIST: ['*'],
            },
          ],
        });
      }),
      on(actions.deleteProfile, async ({ injector, params }) => {
        console.log(`Zebra: Deleting Profile \'${params}\'`);
        const service = injector.get(ZebraService);
        await service.deleteProfile(params);
      }),
    ],
    getters: [],
  };
}
