import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MessageBarStore, Modal, SOFTLINE_FEATURE_MESSAGE_BAR, UiCoreModule} from '@softline/ui-core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {CommonModule} from '@angular/common';
import {Auftrag} from '../../data/auftrag';
import {SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE} from '../../auftrag.shared';
import {Store} from '@softline/core';
import * as WerkstattAuftraegeStore from '../../store/werkstatt-auftraege.store'

@Component({
  selector: 'soft-edit-header-text-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiCoreModule,
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './edit-header-text-dialog.component.html',
  styleUrls: ['./edit-header-text-dialog.component.scss']
})
export class EditHeaderTextDialogComponent implements OnInit, Modal<null> {

  close!: (result: null) => void;
  task!: Auftrag;

  readonly form = this.fb.group({
    headerText: ['', Validators.required]
  });

  // loading$ = this.store.select(fromTaskSelectors.selectors.selectAnyLoading);
  readonly loading$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form.patchValue({ headerText: this.task?.description ?? '' });
  }

  async onSubmit(): Promise<void> {
    this.loading$.next(true);
    await this.saveHeaderText();
  }

  private async saveHeaderText(): Promise<void> {
    try {
      const { archiveKey, ...entity } = this.task;
      entity.description = this.form.value?.headerText ?? '';

      await this.store.dispatch(
        SOFTLINE_FEATURE_WERKSTAETTENAUFTRAG_AUFTRAEGE,
        WerkstattAuftraegeStore.actions.update,
        { entity }
      );

      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        { title: 'Kopftext wurde erfolgreich geändert' }
      );

      this.close(null);
    } catch (e) {
      await this.showErrorMessage();
    } finally {
      this.loading$.next(false)
    }
  }

  registerCloseHandler(handler: (result: null) => void): void {
    this.close = handler;
  }

  private async showErrorMessage(): Promise<void> {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.error,
      {
        container: 'EDIT_HEADER_MSG_BAR',
        duration: 10000,
        title: '#WORKSHOP.DIALOGS.ERROR_SUBJECT',
        message: '#WORKSHOP.DIALOGS.EDIT_HEADER.ERROR',
        actions: [{
          title: '#WORKSHOP.DIALOGS.TRY_AGAIN',
          type: 'link',
          onExecute: async () => await this.saveHeaderText()
        }],
      }
    );
  }
}
