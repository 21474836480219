export interface Price {
  fromAmount: number;
  priceUnit: number;
  grossPrice: number;
  terms: string;
  per?: number;
  netPrice: number;
  netPriceIncl: number;
  additionalTerms: string | null;
}
