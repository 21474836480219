import {Command} from '@softline/application';
import {ModalStore, SOFTLINE_FEATURE_MODAL} from '@softline/ui-core';
import {Store} from '@softline/core';
import {Auftrag} from '../data/auftrag';
import {EditHeaderTextDialogComponent} from '../dialogs/edit-header-text-dialog/edit-header-text-dialog.component';

export class EditHeaderTextCommand implements Command {
  icon = 'fa-regular fa-pen-to-square';
  name = '#WORKSHOP.TASK_DETAILS.COMMANDS.EDIT_HEADER';
  class = 'menu action-menu action-menu-top';
  execute = async () => await this.showEditHeaderTextDialog();

  constructor(private store: Store, private task: () => Auftrag) {}

  private showEditHeaderTextDialog = async (): Promise<void> => {
    await this.store.dispatch(
      SOFTLINE_FEATURE_MODAL,
      ModalStore.actions.open(),
      {
        id: 'EDIT_HEADER_TEXT',
        component: EditHeaderTextDialogComponent,
        data: { task: this.task() },
        dismiss: { button: true, backdrop: true }
      }
    );
  }
}
