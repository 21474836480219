import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import {
  SOFTLINE_SERVICE_HTTP,
  SOFTLINE_STORE_ENTITY_SERVICE,
  SoftlineEntityService,
  StoreModule
} from "@softline/core";
import { SOFTLINE_FEATURE_KOMMISSIONIERUNG, SOFTLINE_PERMISSION_KOMMISSIONIERUNG } from "./kommissionierung.shared";
import { KommissionierungStore } from "./store";
import { KommissionierungService } from "./services/kommissionierung.service";
import { SOFTLINE_API_KOPFKOMM } from "./kommissionierung.api";

export const kommissionierungRoutes: Routes = [
  {
    path: 'kommissionierung',
    children: [
      { path: 'filter', loadComponent: () => import('./pages/filter/filter.page').then(o => o.FilterPage) },
      { path: ':idkopfkomm/abschluss', loadComponent: () => import('./pages/abschluss/abschluss.page').then(o => o.AbschlussPage)},
      { path: ':idkopfkomm/:idbewekomm', loadComponent: () => import('./pages/artikel-menge/artikel-menge.page').then(o => o.ArtikelMengePage)},
      { path: ':idkopfkomm', loadComponent: () => import('./pages/auftrag-artikel/auftrag-artikel.page').then(o => o.AuftragArtikelPage)},
      { path: '', loadComponent: () => import('./pages/auftrag-auswahl/auftrag-auswahl.page').then(o => o.AuftragAuswahlPage) },
      { path: '**', redirectTo: '/kommissionierung' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_KOMMISSIONIERUNG},
    providers: [
      KommissionierungService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_KOMMISSIONIERUNG,
          feature: KommissionierungStore.feature,
          providers: [{
            provide: SOFTLINE_STORE_ENTITY_SERVICE,
            useFactory: (service) => new SoftlineEntityService(service, SOFTLINE_API_KOPFKOMM),
            deps: [SOFTLINE_SERVICE_HTTP]
          }]
        }),
      )
    ]
  },
];
