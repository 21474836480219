import { Store2Feature } from '@softline/core';
import { DeveloperService } from './service/developer.service';
import { computed, inject, Injectable } from '@angular/core';
import { SOFTLINE_CONFIG_DEVELOPER_PASSWORD } from './developer.shared';
import { DeveloperConfig } from './developer.store';

@Injectable({ providedIn: 'root' })
export class DeveloperStore2 extends Store2Feature<DeveloperConfig>{
  name = 'developer';
  service = inject(DeveloperService, { optional: true });
  password = inject(SOFTLINE_CONFIG_DEVELOPER_PASSWORD, { optional: true });

  isDeveloper = computed(() => this.state().isDeveloper);

  constructor() {
    super();
  }

  async load(): Promise<DeveloperConfig> {
    if (!this.service)
      return {
        isDeveloper: false
      }

    const settings = await this.service.load();
    this.commit(settings);
    return settings;
  }

  async su(password: string, remember: boolean): Promise<DeveloperConfig> {
    if (!this.service)
      return {
        isDeveloper: false
      }

    if(this.password !== password)
      throw new Error('[DeveloperStore] Password is wrong')

    const newState = {isDeveloper: true}
    this.commitPatch(newState)
    if(remember)
      await this.service.save(newState);
    return newState
  }

  async exit(): Promise<void> {
    this.commit({ isDeveloper: false })
    await this.service?.delete();
  }

  override getDefaultState(): DeveloperConfig {
    return {
      isDeveloper: false
    }
  }
}
