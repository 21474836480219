@if(items(); as items) {
  <div cdkDropListGroup class="grid grid-cols-1 px-4 md:px-0 md:grid-cols-2 gap-4 w-full mt-4 xl:px-4">
    <div class="flex flex-col max-h-[60vh]">
      <h3 class="text-light text-sm">{{ '#UI_CORE.COMPONENTS.DRAG_AND_DROP_SELECT.AVAILABLE' | translate }}</h3>
      <div cdkDropList
           [cdkDropListData]="availableValues()"
           class="column-list flex flex-col mt-1 border border-solid border-outline-500 overflow-y-auto min-h-[50vh] h-[50vh] max-h-[50vh]"
           (cdkDropListDropped)="onAvailableColumnsDropped($event, value(), availableValues())">
        @for(item of availableItems(); track item; let last = $last) {
          <div class="column-item py-2 px-3 text-base flex w-full items-center bg-surface justify-between hover:cursor-move" [ngClass]="'border-b border-b-solid border-b-outline-100'" cdkDrag>
            <ng-container *ngTemplateOutlet="item.template() ?? noTemplate"></ng-container>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-lightest" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
        }
      </div>
    </div>

    <div class="flex flex-col max-h-[60vh]">
      <h3 class="text-light text-sm">{{ '#UI_CORE.COMPONENTS.DRAG_AND_DROP_SELECT.CHOSEN' | translate }}</h3>
      <div cdkDropList
           [cdkDropListData]="value()"
           class="column-list flex flex-col mt-1 border border-solid border-outline-500 overflow-y-auto min-h-[50vh] h-[50vh] max-h-[50vh]"
           (cdkDropListDropped)="onChosenColumnsDropped($event, value(), availableValues())">
        @for(item of chosenItems(); track item; let last = $last) {
          <div class="column-item py-2 px-3 text-base flex w-full items-center bg-surface justify-between hover:cursor-move" [ngClass]="'border-b border-b-solid border-b-outline-100'" cdkDrag>
            <ng-container *ngTemplateOutlet="item.template() ?? noTemplate"></ng-container>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-lightest" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
        }
      </div>
    </div>
  </div>
}

<ng-template #noTemplate>
  No Template
</ng-template>
