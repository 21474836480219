import { Inject, Injectable } from "@angular/core";
import { ResourceService, SOFTLINE_SERVICE_HTTP } from "@softline/core";
import { Observable } from "rxjs";
import { SOFTLINE_API_INVENTUR, SOFTLINE_API_SCAN_LAGERPLATZ } from "../inventur.api";
import { LagerplatzInhalt } from "@softapps/lager/core";

@Injectable()
export class InventurService {
  constructor(@Inject(SOFTLINE_SERVICE_HTTP) private service: ResourceService<any>) {
  }

  getLagerplatz(code: string): Observable<LagerplatzInhalt> {
    const location = {
      path: SOFTLINE_API_SCAN_LAGERPLATZ,
      pathParams: {code}
    }
    return this.service.get(location);
  }

  inventur(inventur: LagerplatzInhalt) {
    const location = { path: SOFTLINE_API_INVENTUR }
    return this.service.get(location, inventur);
  }
}
