import { InjectionToken } from '@angular/core';

export const SOFTLINE_FEATURE_ZEBRA = 'zebra';

export const SOFTLINE_CONFIG_DATAWEDGE_PROFILE = new InjectionToken<string>(
  'SOFTLINE_CONFIG_DATAWEDGE_PROFILE'
);

export const SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION = new InjectionToken<string>(
  'SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION'
);
export const SOFTLINE_CONFIG_DATAWEDGE_SCAN_CATEGORY =
  new InjectionToken<string>('SOFTLINE_CONFIG_DATAWEDGE_SCAN_CATEGORY');
