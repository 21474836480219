import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsDefinedNotEmptyPipe } from '@softline/ui-core';
import { Product } from '@softapps/wws/core';

@Component({
  selector: 'soft-product-card',
  standalone: true,
  imports: [CommonModule, IsDefinedNotEmptyPipe],
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
})
export class ProductCardComponent {
  product = input<Product | null>(null);
}
