<soft-page>
  <soft-page-header>
    @if(!smBreakpoint() && !!masterDetail?.selected()) {
      <ng-container *ngTemplateOutlet="masterTemplate() ?? null; context: {data: masterDetail?.selected()}"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="header()?.template() ?? null"></ng-container>
    }
  </soft-page-header>
  <soft-remote-data-container [data]="data()"
                              [loadingState]="loadingState()"
                              [loadingError]="loadingError()">
    <soft-master-detail [data]="data()"
                        [masterTemplate]="masterTemplate()"
                        [detailTemplate]="detailTemplate()"
                        [detailPlaceholder]="detailPlaceholder()"
                        [detailHeaderVisible]="false"
                        (selectedChange)="selectedChange.emit($event)"
                        #masterDetail>
    </soft-master-detail>
  </soft-remote-data-container>
  <ng-content></ng-content>
</soft-page>
