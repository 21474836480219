import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestLogStore } from '../request-log.store';
import { ModalBase } from '@softline/ui-core';
import { RequestLog } from '../request-log';
import { RequestLogComponent } from './request-log/request-log.component';

@Component({
  selector: 'soft-request-log-list',
  standalone: true,
  imports: [CommonModule, RequestLogComponent],
  templateUrl: './request-log-list.component.html',
  styleUrl: './request-log-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestLogListComponent extends ModalBase<any> {
  store = inject(RequestLogStore);
  logs = computed<RequestLog[]>(() => this.store.state())
}
