import {
  ActionStore,
  createAction,
  createGetter,
  createMutation,
  KeyStore,
  mutate,
  on,
  select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { Lagerkorrektur } from "../types/lagerkorrektur";
import { lastValueFrom } from "rxjs";
import { LagerkorrekturService } from "../services/lagerkorrektur.service";
import { ArtikelMenge, LagerplatzInhalt, Lagersymbol } from "@softapps/lager/core";

export interface State extends ActionStore.State, SubscriptionStore.State, KeyStore.State  {
  lagerplatz?: LagerplatzInhalt;
  artikel?: ArtikelMenge;
  lagersymbol?: Lagersymbol;
};

export class LagerkorrekturStore {
  static mutations = {
    setLagerplatz: createMutation<State, LagerplatzInhalt>('setLagerplatz'),
    setArtikel: createMutation<State, ArtikelMenge>('setArtikel'),
    setLagersymbol: createMutation<State, Lagersymbol>('setLagersymbol'),
    reset: createMutation<State>('reset'),
  };
  static getters = {
    lagerplatz: createGetter<State, LagerplatzInhalt>('quellLagerplatz'),
    artikel: createGetter<State, ArtikelMenge>('artikel'),
    lagersymbol: createGetter<State, Lagersymbol>('lagersymbol')
  };
  static actions = {
    loadLagerplatz: createAction<State, string, LagerplatzInhalt>('loadLagerplatz'),
    lagerkorrektur: createAction<State, Lagerkorrektur>('lagerkorrektur')
  };

  static feature: StoreFeature<State> = {
    initialState: {
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...SubscriptionStore.feature.initialState
    },
    mutations: [
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      mutate(LagerkorrekturStore.mutations.setLagerplatz,
        ({state, params}) => ({...state, lagerplatz: params})
      ),
      mutate(LagerkorrekturStore.mutations.setArtikel,
        ({state, params}) => ({...state, artikel: params})
      ),
      mutate(LagerkorrekturStore.mutations.setLagersymbol,
        ({state, params}) => ({...state, lagersymbol: params})
      ),
      mutate(LagerkorrekturStore.mutations.reset,
        ({state, params}) => ({...state, lagerplatz: undefined, artikel: undefined, lagersymbol: undefined})
      )
    ],
    getters: [
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(LagerkorrekturStore.getters.lagerplatz, ({state}) => state.lagerplatz),
      select(LagerkorrekturStore.getters.artikel, ({state}) => state.artikel),
      select(LagerkorrekturStore.getters.lagersymbol, ({state}) => state.lagersymbol)
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(LagerkorrekturStore.actions.loadLagerplatz, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerkorrekturService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getQuelllagerplatz(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerkorrekturStore.actions.loadLagerplatz.name,
            token
          )
        );
        commit(featureName, LagerkorrekturStore.mutations.setLagerplatz, result);
        return result;
      }),
      on(LagerkorrekturStore.actions.lagerkorrektur, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(LagerkorrekturService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.lagerkorrektur(params.lagerplatzInhalt, params.lagersymbol),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            LagerkorrekturStore.actions.lagerkorrektur.name,
            token
          )
        );
        commit(featureName, LagerkorrekturStore.mutations.reset);
        return result;
      })
    ]
  }
}
