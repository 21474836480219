import {
  computed,
  effect,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  OnInit, runInInjectionContext,
  Signal,
  signal
} from '@angular/core';
import {
  base64Decode,
  base64Encode,
  CancelledError,
  CollectionStore2, Dictionary, isDefinedNotEmpty, LoadCollectionParameters,
  NestedStore2Feature, QueryState,
  QueryStore2,
  ReadonlyRepositoryCollectionStore2,
  RepositoryCollectionStore2, SOFTLINE_SERVICE_UUID, Store
} from '@softline/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { distinct } from 'rxjs';
import { showRequestErrors } from '@softline/application';
import { WithCollection } from './collection.mixin';


type Constructor<T extends {}> = new (...args: any[]) => T;
type QueryCollectionRepositoryMixinParams<
  T extends object,
  TQuery extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>
> = {
  store: InjectionToken<TStore>;
  repositoryFeature: (o: TStore) => ReadonlyRepositoryCollectionStore2<T>;
  collectionFeature: (o: TStore) => CollectionStore2<T>;
  queryFeature: (o: TStore) => QueryStore2<TQuery>;
};

export const WithQueryCollectionRepository = <
  T extends object,
  TQuery extends object,
  TStore extends NestedStore2Feature<any> = NestedStore2Feature<any>,
  TBase extends Constructor<{}> = Constructor<{}>
> (
  params: QueryCollectionRepositoryMixinParams<T, TQuery, TStore>,
  Base: TBase = class {} as any
) => {
  @Injectable()
  abstract class QueryCollectionRepositoryMixin
    extends WithCollection<T, TStore, TBase>({store: params.store, feature: params.collectionFeature}, Base)
    implements OnInit, OnDestroy
  {
    #token: string | null = null;
    #destroying = false;

    #injector = inject(Injector);
    #uuid = inject(SOFTLINE_SERVICE_UUID);
    #store = inject(Store);

    #router = inject(Router);
    #route = inject(ActivatedRoute);

    #repositoryStore: ReadonlyRepositoryCollectionStore2<T>;
    #queryStore: QueryStore2<TQuery>;
    #routeQueryParams = toSignal(this.#route.queryParams, {initialValue: {}});

    routeQuery = computed<string>(() => {
      return this.#routeQueryParams()['query'];
    });

    pathParams = signal<Dictionary<unknown> | undefined>(undefined);

    limit = computed(() => this.#queryStore.limit());
    offset = computed(() => this.#queryStore.offset());
    sort = computed(() => this.#queryStore.sort());
    query = computed(() => this.#queryStore.query());

    loadingState = computed(() => this.#repositoryStore.loadingState());

    queryParams = computed<LoadCollectionParameters>(() => {
      const pathParams = this.pathParams();
      const limit = this.limit();
      const offset = this.offset();
      const sort = this.sort();
      const query = this.query() ?? {};
      return { pathParams, limit, offset, sort, query }
    })

    constructor(...args: any[]) {
      super(...args);
      const injectedStore = inject(params.store);
      this.#queryStore = params.queryFeature(injectedStore);
      this.#repositoryStore = params.repositoryFeature(injectedStore);
    }

    override async ngOnInit(): Promise<void> {
      if(super['ngOnInit'])
        super['ngOnInit']();

      const routeQuery = this.routeQuery();
      if(routeQuery) {
        try {
          const query = JSON.parse(base64Decode(routeQuery)) as QueryState<any>;
          this.#queryStore.setState(query);
        }
        catch (e) {
          console.warn('Cannot parse query params', routeQuery, e);
        }
      }

      runInInjectionContext(this.#injector, () => {
        toObservable(this.queryParams)
          .pipe(
            distinct(),
            takeUntilDestroyed(),
          )
          .subscribe(o => {
            this.setRouteQueryParams();
            this.load();
          })
      });
    }

    override ngOnDestroy(): void {
      this.#destroying = true;
      this.cancelLoad();
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async load(): Promise<void> {
      try {
        if(this.#token)
          await this.#repositoryStore.cancel(this.#token);
        this.#token = this.#uuid();
        const params = this.queryParams() ?? {};
        await this.#repositoryStore.loadMany({...params, token: this.#token, clear: true });
      }
      catch (e) {
        if(!this.#destroying && !(e instanceof CancelledError))
          showRequestErrors(this.#store, e);
      }
      finally {
        this.#token = null;
      }
    }

    cancelLoad(): void {
      if(!this.#token)
        return;
      this.#repositoryStore.cancel(this.#token);
    }

    setQuery(query: TQuery | null): void {
      console.log('set query: ', query);
      this.#queryStore.setQuery(query);
    }

    private async setRouteQueryParams(): Promise<void> {
      const query = this.query();
      const limit = this.limit();
      const offset = this.offset();
      const sort = this.sort();
      if(!isDefinedNotEmpty(query))
        return;
      await this.#router.navigate([], {
        queryParams: {query: base64Encode(JSON.stringify({  query, limit, offset, sort }))},
        relativeTo: this.#route,
        queryParamsHandling: 'merge'
      });
    }
  }
  return QueryCollectionRepositoryMixin;
};
