import {Price} from './price';
import {Unit} from './unit';

export interface SalesPrice {
  id: number;
  unit: Unit;
  salesOrganisation: {
    key: string;
  }
  salesOrganisationGroup: {
    key: string;
  }
  validFrom: string;
  validTo: string;
  priceLevel: {
    key: string;
    name: string;
    currency: string;
  }
  prices: Price[];
  basePrice: {
    netContent: number;
    factor: number;
    contentUnit: Unit;
    declarationUnit: Unit;
  }
}
