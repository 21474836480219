import { ResourceService } from '../../data/abstraction';
import { LoadObjectParameters, ReadonlyDictionaryRepository } from '../abstraction';
import { Observable } from 'rxjs';
import { IndexedDbResourceLocation } from '../../data/specialized/indexed-db/indexed-db.location';

export class ReadonlyIndexedDbDictionaryRepository<TResult> implements ReadonlyDictionaryRepository<TResult> {

  constructor(protected service: ResourceService<IndexedDbResourceLocation>,
              protected databaseName: string,
              protected objectStoreName: string) { }

  load(params?: LoadObjectParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
    };
    return this.service.get(location);
  }

  loadItem(key: string, params?: LoadObjectParameters | undefined): Observable<TResult> {
    const location: IndexedDbResourceLocation = {
      databaseName: this.databaseName,
      objectStoreName: this.objectStoreName,
      key
    };
    return this.service.get(location);
  }
}
