<h4 class="font-semibold text-xl mt-2 font-semibold px-4 md:px-0">{{ '#WORKSHOP.DIALOGS.START_ACTIVITY.TITLE' | translate }}</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mt-3">
  <div class="flex flex-col px-4 md:px-0">
    <soft-value-converter formControlName="activity" [converter]="taetigkeitConverter">
      <soft-field-ok
        class="mt-3"
        [name]="'wtaetigkeit'"
        [template]="activityTemplate"
        [placeholder]="'#WORKSHOP.DIALOGS.START_ACTIVITY.ACTIVITY_LABEL' | translate"
        #convertValue>
      </soft-field-ok>
    </soft-value-converter>
    <ng-template let-entity="entity" #activityTemplate>
      <span>{{ entity.id }} - {{ entity?.title || entity?.bezeichnung }}</span>
    </ng-template>

    <soft-multiline-input
      [lines]="3"
      class="mt-3"
      formControlName="description"
      [placeholder]="'#WORKSHOP.DIALOGS.START_ACTIVITY.DESCRIPTION_LABEL' | translate">
    </soft-multiline-input>

    <soft-message-bar-container [name]="'START_ACTIVITY_MSG_BAR'">
    </soft-message-bar-container>
  </div>

  <div class="c row no-wrap w-full mt-3">
    <button class="soft-button primary w-full text-center h-14 fill-force md:mr-1.5" (click)="close(null)">{{ '#WORKSHOP.DIALOGS.ABORT' | translate }}</button>
    <button type="submit" class="soft-button accent h-14 primary fill-force text-center md:ml-1.5 w-full" [disabled]="form.invalid">
      {{ '#WORKSHOP.DIALOGS.START_ACTIVITY.START' | translate }}
    </button>
  </div>
</form>
