import {TemplateRef} from '@angular/core';
import {DismissConfig} from './modal-config';

export interface DateRange{
  from: string | null;
  to: string | null;
}

export interface DateRangeModalConfig {
  title?: string;
  subtitle?: string;
  content?: string | TemplateRef<any>;
  class?: string;
  value: DateRange | null;
  startField?: 'from' | 'to';
  dismiss?: boolean | DismissConfig;
}

export function isDateRange(value: unknown): value is DateRange {
  return (value as DateRange).from !== undefined && (value as DateRange).to !== undefined;
}
