import { Dictionary } from '@softline/core';

export interface SettingsComponent {
  applySettings(settings: Dictionary<unknown>): void;
  registerOnSettingsChange(callback: (settings: Dictionary<unknown>) => void): void;
}

export function isSettingsComponent(o: any): o is SettingsComponent {
  return o && typeof o.applySettings === 'function' && typeof o.registerOnSettingsChange === 'function';
}
