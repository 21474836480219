<ng-template #template>
  <soft-remote-data-container class="block sm:max-h-[50vh]"
                              [data]="data()"
                              [loadingState]="loadingState()"
                              [loadingError]="loadingError()">
    <soft-master-detail [data]="data() ?? []"
                        [masterTemplate]="masterTemplate()"
                        [detailTemplate]="detailTemplate()"
                        (selectedChange)="this.selectionChange.emit($event)">
    </soft-master-detail>
  </soft-remote-data-container>
</ng-template>
