import { Directive, inject, input, signal } from '@angular/core';
import { PanelComponent } from '@softline/ui-core';
import { PageSettingsDirectiveBase } from '../page-settings-directive.base';

@Directive({
  selector: '[softPageSettingsPanel]',
  standalone: true,
})
export class PanelSettingsDirective extends PageSettingsDirectiveBase<PanelComponent>{
  panel = inject(PanelComponent);

  override name = input.required<string>({alias: 'softPageSettingsPanel'});
  override component = signal(this.panel);

  constructor() {
    super();
  }
}
