<div class="sticky top-0 header">
  <div class="flex flex-col-reverse full-width bg-surface lg:bg-transparent -mt-2">
    <soft-step-header [steps]="steps" [currentIndex]="2" #stepper></soft-step-header>
    <soft-default-header [overline]="stepper.stepText"
                      [title]="stepper.current()?.header | translate"
                      [subtitle]="stepper.current()?.subHeader | translate"></soft-default-header>
  </div>
</div>

<div class="pb-20">
  <div class="soft-overline mt-4">
    {{'#INVENTUR.PAGES.ARTIKEL_AUSWAHL.MENGE' | translate}}
  </div>
  <soft-mengen-eingabe *ngIf="(artikel$ | async) as artikel"
                      class="mt-1"
                      [value]="artikel.menge"
                      [einheit]="artikel.einheit.arteh"
                      [float]="true"
                      (valueChange)="onMengeChange($event)"></soft-mengen-eingabe>
</div>
<button class="fixed left-0 right-0 bottom-0  soft-button primary w-full h-20 mt-16" (click)="onSubmit()">
  {{'#INVENTUR.PAGES.ARTIKEL_AUSWAHL.SUBMIT' | translate}}
</button>

<soft-blocking-loading-spinner *ngIf="loading$ | async" class="top-0 right-0"></soft-blocking-loading-spinner>
