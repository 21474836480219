<div class="sticky top-0">
    <h1 class="text-xl font-bold">{{'#LAGERKORREKTUR_ARTIKEL.TITLE' | translate}}</h1>
    <soft-step-header [steps]="steps"
                      [currentIndex]="1">
    </soft-step-header>
  </div>

  <div class="soft-overline mt-4">
    {{'#LAGERKORREKTUR_ARTIKEL.PAGES.UMBUCHUNGSARTIKEL_AUSWAHL.UMBUCHUNGSARTIKEL' | translate}}
  </div>
  <div class="max-h-[50vh] overflow-y-auto" *ngIf="umbuchungsartikel$ | async as umbuchungsartikel">
    <div *ngFor="let artikel of umbuchungsartikel; let i = index"
         class="soft-card border mt-1 p-6 rounded"
         (click)="onSelect(artikel)">
      <div class="flex flex-row flex-nowrap">
        <div class="flex flex-col">
          <span class="text-light text-xs">{{artikel.nummer}}</span>
          <span class="text-lg font-semibold">{{artikel.artbez1}}</span>
          <span class="text-xs">{{artikel.artbez2}}</span>
          <span class="text-xs">{{artikel.artbez3}}</span>
          <span class="text-xs">{{artikel.artbez4}}</span>
          <span class="text-xs">{{artikel.artbez5}}</span>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="loading$ | async" >
    <div *softRepeat="3" class="soft-card border mt-1 p-6 rounded">
      <div class="flex flex-row flex-nowrap w-full">
        <div class="flex flex-col w-full">
          <div class="h-3 skeleton w-1/2"></div>
          <div class="h-3 skeleton w-full mt-2"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <soft-blocking-loading-spinner *ngIf="saving$ | async" class="top-0 right-0"></soft-blocking-loading-spinner>
