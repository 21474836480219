import { Component, computed, EventEmitter, input, Output, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingState } from '@softline/core';
import { IconComponent, IsDefinedNotEmptyPipe, UiCoreModule } from '@softline/ui-core';
import { Definition, DynamicSkeletonComponent } from '@softline/dynamic';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, startWith } from 'rxjs';
import { showRequestErrors, requestErrorMessageFactory } from '@softline/application';

@Component({
  selector: 'soft-remote-data-container',
  standalone: true,
  imports: [
    CommonModule,
    IsDefinedNotEmptyPipe,
    IconComponent,
    UiCoreModule,
    DynamicSkeletonComponent,
  ],
  templateUrl: './remote-data-container.component.html',
  styleUrl: './remote-data-container.component.scss',
})
export class RemoteDataContainerComponent<T> {
  private debounceTime = 150;

  data = input.required<T>();

  loadingState = input.required<LoadingState>();
  loadingError = input<Error | null>(null);
  debouncedLoadingState$ = toObservable(this.loadingState).pipe(
    debounceTime(this.debounceTime),
  );
  private _debouncedLoadingState = toSignal(this.debouncedLoadingState$, {
    initialValue: null,
  });
  debouncedLoadingState = computed(() =>
    this._debouncedLoadingState() ?? this.loadingState()
  );

  errorMessage = computed(() => {
    const error = this.loadingError();
    if(!error)
      return null;
    return requestErrorMessageFactory(error);
  })

  @Output() cancel = new EventEmitter<void>();

  definition = input<Definition | null>(null);
  loadingTemplate = input<TemplateRef<any> | null>(null);

  emptyMessage = input<string | null>(null);
  failureMessage = input<string | null>(null);
  canceledMessage = input<string | null>(null);
}
