import { computed, inject, Injectable, signal } from '@angular/core';
import { SOFTLINE_SERVICE_UUID } from '@softline/core';
import { MessageBarAction, MessageBarConfig } from './data/message-bar.config';
import { SOFTLINE_CONFIG_MESSAGE_BAR_DURATION } from '@softline/ui-core';

export interface MessageBarParams {
  params?: object;
  container?: string;
  group?: string;
  actions?: MessageBarAction[];
  duration?: number;
  onDismiss?(): void;
}

@Injectable({providedIn: 'root'})
export class MessageBarService {
  private _messageBars = signal<readonly Readonly<MessageBarConfig>[]>([]);

  uuid = inject(SOFTLINE_SERVICE_UUID);
  duration = inject(SOFTLINE_CONFIG_MESSAGE_BAR_DURATION);
  messageBars = computed(() => this._messageBars());

  constructor() {}

  open(config: MessageBarConfig): string {
    const messageBars = [...this.messageBars()];
    const id = config.id ?? this.uuid();
    messageBars.push({...config, id});
    this._messageBars.set(messageBars);
    if ((config.duration ?? 0) < Number.POSITIVE_INFINITY) {
      setTimeout(() => {
        const messageBars = [...this.messageBars()];
        const index = messageBars.findIndex(o => o.id === id);
        if (index > -1)
          this.close(id);
      }, config.duration ?? this.duration);
    }
    return id;
  }

  close(id: string): string {
    const messageBars = [...this.messageBars()];
    const index = messageBars.findIndex(o => o.id === id);
    if (index === -1)
      throw new Error(`[MessageBarService] close: No message bar found with id ${id}`);
    messageBars.splice(index, 1);
    this._messageBars.set(messageBars);
    return id;
  }

  closeContainer(container: string | undefined): void {
    let messageBars = this.messageBars();
    messageBars = messageBars.filter(o => o.container === container);
    this._messageBars.set(messageBars);
  }

  closeAll(): void {
    this._messageBars.set([]);
  }

  success(message: string, title?: string, params?: MessageBarParams): string
  {
    const config: MessageBarConfig = {
      ...params,
      message,
      title: title ?? '#UI_CORE.MESSAGE_BAR.SUCCESS_TITLE',
      icon: 'fa-regular fa-circle-check',
      class: 'soft-message-bar-success',
      dismiss: {canDismiss: true}
    };
    return this.open(config);
  }

  warning(message: string, title?: string, params?: MessageBarParams): string
  {
    const config: MessageBarConfig = {
      ...params,
      message,
      title: title ?? '#UI_CORE.MESSAGE_BAR.WARNING_TITLE',
      icon: 'fa-regular fa-circle-exclamation',
      class: 'soft-message-bar-warning',
      dismiss: {canDismiss: true}
    };
    return this.open(config);
  }

  error(message: string, title?: string, params?: MessageBarParams): string
  {
    const config: MessageBarConfig = {
      ...params,
      message,
      title: title ?? '#UI_CORE.MESSAGE_BAR.ERROR_TITLE',
      icon: 'fa-regular fa-circle-xmark',
      class: 'soft-message-bar-error',
      dismiss: {canDismiss: true},
    };
    return this.open(config);
  }

  info(message: string, title?: string, params?: MessageBarParams): string
  {
    const config: MessageBarConfig = {
      ...params,
      message,
      title: title ?? '#UI_CORE.MESSAGE_BAR.INFO_TITLE',
      icon: 'fa-regular fa-circle-info',
      class: 'soft-message-bar-info',
      dismiss: {canDismiss: true},
    };
    return this.open(config);
  }

  loading(onCancel?: () => void, message?: string, title?: string, params?: MessageBarParams): string
  {
    const config: MessageBarConfig = {
      ...params,
      message,
      title: title ?? '#UI_CORE.MESSAGE_BAR.LOADING',
      icon: 'fa-regular fa-spinner fa-spin',
      class: 'soft-message-bar-loading',
      dismiss: {canDismiss: !!onCancel},
      onDismiss: onCancel,
    };
    return this.open(config);
  }

}
