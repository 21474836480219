import {Injectable} from '@angular/core';
import {
  BulkEntityService,
  Change,
  ConnectionHttpService,
  ConnectionResourceLocation, CreateChange,
  Dictionary,
  LazySaveResult, ResponseResult
} from '@softline/core';
import {AuftragArtikel} from '../data/auftrag-artikel';
import {map, Observable, of} from 'rxjs';
import {SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_RESOURCE_PATH} from '../auftrag.api';

@Injectable()
export class BulkAuftragArtikelService implements BulkEntityService<AuftragArtikel> {

  constructor(private connectionHttpService: ConnectionHttpService) {}

  save(changes: Readonly<Change<AuftragArtikel>[]>): Observable<LazySaveResult<AuftragArtikel>> {
    const artikelChanges = changes.filter(o => o.action === 'create')as CreateChange<AuftragArtikel>[]

    const artikel = artikelChanges.map(o => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...entityWithoutUUID } = o.entity
      return entityWithoutUUID
    });

    if (!artikel || artikel?.length < 1)
      return of({ failed: [], succeeded: [] })

    const location: ConnectionResourceLocation = {
      path: SOFTLINE_API_WERKSTAETTENAUFTRAG_TASK_ITEM_RESOURCE_PATH
    }

    return this.connectionHttpService
      .create<Omit<AuftragArtikel, 'id'>[], ResponseResult<AuftragArtikel[]>>(location, artikel)
      .pipe(
        map(o => ({
          failed: [],
          succeeded: o.result.map(entity => ({ action: 'create', entity }))
        }))
      )
  }
}
