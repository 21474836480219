import { Inject, Injectable, NgZone } from '@angular/core';
import {
  DateService,
  Logger,
  LogPriority,
  Store,
} from '@softline/core';
import {
  LabelType,
  Scan,
  ScannerService,
  ScannerStore,
  SOFTLINE_FEATURE_SCANNER,
} from '@softline/application';
import { Intent } from '../types/intent';
import { WebIntent } from '../types/web-intent';
import {
  FILTER_CATEGORY,
  INTENT_ACTION,
  INTENT_ACTION_RESULT,
  INTENT_EXTRA_CREATE_PROFILE,
  INTENT_EXTRA_DELETE_PROFILE,
  INTENT_EXTRA_ENUMERATE_SCANNERS,
  INTENT_EXTRA_GET_ACTIVE_PROFILE,
  INTENT_EXTRA_GET_ACTIVE_PROFILE_RESULT,
  INTENT_EXTRA_GET_CONFIG,
  INTENT_EXTRA_GET_CONFIG_RESULT,
  INTENT_EXTRA_GET_PROFILES_LIST,
  INTENT_EXTRA_GET_PROFILES_LIST_RESULT,
  INTENT_EXTRA_GET_VERSION_INFO,
  INTENT_EXTRA_GET_VERSION_INFO_RESULT,
  INTENT_EXTRA_SET_CONFIG,
  INTENT_EXTRA_SOFT_SCAN_TRIGGER,
  INTENT_EXTRA_SWITCH_TO_PROFILE,
} from '../zebra.commands';
import { SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION } from '../zebra.shared';
import { BehaviorSubject, filter, lastValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { VersionInfo } from '../types/version-info';
import { Profile, ProfileConfig } from '../types/profile-config';

// @ts-ignore
const webIntent = window?.plugins?.intentShim as WebIntent;

const LABEL_EXTRA = 'com.symbol.datawedge.label_type';
const DATA_EXTRA = 'com.symbol.datawedge.data_string';

@Injectable()
export class ZebraService extends ScannerService {
  private subject?: BehaviorSubject<Scan | undefined>;
  private actionSubject?: BehaviorSubject<any>;
  private active = false;

  constructor(
    private store: Store,
    private zone: NgZone,
    private logger: Logger,
    private dateService: DateService,
    @Inject(SOFTLINE_CONFIG_DATAWEDGE_SCAN_ACTION) private scanAction: string
  ) {
    super();
  }

  override async init(): Promise<void> {
    this.isAvailable = !!webIntent;
    if(!this.isAvailable)
      return;

    try {
      webIntent.registerBroadcastReceiver(
        {
          filterActions: [INTENT_ACTION_RESULT, this.scanAction],
          filterCategories: [FILTER_CATEGORY],
        },
        (intent) => {
          this.zone.run(() => {
            switch (intent.action) {
              case this.scanAction:
                this.onScan(intent);
                break;
              default:
                this.onResult(intent);
                break;
            }
          });
        }
      );
      await this.loadActiveProfile();
      this.isAvailable = true;
    }
    catch (e) {
      console.log('[Softline Zebra] ZebraService - Zebra Scanner is not available on this device');
      this.isAvailable = false;
    }
  }

  override async activate(): Promise<void> {
    this.active = true;
  }

  override async deactivate(): Promise<void> {
    this.active = false;
  }

  scan(labelType?: LabelType | LabelType[]): Promise<Scan> {
    if (this.subject && !this.subject.closed)
      this.subject.error(
        '[Softline Zebra] ZebraService - Cancelled by another scan ident'
      );

    this.subject = new BehaviorSubject<Scan | undefined>(undefined);
    this.startScan();
    return lastValueFrom(
      this.subject.pipe(
        first((o) => !!o),
        map(
          (o) =>
            (o as Scan) ?? { data: '', labelType: undefined, timestamp: '' }
        )
      )
    );
  }

  async cancel(): Promise<void> {
    if (this.subject && !this.subject.closed) {
      this.subject.error('[Softline Zebra] ZebraService - Cancelled by action');
      this.subject = undefined;
    } else
      throw Error(
        '[Softline Zebra] ZebraService - Unable to cancel: no scan running'
      );
  }

  loadActiveProfile(): Promise<string | undefined> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_GET_ACTIVE_PROFILE);
    return lastValueFrom(
      this.actionSubject.pipe(
        filter((o) => !!o),
        map((o) => o.extras[INTENT_EXTRA_GET_ACTIVE_PROFILE_RESULT])
      )
    );
  }

  loadVersionInfo(): Promise<VersionInfo> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_GET_VERSION_INFO);
    return lastValueFrom(
      this.actionSubject.pipe(
        filter((o) => !!o),
        map((o) => o.extras[INTENT_EXTRA_GET_VERSION_INFO_RESULT])
      )
    );
  }

  loadProfilesList(): Promise<string[]> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_GET_PROFILES_LIST);
    return lastValueFrom(
      this.actionSubject.pipe(
        filter((o) => !!o),
        map((o) => o.extras[INTENT_EXTRA_GET_PROFILES_LIST_RESULT])
      )
    );
  }

  loadConfig(name: string): Promise<Profile> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_GET_CONFIG, name);
    return lastValueFrom(
      this.actionSubject.pipe(
        filter((o) => !!o),
        map((o) => o.extras[INTENT_EXTRA_GET_CONFIG_RESULT])
      )
    );
  }

  loadScanners(): void {
    this.sendCommand(INTENT_EXTRA_ENUMERATE_SCANNERS, name);
  }

  createProfile(name: string): Promise<void> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_CREATE_PROFILE, name);
    return lastValueFrom(this.actionSubject.pipe(filter((o) => !!o)));
  }

  setConfig(config: ProfileConfig): Promise<void> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_SET_CONFIG, config);
    return lastValueFrom(this.actionSubject.pipe(filter((o) => !!o)));
  }

  deleteProfile(name: string): Promise<void> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_DELETE_PROFILE, name);
    return lastValueFrom(this.actionSubject.pipe(filter((o) => !!o)));
  }

  switchProfile(name: string): Promise<void> {
    if (this.actionSubject && !this.actionSubject.closed)
      this.actionSubject.error(
        '[Softline Zebra] ZebraService - Cancelled by another action ident'
      );

    this.actionSubject = new BehaviorSubject<any>(undefined);
    this.sendCommand(INTENT_EXTRA_SWITCH_TO_PROFILE, name);
    return lastValueFrom(this.actionSubject.pipe(filter((o) => !!o)));
  }

  /*private onResult(intent: Intent): void {
    if (intent.extras.hasOwnProperty(INTENT_EXTRA_GET_VERSION_INFO_RESULT)) {
      const result = intent.extras[INTENT_EXTRA_GET_VERSION_INFO_RESULT];
      this.onGetVersionInfoResult(result);
    }
    else if (intent.extras.hasOwnProperty(INTENT_EXTRA_ENUMERATE_SCANNERS_RESULT)) {
      const result = intent.extras[INTENT_EXTRA_ENUMERATE_SCANNERS_RESULT];
      this.onEnumerateScannersResult(result);
    }
    else if (intent.extras.hasOwnProperty(INTENT_EXTRA_GET_ACTIVE_PROFILE_RESULT)) {
      const result = intent.extras[INTENT_EXTRA_GET_ACTIVE_PROFILE_RESULT];
      this.onGetActiveProfileResult(result);
    }
  }*/

  /*private onGetVersionInfoResult(versionInfo: VersionInfo): void {
    this.store.dispatch(DeviceActions.loadVersionInfoSuccess({versionInfo}));
  }

  private onEnumerateScannersResult(scanners: Scanner[]): void {
    this.store.dispatch(DeviceActions.loadScannersSuccess({scanners}));
  }

  private onGetActiveProfileResult(profile: string): void {
    this.store.dispatch(DeviceActions.loadActiveProfileSuccess({profile}));
  }*/

  startScan(): void {
    this.sendScannerCommand('START_SCANNING');
  }

  stopScan(): void {
    this.sendScannerCommand('STOP_SCANNING');
  }

  toggleScan(): void {
    this.sendScannerCommand('TOGGLE_SCANNING');
  }

  private sendCommand(commandName: string, value: any = ''): void {
    webIntent.sendBroadcast(
      {
        action: INTENT_ACTION,
        extras: {
          [commandName]: value,
          SEND_RESULT: 'true',
        },
      },
      () => {},
      () => {}
    );
  }

  private onResult(intent: Intent): void {
    this.logger.log({
      subject: 'datawedge intent result',
      source: ZebraService,
      type: 'debug',
      category: 'scan',
      priority: LogPriority.Miscellaneous,
      params: intent,
    });
    if (this.actionSubject && !this.actionSubject.closed) {
      if (intent?.extras['RESULT'] === 'FAILURE') {
        this.actionSubject?.error(intent.extras);
        this.actionSubject = undefined;
        return;
      }
      this.actionSubject.next(intent);
      this.actionSubject.complete();
      this.actionSubject = undefined;
    }
  }

  private sendScannerCommand(
    command: 'START_SCANNING' | 'STOP_SCANNING' | 'TOGGLE_SCANNING'
  ): void {
    webIntent.sendBroadcast(
      {
        action: INTENT_ACTION,
        extras: {
          [INTENT_EXTRA_SOFT_SCAN_TRIGGER]: command,
        },
      },
      () => {},
      () => {}
    );
  }

  private onScan(intent: Intent): void {
    if(!this.active)
      return;

    const scan: Scan = {
      data: intent.extras[DATA_EXTRA],
      labelType: this.getFrameworkLabelType(intent.extras[LABEL_EXTRA]),
      timestamp: this.dateService.now(),
    };
    this.logger.log({
      subject: 'scan result',
      source: ZebraService,
      type: 'debug',
      category: 'scan',
      priority: LogPriority.Miscellaneous,
      params: scan,
    });
    if (this.subject && !this.subject.closed) {
      this.subject.next(scan);
      this.subject.complete();
      this.subject = undefined;
    } else
      this.store.commit(
        SOFTLINE_FEATURE_SCANNER,
        ScannerStore.mutations.add,
        scan
      );
  }

  private getFrameworkLabelType(labelType: string): LabelType | undefined {
    switch (labelType) {
      case 'LABEL-TYPE-AZTEC':
        return 'aztec';
      case 'LABEL-TYPE-CODABAR':
        return 'codabar';
      case 'LABEL-TYPE-CODE39':
        return 'code39';
      case 'LABEL-TYPE-CODE93':
        return 'code93';
      case 'LABEL-TYPE-CODE128':
        return 'code128';
      case 'LABEL-TYPE-DATAMATRIX':
        return 'datamatrix';
      case 'LABEL-TYPE-EAN8':
        return 'ean8';
      case 'LABEL-TYPE-EAN13':
        return 'ean13';
      case 'LABEL-TYPE-EAN128':
        return 'ean128';

      case 'LABEL-TYPE-PDF417':
        return 'pdf417';
      case 'LABEL-TYPE-QRCODE':
        return 'qrcode';

      case 'LABEL-TYPE-UPCA':
        return 'upc_a';
      case 'LABEL-TYPE-UPCE0':
        return 'upc_e0';
      case 'LABEL-TYPE-UPCE1':
        return 'upc_e1';

      case 'LABEL-TYPE-I2OF5':
        return 'itf';

      default:
        console.log('[Softline Zebra] Unknown label type ', labelType);
        return undefined;
    }
  }
}
