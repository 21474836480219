import { ResourceService } from '../../data/abstraction';
import { StorageResourceLocation } from '../../data/specialized/storage/storage.location';
import { interpolate } from '../../functions/interpolate.function';
import {
  Id,
  LoadCollectionItemParameters,
  LoadCollectionParameters,
  ReadonlyCollectionRepository
} from '../abstraction';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { toIdString } from '../functions/to-id-string';
import { Dictionary } from '../../types/dictionary';

export class ReadonlyStorageCollectionRepository<TItem> implements ReadonlyCollectionRepository<TItem, TItem[]> {

  constructor(protected service: ResourceService<StorageResourceLocation>,
              protected key: string) {
  }

  loadItem(id: Id, params: LoadCollectionItemParameters): Observable<TItem> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    const idString = toIdString(id);
    return this.service.get<Dictionary<TItem>>(location)
      .pipe(
        map(o => o[idString]),
        map(o => {
          if(!o)
            throw new Error(`[ReadonlyStorageCollectionDataService]loadItem: Object with id '${id}' not found in collection '${this.key}'`);
          return o as TItem;
        })
      );
  }

  loadMany(params?: LoadCollectionParameters): Observable<TItem[]> {
    const location: StorageResourceLocation = {
      key: interpolate(this.key, params?.pathParams ?? {})
    };
    return this.service.get<Dictionary<TItem>>(location)
      .pipe(
        map(o => {
          let items = Object.values(o)
            .filter(o => !!o)
            .map(o  => o as TItem);
          if(params?.query)
            items = items.filter(p => Object.keys(params.query ?? {}).every(k => !params.query || p[k] === params.query[k]));
          if(params?.offset)
            items = items.slice(params.offset, items.length);
          if(params?.limit)
            items.slice(0, params.limit - 1);
          return items;
        }));
  }
}
