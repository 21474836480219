import { KommmissionierungStrategy } from "./kommmissionierung.strategy";
import { ArtikelMenge, LagerplatzInhalt } from "@softapps/lager/core";
import { KommissionsauftragKopf } from "../types/kommissionsauftrag";

export class RetourscheinStrategy implements KommmissionierungStrategy {
  loadLagerplatzVorschlag = false;
  begleitscheindruck = false;

  constructor(public lagerplatzAuswahl: boolean) {
    this.loadLagerplatzVorschlag = !lagerplatzAuswahl;
  }

  getMaxMenge(lagerplatz: LagerplatzInhalt | undefined, artikelMenge: ArtikelMenge | undefined): number {
    if(!lagerplatz || !artikelMenge)
      return 0;
    return artikelMenge.menge ?? 0;
  }

  getPalettenBegleitscheinMenge(auftrag: KommissionsauftragKopf): number {
    return 0;
  }
}
