/*
 * Public API Surface of pdf-editor
 */
export * from './lib/components/pdf-editor/pdf-editor.component';
export * from './lib/components/pdf-editor-signature-dialog/pdf-editor-signature-dialog.component';
export * from './lib/components/pdf-editor-tool-customization/pdf-editor-tool-customization.component';
export * from './lib/components/pdf-editor-tools/pdf-editor-tools.component';

export * from './lib/data/pdf-editor-config';
export * from './lib/data/pdf-editor-tool';

export * from './lib/pages/pdf-editor-page/pdf-editor-page.component';

export {
  OpenPdfEditorParams,
  State,
  PdfFile,
  PdfEditorStore,
} from './lib/store/pdf-editor.store';

export * from './lib/pdf-editor.shared';
export * from './lib/pdf-editor.routes';
export * from './lib/pdf-editor.providers';
