import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { ArtikelMenge } from "../../types/artikel-menge";
import { LagerplatzInhalt } from "../../types/lagerplatz-inhalt";
import { UiCoreModule } from "@softline/ui-core";

@Component({
  selector: 'soft-artikel-menge-auswahl',
  standalone: true,
  imports: [CommonModule, UiCoreModule],
  templateUrl: './artikel-menge-auswahl.component.html',
  styleUrls: ['./artikel-menge-auswahl.component.scss']
})
export class ArtikelMengeAuswahlComponent implements OnInit {

  @Input() lagerplatzInhalt: LagerplatzInhalt | null | undefined;
  @Output() artikelMengeChange = new EventEmitter<ArtikelMenge>();

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(artikelMenge: ArtikelMenge | undefined): void {
    if(!artikelMenge)
      return;
    this.artikelMengeChange.emit(artikelMenge);
  }
}
