import { ResourceService } from '../../data/abstraction';
import { CollectionRepository, CRUDCollectionParameters, Id } from '../abstraction';
import { Observable } from 'rxjs';
import { ReadonlyHttpCollectionRepository } from './readonly-http-collection.repository';
import { createHttpResourceLocation, HttpResourceLocation } from '../../data/specialized/http/http.location';
import { toIdString } from '../functions/to-id-string';

export class HttpCollectionRepository<TItem, TResult, TManyResult> extends ReadonlyHttpCollectionRepository<TResult, TManyResult> implements CollectionRepository<TItem, TResult, TManyResult> {

  constructor(service: ResourceService<HttpResourceLocation>,
              url: string) {
    super(service, url);
  }

  create(id: Id, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location = this.createLocation(id, params);
    return this.service.create(location, value);
  }
  update(id: Id, value: TItem, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location = this.createLocation(id, params);
    return this.service.update(location, value);
  }
  patch(id: Id, changes: Partial<TItem>, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location = this.createLocation(id, params);
    return this.service.patch(location, changes);
  }
  delete(id: Id, params?: CRUDCollectionParameters | undefined): Observable<TResult> {
    const location = this.createLocation(id, params);
    return this.service.delete(location);
  }

  private createLocation(id: Id, params?: CRUDCollectionParameters): HttpResourceLocation {
    const idString = toIdString(id);
    const url = `${this.url}/${idString}`;
    return createHttpResourceLocation({ url: url, pathParams: params?.pathParams });
  }
}
