import { inject, Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { ScanObjektStore2 } from './scan-objekt.store';
import { showRequestErrors, Scan } from '@softline/application';
import { Objekt } from './objekt';
import { Store } from '@softline/core';

type Constructor<T extends {}> = new(...args: any[]) => T;

export const WithScanObjekt = <T extends Constructor<{}>>(Base: T = (class {} as any)) => {

  @Injectable()
  abstract class ScanObjektMixin extends Base implements OnInit, OnDestroy {

    #injector = inject(Injector);
    #scanObjectStore = inject(ScanObjektStore2);
    #store = inject(Store);

    constructor(...args: any[]) {
      super(...args);
    }

    ngOnInit(): void {
      if(super['ngOnInit'])
        super['ngOnInit']();
    }

    ngOnDestroy() {
      if(super['ngOnDestroy'])
        super['ngOnDestroy']();
    }

    async onScan(scan: Scan): Promise<void> {
      if(super['onScan'])
        await super['onScan'](scan);
      if(scan.labelType === 'code39') {
        try {
          const result = await this.#scanObjectStore.repository.load({pathParams: {...scan}});
          await this.onScanObjekt(result);
        }
        catch (e) {
          showRequestErrors(this.#store, e)
        }
      }
    }

    abstract onScanObjekt(objekt: Objekt): Promise<void>;
  }
  return ScanObjektMixin;
}
