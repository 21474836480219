<div>
  <span class="font-semibold" *ngIf="name()">{{ name() }}:</span>
  <ng-container
    *ngTemplateOutlet="
      value() === null
        ? nullTemplate
        : (value() | isArray)
        ? arrayTemplate
        : (value() | typeof) === 'object'
        ? objectTemplate
        : (value() | typeof) === 'function'
        ? functionTemplate
        : (value() | typeof) === 'symbol'
        ? symbolTemplate
        : (value() | typeof) === 'undefined'
        ? undefinedTemplate
        : defaultTemplate;
      context: { content: value() }
    "
  ></ng-container>
</div>

<ng-template #nullTemplate> <span class="text-light italic ml-2">null</span> </ng-template>

<ng-template let-content="content" #arrayTemplate>
  <span class="cursor-pointer" (click)="isExpanded.set(!isExpanded())">
  @if(canToggle()) {
    <i
      class="fa-regular mx-2"
      [ngClass]="{ 'fa-caret-right': !isExpanded(), 'fa-caret-down': isExpanded() }"
    ></i>
  }
    Array
  </span>
  @if(isExpanded()) {
    <div class="cursor-pointer" class="ml-2">
      @for (child of content; track child; let i = $index) {
        <soft-json-object-tree
          [name]="'[' + i + ']'"
          [value]="child"
        ></soft-json-object-tree>
      } @empty {
        <span class="text-light italic ml-2"> [empty] </span>
      }
    </div>
  }
</ng-template>

<ng-template let-content="content" #objectTemplate>
  <span class="cursor-pointer" (click)="isExpanded.set(!isExpanded())">
  @if(canToggle()) {
    <i
      class="fa-regular mx-2"
      [ngClass]="{ 'fa-caret-right': !isExpanded(), 'fa-caret-down': isExpanded() }"
    ></i>
  }
    {{ className() }}
  </span>
  @if (isExpanded()) {
    <div class="ml-2">
      @for (child of content | keyvalue; track child) {
        <soft-json-object-tree
          [value]="child.value"
          [name]="$any(child.key)"
        ></soft-json-object-tree>
      } @empty {
        <span class="text-light italic ml-2"> empty </span>
      }
    </div>
  }
</ng-template>

<ng-template #functionTemplate> <span class="text-light italic ml-2">function</span> </ng-template>

<ng-template #symbolTemplate> <span class="text-light italic ml-2">symbol</span> </ng-template>

<ng-template #undefinedTemplate><span class="text-light italic ml-2"> undefined </span></ng-template>

<ng-template let-content="content" #defaultTemplate>
  {{ content }}
</ng-template>
