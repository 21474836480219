import {
  ActionStore,
  createAction, createGetter,
  createMutation,
  KeyStore,
  mutate,
  on, RemoteCollectionStore, select,
  SOFTLINE_SERVICE_UUID,
  StoreFeature,
  SubscriptionStore
} from "@softline/core";
import { lastValueFrom } from "rxjs";
import { InventurService } from "../services/inventur.service";
import { ArtikelMenge, LagerplatzInhalt, Lagersymbol } from "@softapps/lager/core";
import { Scan } from "@softline/application";

export interface State extends ActionStore.State, SubscriptionStore.State, KeyStore.State  {
  scan?: Scan;
  lagerplatz?: LagerplatzInhalt;
  artikel?: ArtikelMenge;
  lagersymbol?: Lagersymbol;
};

export class InventurStore {
  static mutations = {
    setLagerplatz: createMutation<State, LagerplatzInhalt>('setLagerplatz'),
    setArtikel: createMutation<State, ArtikelMenge>('setArtikel'),
    setScan: createMutation<State, Scan>('setScan'),
    reset: createMutation<State>('reset'),
  };
  static getters = {
    lagerplatz: createGetter<State, LagerplatzInhalt>('lagerplatz'),
    artikel: createGetter<State, ArtikelMenge>('artikel')
  };
  static actions = {
    loadLagerplatz: createAction<State, string, LagerplatzInhalt>('loadLagerplatz'),
    inventur: createAction<State, LagerplatzInhalt>('inventur')
  };

  static feature: StoreFeature<State> = {
    initialState: {
      ...ActionStore.feature.initialState,
      ...KeyStore.feature.initialState,
      ...SubscriptionStore.feature.initialState
    },
    mutations: [
      ...ActionStore.feature.mutations,
      ...KeyStore.feature.mutations,
      ...SubscriptionStore.feature.mutations,
      mutate(InventurStore.mutations.setLagerplatz,
        ({state, params}) => ({...state, lagerplatz: params})
      ),
      mutate(InventurStore.mutations.setArtikel,
        ({state, params}) => ({...state, artikel: params})
      ),
      mutate(InventurStore.mutations.setScan,
        ({state, params}) => ({...state, scan: params})
      ),
      mutate(InventurStore.mutations.reset,
        ({state, params}) => ({...state, lagerplatz: undefined, artikel: undefined, lagersymbol: undefined})
      )
    ],
    getters: [
      ...ActionStore.feature.getters,
      ...KeyStore.feature.getters,
      ...SubscriptionStore.feature.getters,
      select(InventurStore.getters.lagerplatz, ({state}) => state.lagerplatz),
      select(InventurStore.getters.artikel, ({state}) => state.artikel)
    ],
    actions: [
      ...ActionStore.feature.actions,
      ...KeyStore.feature.actions,
      ...SubscriptionStore.feature.actions,
      on(InventurStore.actions.loadLagerplatz, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(InventurService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.getLagerplatz(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            InventurStore.actions.loadLagerplatz.name,
            token
          )
        );
        commit(featureName, InventurStore.mutations.setLagerplatz, result);
        return result;
      }),
      on(InventurStore.actions.inventur, async ({state, commit, featureName, injector, params}) => {
        const service = injector.get(InventurService);
        const token = injector.get(SOFTLINE_SERVICE_UUID)();
        const subscriptionState$ = SubscriptionStore.handleSubscriptionState(
          service.inventur(params),
          featureName,
          commit,
          token
        );
        const result = await lastValueFrom(
          ActionStore.handleObservableActionState(
            subscriptionState$,
            featureName,
            commit,
            InventurStore.actions.inventur.name,
            token
          )
        );
        commit(featureName, InventurStore.mutations.reset);
        return result;
      })
    ]
  }
}

export const LagerSymbolStore = RemoteCollectionStore.create<Lagersymbol>()
