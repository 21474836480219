import { Routes } from "@angular/router";
import { AuthenticationGuard, AuthorizationGuard } from "@softline/auth";
import { importProvidersFrom } from "@angular/core";
import { StoreModule } from "@softline/core";
import { SOFTLINE_FEATURE_LAGERKORREKTUR, SOFTLINE_PERMISSION_LAGERKORREKTUR } from "./lagerkorrektur.shared";
import { LagerkorrekturStore } from "./store";
import { LagerkorrekturService } from "./services/lagerkorrektur.service";

export const lagerkorrekturRoutes: Routes = [
  {
    path: 'lagerkorrektur',
    children: [
      { path: 'lagersymbol-auswahl', loadComponent: () => import('./pages/lagersymbol-auswahl/lagersymbol-auswahl.page').then(o => o.LagersymbolAuswahlPage)},
      { path: 'mengen-auswahl', loadComponent: () => import('./pages/mengen-auswahl/mengen-auswahl.page').then(o => o.MengenAuswahlPage)},
      { path: 'artikel-auswahl', loadComponent: () => import('./pages/artikel-auswahl/artikel-auswahl.page').then(o => o.ArtikelAuswahlPage)},
      { path: '', loadComponent: () => import('./pages/lagerplatz-scannen/lagerplatz-scannen.page').then(o => o.LagerplatzScannenPage) },
      { path: '**', redirectTo: '/lagerkorrektur' },
    ],
    canActivate: [AuthenticationGuard, AuthorizationGuard],
    data: {permission: SOFTLINE_PERMISSION_LAGERKORREKTUR},
    providers: [
      LagerkorrekturService,
      importProvidersFrom(
        StoreModule.forFeature({
          name: SOFTLINE_FEATURE_LAGERKORREKTUR,
          feature: LagerkorrekturStore.feature,
        })
      ),
    ]
  },
];
